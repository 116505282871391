import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Route, useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { TailSpin } from "react-loader-spinner";
import { ApiService } from "../../Components/Services/apiServices";
let apiServices = new ApiService();

function CompleteProfileNew(){
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([])
    const [profileData, setProfileData] = useState({ dob: '', fname: '', mname: '', lname: '', gender: '', pan: '', country_of_birth: "", place_of_birth: "", addr1: '', addr2: '', city: '', state: '', pin: '', addrtype: '', sales_code: "" });
    const [IsMinor, setIsMinor] = useState(0);
    const [MinorText, setMinorText] = useState('');
    const [city, setCity] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const[kycEncrytStatus1 , setKycEncryptStatus1] = useState(false)
    const[kycEncrytStatus4 , setKycEncryptStatus4] = useState(false)
    const[checkKyc , setCheckKyc] = useState(true)
    const [checkKycData , setCheckKycData] = useState({})
    const [checkKycMessage , setCheckKycMessage] = useState("")
    const [successMessage , setSuccessMessage] = useState("")
    const [errorMessage , setErrorMessage] = useState("")
  const [doKycData , setDoKycData] = useState({fkyc_name:'',mkyc_name:'',lkyc_name:'',pan_kyc:''})
  const [applicationType , setApplicationType] = useState("")
  const [spinnerLoading, setSpinnerLoading] = useState(false)
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
      if (didMountRef.current) {
  
        apiServices.getstatesGetRequest().then(result => {
          if (result.data.status == "success") {
            setStateData(result.data.data)
          }
        })
        apiServices.getCityDataGetRequest().then(result => {
          if (result.data.status == "success") {
            setCity(result.data.data);
          }
        })
        setSpinnerLoading(true)
        apiServices.bsegetuserdataPostRequest({}).then(result => {
          if (result.data.status == 'success') {
            setSpinnerLoading(false)
            setProfileData({
              fname: result.data.data.gs_fname,
              mname: result.data.data.gs_mname,
              lname: result.data.data.gs_lname,
              gender: result.data.data.gs_gender,
              dob: result.data.data.gs_dob,
              pan: result.data.data.gs_pan_no,
              place_of_birth: result.data.data.gs_palce_of_birth,
              country_of_birth: result.data.data.gs_country_of_birth,
              addr1: result.data.data.gs_address1,
              pin: result.data.data.gs_pin,
              city: result.data.data.gs_city,
              state: result.data.data.gs_state,
              addrtype: result.data.data.gs_address_proof_type,
              sales_code: result.data.data.gs_sub_broker_code
            });
    
   
  
  
          } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
            navigate("/");
            setSpinnerLoading(false)
          }
        })
        getCountryData()
        // pullonboading()
  
      }
      didMountRef.current = false;
    }, []);
    const handleInput = (e) => {
      const key = e.target.name;
      const value = e.target.value;
      setProfileData({ ...profileData, [key]: value })
      if (key == 'dob') {
        const dob = new Date(e.target.value);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
  
        // Check if the user is a minor (under 18 years old)
        if (age < 18) {
          setIsMinor(1);
          setMinorText('MINOR');
        } else {
          setIsMinor(0);
          setMinorText('INDIVIDUAL');
        }
      }
      if (key == 'state') {
        getCities(value);
      }
    }
  
  
    const firstStep = (e) => {
      e.preventDefault();
  
      if (profileData.fname == '' || profileData.fname == null) {
        toast.error("Please enter your name");
  
        return;
      }
      else if (profileData.dob == "" || profileData.dob == null) {
        toast.error("Please fill the DOB field")
        return
      }
      else if (profileData.gender == "" || profileData.gender == null) {
        toast.error("Please fill gender field")
        return
      }
      else if (profileData.pan == "" || profileData.pan == null) {
        toast.error("Please enter the pan number")
        return
      }
      else if (profileData.pan.length < 10 || profileData.pan.length > 10) {
        toast.error("Please enter valid pan number")
        return
      }
      else if (profileData.place_of_birth == "" || profileData.place_of_birth == null) {
        toast.error("Please enter your place of birth ")
        return
      }
      else if (profileData.country_of_birth == "" || profileData.country_of_birth == null) {
        toast.error("Please enter your country of birth ")
        return
      }
      else {
        setIsLoading(true)
        const dataString = {
          "step": 1,
          "fname": profileData.fname,
          "mname": profileData.mname,
          "lname": profileData.lname,
          "gender": profileData.gender,
          "pan": profileData.pan,
          "dob": profileData.dob,
          "sales_code": profileData.sales_code,
          "country_of_birth": profileData.country_of_birth,
          "place_of_birth": profileData.place_of_birth,
          "minor": IsMinor,
  
        }
        apiServices.bsecompleteprofilePostRequest(dataString).then(result => {
          
          if (result.data.status == 'success') {
            setIsLoading(false)
          navigate('/bseonboarding/0')
          }
          else if (result.data.status == "error" && result.data.message == "Session expired") {
            setIsLoading(false)
            navigate("/")
            localStorage.removeItem('AUTH_TOKEN');
  
          }
          else {
            toast(result.data.message);
            setIsLoading(false)
  
          }
        })
          .catch(error => {
          })
      }
    };
    const KYCprocess = (e) => {
      e.preventDefault();
      console.log("first");
    
      if (doKycData.fkyc_name == "" || doKycData.fkyc_name == null) {
        toast.error("Please enter the Name");
        return;
      } else {
        setIsLoading(true);
    
        const dataString = {
          first_name: doKycData.fkyc_name,
          middle_name: doKycData.mkyc_name,
          last_name: doKycData.lkyc_name,
          application_type: applicationType
        };
    
        console.log(dataString);
    
        apiServices.kycLoginpostrequest(dataString)
          .then(result => {
            console.log(result, "dfgdfgdfg");
            if (result?.data?.status === "success") {
              setIsLoading(false);
              setKycEncryptStatus4(false);
              setCheckKyc(true);
              setKycEncryptStatus1(false);
              const url = result?.data?.redirectlink;
              console.log(result?.data?.redirectlink);
              window.open(url, '_blank');
            } else {
              toast(result.data.message);
              setIsLoading(false);
            }
          })
          .catch(error => {
            setIsLoading(false);
            console.log(error);
          });
      }
    };
    
    const getCountryData = (e) => {
      apiServices.getbsecountryGetRequest().then(result => {
        if (result.data.status == "success") {
          setCountryData(result.data.data);
        }
      })
    }
    const secondStep = (e) => {
      e.preventDefault();
      if ((profileData.pan == '' || profileData.pan == null) && IsMinor == 0) {
        toast("PAN is Required");
        return;
      }
      else if ((profileData.pan.length != 10) && IsMinor == 0) {
        toast("Invalid PAN");
        return;
      }
      else if (profileData.dob == '' || profileData.dob == null) {
        toast("Date Of Birth is Required");
        return;
      }
      else if (profileData.gender === '' || profileData.gender === null) {
        toast("Gender is Required");
        return;
      }
  
      else {
  
      }
      setIsLoading(true)
      const dataString = {
        "pan": profileData.pan,
        "step": 2,
        "dob": profileData.dob,
        "minor": IsMinor,
        "gender": profileData.gender,
      }
      apiServices.completeprofilePostRequest(dataString).then(result => {
        if (result.data.status == "success") {
          toast(result.data.message);
          setIsLoading(false)
          setStep1(false)
          setStep2(false)
          setStep3(true)
        }
      })
    };
    const getCities = (value) => {
      const dataString = {
        "id": value
      }
      apiServices.getcities(dataString).then(res => {
        if (res.data.status == 'success') {
          setCity(res.data.data);
          return;
        }
      })
    }
  
    const thirdstep = () => {
  
      if ((profileData.addr1 == '' || profileData.addr1 == null)) {
        toast("Address is Required");
        return;
      }
      if ((profileData.state == '' || profileData.state == null)) {
        toast("State is Required");
        return;
      }
      if ((profileData.city == '' || profileData.city == null)) {
        toast("City is Required");
        return;
      }
      if ((profileData.pin == '' || profileData.pin == null)) {
        toast("Postcode is Required");
        return;
      }
      setIsLoading(true)
      const dataString = {
        "step": 3,
        "addr1": profileData.addr1,
        "city": profileData.city,
        "state": profileData.state,
        "pin": profileData.pin,
      }
      apiServices.completeprofilePostRequest(dataString).then(result => {
        if (result.data.status == "success") {
          if (result.data.canstatus == 0) {
            setIsLoading(false)
            navigate("/canregister");
          }
          else {
            setIsLoading(false)
            navigate("/bankdetails");
          }
        }
      })
  
    }
  const checkKYCprocess=()=>{
    const dataString={
      pan : profileData.pan
    }
    setIsLoading(true)
    apiServices.checkuserekycpostrequest(dataString).then(res=>{
  
      if(res.data.status == "success"){
      
        if(res.data.data.STATUS == 'EKYC'){
  
            setKycEncryptStatus4(true)
            setCheckKyc(false)
            setKycEncryptStatus1(false)
            setIsLoading(false)
            toast(res.data.data.STATUSTXT);
            
            setCheckKycData(res.data.data.DATA)
        
        }
        else if(res.data.data.STATUS == "VERIFIED"){
          setIsLoading(false)
          setKycEncryptStatus1(true)
          setCheckKyc(false)
          setKycEncryptStatus4(false)
          toast(res.data.data.STATUSTXT);
          setCheckKycMessage(res.data.data.STATUSTXT)
          setCheckKycData(res.data.data.DATA)
          const string = res.data?.data?.DATA.F_PAN_NAME;
          const name = string?.split(' ');
          if(name && name != ''){
          if(name?.length == 3){
            setProfileData({ ...profileData, fname: name[0], mname: name[1], lname: name[2] });
          }
          else if(name?.length == 2){
            setProfileData({ ...profileData, fname:name[0] , lname:name[1]})
          }
          else{
            setProfileData({ ...profileData,fname:name[0]})
          }
        }
      
        }
      }
    })
  }
  
  const pullonboading=()=>{
    apiServices.pullonboardingpostrequest({}).then(res=>{
  if(res.data.status == "success" ){
    setSuccessMessage(res.data.message )
  }
  else if(res.data.status == "error" && res.data.statusText == "Pending"){
    setErrorMessage(res.data.message)
  }
  else if(res.data.status == "error" && res.data.statusText == "Rejected"){
    setErrorMessage(res.data.message)
  }
  else if(res.data.status == "error" && res.data.statusText == "Draft"){
    setErrorMessage(res.data.message)
  }
    })
  }

  const gotocompleteprofile=()=>{
    setStep3(true)
    setStep1(false)
    setStep2(false)
  }

  const checkKycStatus=()=>{
  window.open("https://www.karvykra.com/UPanSearchGlobalWithPanExempt.aspx")
  }

  const dokycbutton=(type)=>{
    setApplicationType(type)
    setStep3(false)
    setStep1(false)
    setStep2(true)
  }

 const handleChangeKyc=(e)=>{

    const key = e.target.name;
    const value = e.target.value;
    setDoKycData({ ...doKycData, [key]: value })
   
  
 }


    return(
        <>
         <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
         <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
   

        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="crds">
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <h5>Let's Create Your Profile</h5>
                        <p className="tx-gray">Your name will be taken as per (PAN Card)</p>
                      </div>
                      {step1 == true ?
                      <div className="col-lg-12">
                        <p className='tx-5'>If you have already done Mutual Funds KYC once, you do not need to do it again.</p>
                        <p class="">1. Not sure ? <span onClick={checkKycStatus} class="tx-theme" style={{cursor: "pointer"}}>Check KYC status</span></p>
        
                        <p>2. KYC done already and status is "KYC validated", <span  class="tx-theme" style={{cursor: "pointer"}} onClick={gotocompleteprofile}>proceed to open account</span></p>
                        <p>3. KYC done but status is "KYC Registered" OR "KYC On hold/ Rejected" <span  class="tx-theme" style={{cursor: "pointer"}} onClick={()=>{dokycbutton('new')}}>Do KYC</span></p>
                        <p>4. KYC not done in past, new KYC required to be done <span  class="tx-theme" style={{cursor: "pointer"}} onClick={()=>{dokycbutton('modify')}}>Do KYC</span></p>
        
                       

                        <strong>Note :-</strong>
                              <p className='tx-11 mb-1'>If your KYC is not done, it will show there is no data.</p>
                              <p className='tx-11'>You will be taken to Karvy KRA website. Enter you PAN number and check KYC status.</p>
                              <p className='tx-11'>If your KYC is in "KYC validated" Status, you can go ahead and open the account.</p>
                              <p className='tx-11'>If your KYC is in any other status than KYC validated, proceed to do KYC again on our web page. </p>
                      </div>:''}
                      {step2 == true ?<>
                      {/* {kycEncrytStatus1 == false ?<> 
                   {errorMessage && errorMessage !== "" ?   <div class="alert alert-danger" role="alert">
  {errorMessage}
</div>:""}
{successMessage && successMessage !== "" ?  
<div class="alert alert-success" role="alert">
{successMessage}
</div>:""}</>:""} */}

                  
            
                      
                          <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label htmlFor="fname">First Name<span className="tx-red">*</span></label>
                            <input type="text" name="fkyc_name" id="fkyc_name" defaultValue={doKycData.fkyc_name} onChange={handleChangeKyc} />
                            <p className="mb-0 mt-2 tx-12" style={{color:'var(--color-primary)'}}>Name has to exactly match with your PAN Name </p> 
                          </div>
  
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="from-group mb-20">
                              <label htmlFor="lname">Middle Name</label>
                              <input type="text" name="mkyc_name" id="mkyc_name" defaultValue={doKycData.mkyc_name} onChange={handleChangeKyc} />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="from-group mb-20">
                              <label htmlFor="lname">Last Name</label>
                              <input type="text" name="lkyc_name" id="lkyc_name" defaultValue={doKycData.lkyc_name}  onChange={handleChangeKyc} />
                            </div>
                          </div>
  
  
                        </div></>
                        
                        :"" }
              {step3 == true ?<>
                        {kycEncrytStatus1 == false ? 
                        <>
                      <div className="col-lg-12">
                        <div className="from-group mb-20">
                          <label htmlFor="fname">First Name<span className="tx-red">*</span></label>
                          <input type="text" name="fname" id="fname" defaultValue={profileData.fname} onChange={handleInput} />
                          <p className="mb-0 mt-2 tx-12" style={{color:'var(--color-primary)'}}>Name has to exactly match with your PAN Name </p> 
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="lname">Middle Name</label>
                            <input type="text" name="mname" id="mname" defaultValue={profileData.mname} onChange={handleInput} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="lname">Last Name</label>
                            <input type="text" name="lname" id="lname" defaultValue={profileData.lname}  onChange={handleInput} />
                          </div>
                        </div>


                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="gender">Gender<span className="tx-red">*</span></label>
                            <select
                              name="gender"
                              id="gender"
                              defaultValue={profileData.gender}
                              onChange={handleInput}
                              className="custom-select"
                            >

                              <option value="">Select</option>
                              <option value="M" selected={profileData.gender === "M"}>Male</option>
                              <option value="F" selected={profileData.gender === "F"}>Female</option>
                              <option value="O" selected={profileData.gender === "O"}>Others</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="dob">Date Of Birth<span className="tx-red">*</span></label>
                            <input type="date" name="dob" id="dob" placeholder="Enter your date of birth" defaultValue={profileData.dob} onChange={handleInput} />
                          </div>
                        </div>


                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="place of birth">Place of Birth<span className="tx-red">*</span></label>
                            <input type="text" name="place_of_birth" id="place_of_birth"  defaultValue={profileData.place_of_birth} onChange={handleInput} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label htmlFor="country of birth">Country of Birth<span className="tx-red">*</span></label>
                            <select name="country_of_birth" id="country_of_birth" onChange={handleInput} Value={profileData.country_of_birth} className="custom-select">
                              <option value="">Select</option>
                              {countryData.map((value) => (
                                <option
                                  key={value.country_code}
                                  value={value.country_code}

                                  selected={value.country_code === profileData.country_of_birth ? true : false}
                                >
                                  {value.country_name}
                                </option>
                              ))}
                            </select>
                            {/* <input type="text" name="country_of_birth" id="country_of_birth" placeholder="Enter your country of birth" defaultValue={profileData.country_of_birth}  onChange={handleInput}/> */}
                          </div>
                        </div>
                        {IsMinor == 0 ?
                        <div className="col-lg-12">
                          <div className="from-group mb-20 ifsc-input">
                            <label htmlFor="pan">PAN Number<span className="tx-red">*</span></label>
                            {checkKycData.STATUS == 1 ?
                            <input type="text" style={{ textTransform: "uppercase" }} name="pan" id="pan" defaultValue={profileData.pan} onChange={handleInput}  disabled/>
                            :<input type="text" style={{ textTransform: "uppercase" }} name="pan" id="pan" defaultValue={profileData.pan} onChange={handleInput}  />}
                           {checkKycData && checkKycData.F_PAN_NAME ? <div className="ifsc-verify"><img src="/img/verify.png" className="wd-20"></img></div> :""}
                           {checkKycData && checkKycData.F_PAN_NAME ? <p className="mb-0 mt-2 tx-12" style={{color:'var(--color-primary)'}}>{checkKycData.F_PAN_NAME} ({checkKycMessage}) </p> :""}
                          

                          </div>
                        </div>:"" }

                      </div>

                    
                      <div className="col-lg-12">
                        <div className="from-group mb-20">
                          <label htmlFor="fname">I have a Sales Code</label>
                          <input type="text" name="sales_code" id="" defaultValue={profileData.sales_code}  onChange={handleInput} />
                        </div>
                      </div>
</>:""}</>:''}
{step3 == true ?<>

                    {kycEncrytStatus1 == false ?
                    
                      <button
                        className="btn btn-primary btn-lg btn-full mt-1"
                        onClick={firstStep}
                        disabled={isLoading} // Disable the button when loading
                      >
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}
                      </button>:""}
                      <button type="button" onClick={()=>{setStep1(true);setStep3(false)}} className="btn btn-outline-primary btn-lg btn-full mt-3">Back</button>
                      </>:''}
            
                      {step2 == true ?<>
                       
                      <button
                        className="btn btn-primary btn-lg btn-full mt-1"
                        onClick={KYCprocess}
                        disabled={isLoading} // Disable the button when loading
                      >
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Do KYC"
                        )}
                      </button>
                      <button type="button" onClick={()=>{setStep1(true);setStep2(false)}} className="btn btn-outline-primary btn-lg btn-full mt-3">Back</button>
                      
                      </>
                      :""}
                      
                      {/* {checkKyc == true ?
                      <button
                        className="btn btn-primary btn-lg btn-full mb-20"
                        onClick={checkKYCprocess}
                        disabled={isLoading} // Disable the button when loading
                      >
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Next"
                        )}
                      </button>:""} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


   
      </BrowserView>
      <MobileView>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div style={{ background: '#f5f5f5', padding: '15px' }}>
          <h5 className="mb-0">Let's Create Your Profile</h5>
          <p className="tx-gray tx-12 mb-0">Your name will be taken as per (PAN Card)</p>
        </div>
        <div className="p15 pb-5 mb-5">
          <div className="row g-2">
          {step1 == true ?
                      <div className="col-lg-12">
                        <p className='tx-5'>If you have already done Mutual Funds KYC once, you do not need to do it again.</p>
                        <p class="">1. Not sure ? <span onClick={checkKycStatus} class="tx-theme" style={{cursor: "pointer"}}>Check KYC status</span></p>
        
                        <p>2. KYC done already and status is "KYC validated", <span  class="tx-theme" style={{cursor: "pointer"}} onClick={gotocompleteprofile}>proceed to open account</span></p>
                        <p>3. KYC done but status is "KYC Registered" OR "KYC On hold/ Rejected" <span  class="tx-theme" style={{cursor: "pointer"}} onClick={dokycbutton}>Do KYC</span></p>
                        <p>4. KYC not done in past, new KYC required to be done <span  class="tx-theme" style={{cursor: "pointer"}} onClick={dokycbutton}>Do KYC</span></p>
        
                       

                        <strong>Note :-</strong>
                              <p className='tx-11 mb-1'>If your KYC is not done, it will show there is no data.</p>
                              <p className='tx-11'>You will be taken to Karvy KRA website. Enter you PAN number and check KYC status.</p>
                              <p className='tx-11'>If your KYC is in "KYC validated" Status, you can go ahead and open the account.</p>
                              <p className='tx-11'>If your KYC is in any other status than KYC validated, proceed to do KYC again on our web page. </p>
                      </div>:''}
            {step2 == true?<>
     
           
             <div className="col-12">
             <div className="from-group mb-2">
               <label htmlFor="fname">First Name<span className="tx-red">*</span></label>
               <input type="text" name="fkyc_name" id="fkyc_name" defaultValue={doKycData.fkyc_name} onChange={handleChangeKyc} />
             </div>
           </div>
           <div className="col-6">
             <div className="from-group mb-2">
               <label htmlFor="lname">Middle Name</label>
               <input type="text" name="mkyc_name" id="mkyc_name" defaultValue={doKycData.mkyc_name} onChange={handleChangeKyc} />
             </div>
           </div>
           <div className="col-6">
             <div className="from-group mb-2">
               <label htmlFor="lname">Last Name</label>
               <input type="text" name="lkyc_name" id="lkyc_name" defaultValue={doKycData.lkyc_name}  onChange={handleChangeKyc} />
             </div>
           </div>    </>      
           
          
            
            :''    
        }  
        {step3 == true ?<>       
            <div className="col-12">
              <div className="from-group mb-2">
                <label htmlFor="fname">First Name<span className="tx-red">*</span></label>
                <input type="text" name="fname" id="fname" defaultValue={profileData.fname} placeholder="First Name" onChange={handleInput} />
              </div>
            </div>
            <div className="col-6">
              <div className="from-group mb-2">
                <label htmlFor="lname">Middle Name</label>
                <input type="text" name="mname" id="mname" defaultValue={profileData.mname} placeholder="Middle Name" onChange={handleInput} />
              </div>
            </div>
            <div className="col-6">
              <div className="from-group mb-2">
                <label htmlFor="lname">Last Name</label>
                <input type="text" name="lname" id="lname" defaultValue={profileData.lname} placeholder="Last Name" onChange={handleInput} />
              </div>
            </div>
            <div className="col-6">
              <div className="from-group mb-2">
                <label htmlFor="gender">Gender<span className="tx-red">*</span></label>
                <select
                  name="gender"
                  id="gender"
                  defaultValue={profileData.gender}
                  onChange={handleInput}
                  className="custom-select"
                >
                  <option value="">Select</option>
                  <option value="M" selected={profileData.gender === "M"}>Male</option>
                  <option value="F" selected={profileData.gender === "F"}>Female</option>
                  <option value="O" selected={profileData.gender === "O"}>Others</option>
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="from-group mb-2">
                <label htmlFor="dob">Date Of Birth<span className="tx-red">*</span></label>
                <input type="date" name="dob" id="dob" placeholder="Enter your date of birth" defaultValue={profileData.dob} onChange={handleInput} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="from-group mb-2">
                <label htmlFor="place_of_birth">Place of Birth<span className="tx-red">*</span></label>
                <input type="text" name="place_of_birth" id="place_of_birth" placeholder="Enter your place of birth" defaultValue={profileData.place_of_birth} onChange={handleInput} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="from-group mb-2">
                <label htmlFor="country of birth">Country of Birth<span className="tx-red">*</span></label>
                <select name="country_of_birth" id="country_of_birth" onChange={handleInput} Value={profileData.country_of_birth} className="custom-select">
                  <option value="">Select</option>
                  {countryData.map((value) => (
                    <option
                      key={value.country_code}
                      value={value.country_code}

                      selected={value.country_code === profileData.country_of_birth ? true : false}
                    >
                      {value.country_name}
                    </option>
                  ))}
                </select>
                {/* <input type="text" name="country_of_birth" id="country_of_birth" placeholder="Enter your country of birth" defaultValue={profileData.country_of_birth}  onChange={handleInput}/> */}
              </div>
            </div>
            {IsMinor == 0 ?
              <div className="col-12">

                <div className="from-group mb-2">
                  <label htmlFor="pan">PAN Number<span className="tx-red">*</span></label>
                  <input type="text" name="pan" style={{ textTransform: "uppercase" }} id="pan" defaultValue={profileData.pan} onChange={handleInput} placeholder="Enter your PAN number" />
                </div>


                {/* {MinorText ? <p className="tx-13">Your Invest Type : <span className="tx-red">{MinorText}</span></p>:''} */}
              </div> : ""}
            <div className="col-lg-12">
              <div className="from-group mb-2">
                <label htmlFor="fname">I have a Sales Code</label>
                <input type="text" name="sales_code" id="" defaultValue={profileData.sales_code} placeholder="Sales code" onChange={handleInput} />
              </div>
            </div></>:''}          
            </div>
        </div>
        <div className="footer-button  ">
          {/* <button
            className="btn btn-primary btn-lg btn-full"
            onClick={firstStep}
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading ? (
              <img src="/img/loder01.gif" width="60px" height="11px" />
            ) : (
              "Continue"
            )}
          </button> */}

{step3 == true ?<>
  <div className='d-flex'>
  <button type="button" onClick={()=>{setStep1(true);setStep3(false)}} className="btn btn-outline-primary btn-lg btn-full">Back</button>
                    {kycEncrytStatus1 == false ?

                      <button
                        className="btn btn-primary btn-lg btn-full ms-1"
                        onClick={firstStep}
                
                      >
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}
                      </button>
                      :""}

                      </div>
                      </>:''}
            
                      {step2 == true ?<>
               
                      <button
            className="btn btn-primary btn-lg btn-full btn-md me-1"
            onClick={(e)=>{KYCprocess(e)}}
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading ? (
              <img src="/img/loder01.gif" width="60px" height="11px" />
            ) : (
              "Do KYC"
            )}
          </button>
                      <button type="button" onClick={()=>{setStep1(true);setStep2(false)}} className="btn btn-outline-primary btn-lg btn-full mt-3">Back</button></>
                      :""}
        </div>


      </MobileView>
        </>
    )
}
export default CompleteProfileNew