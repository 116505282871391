import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
import { ApiService } from "../../Components/Services/apiServices";
let apiServices = new ApiService();

function DirectLogin(){
    const didMountRef = useRef(true)
    const {id} = useParams()
   const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {

 const headers = {
    'X-Authorization': `Bearer ${id}`
};
        const dataString = {
        }
        apiServices.directloginbyadminpostrequest(dataString,headers).then(res => {
            localStorage.setItem('AUTH_TOKEN', res.data.auth_token);
           if(res.data.auth_token){ 
            if (res.data.data.gs_status == 0) {
            
                navigate("/completeprofile");
            }
            else if (res.data.data.gs_bse_onboard_status == 0) {
                window.location.href = "/bseonboarding/" + res.data.data.gs_bse_onboard_step
            }
            else if (res.data.data.gs_bse_onboard_status == 1) {
             
                window.location.href = "/bseaufform";
            }
            else if (res.data.data.gs_bse_onboard_status == 2) {
                window.location.href = "/dashboard";
            }  
        }
        else{
            navigate("/");
        } 
        })
    }
    didMountRef.current = false;
    }, []);

    return (

<>
<section className="sec-pad-md">
                    <div className="container">
                        <div className="row g-4">
                         
                            <div className="col-lg-12">
                                <div className="acpanel">
                               

                                    <div className="acpanel-body center-content">
                                    <Hourglass
            ariaLabel="Loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#304AA7', '#304AA7']}
          />
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                    <h4>Please wait for a while</h4>
                                        <p>We are redirecting you to user login</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
</>
    )
}
export default DirectLogin