import React, { useRef } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
let apiServices = new ApiService();
function InvestorConcent(){
    const navigate = useNavigate()

    const giveconcent = () => {
    
        const dataString = {
        }
        apiServices.getinvestorconcentPostRequest(dataString).then(result => {
        if(result.data.status == "success"){
            navigate(result.data.message)
        }
        else{
            toast(result.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            return false
        }

          
        })
      }
    
      const skipfornow=()=>{
        navigate("/dashboard")
      }
    return(
        <>
        <ToastContainer/>
        <BrowserView>
        <Header/>
        <h4>Provide Consent for One Glance Statement</h4>
        <p>Provide Consent to display complete portfolio under your CAN, even the one invested with other brokers</p>
        <button onClick={giveconcent}>Provide Concent</button>
        <button onClick={skipfornow}>Skip For Now</button>
        <p><strong>Benefits of providing Consent : </strong>You have to provide your consent one time for us to enable your complete one glance statement. You can view and transact all you funds, invested with any broker in folio form (Not in Demat Form) from one single dashboard.</p>
        <Footer/>
        </BrowserView>
        <MobileView>
            
        </MobileView>
        </>
    )
}
export default InvestorConcent