import React from "react";
import { BrowserView, MobileView } from "react-device-detect";


function DeleteAccount(){
    return(
        <>

<BrowserView>
              
                <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="crds">
                                        <div className="crds-body p-50">
                                            <div className="text-center mb-30">
                                                <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                <h5>Delete Goalstox Account</h5>
                                               
                                            </div>
                                            <div className="from-group mb-20">
                                                <label> Name</label>
                                                <input type="text" name="contact-name" id="contact-name" placeholder="Your name"  />
                                            </div>
                                            <div className="from-group mb-20">
                                                <label> Email</label>
                                                <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email"/>
                                            </div>
                                            <div className="from-group mb-20">
                                                <label> Phone Number</label>
                                                <input type="number" name="contact-phone" id="contact-phone" placeholder="Phone number"  />
                                            </div>
                                            <div className="from-group mb-20">
                                                <label> Message</label>
                                                <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message" ></textarea>
                                            </div>
                                           
                                            <button
                                                className="btn btn-primary btn-lg btn-full mb-4"
                                            
                                            >
                                               Delete Account
                                            </button>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
               
                <div className="p25 mt-5">
                    {/* <Link to="http://192.168.1.20:3020/" className="backbutton"><img src="img/back.png" className="wd-20" /></Link> */}

                    <div className="mb-4 text-center">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <h5>Delete Goalstox Account</h5>
                       
                    </div>
                    <div className="from-group mb-3">
                        <label>Name</label>
                        <input type="text" name="contact-name" id="contact-name" placeholder="Your name"  />
                    </div>
                    <div className="from-group mb-3">
                        <label>Email</label>
                        <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email"/>
                    </div>
                    <div className="from-group mb-3">
                        <label>Phone Number</label>
                        <input type="number" name="contact-phone" id="contact-phone" placeholder="Phone number"  />
                    </div>
                    <div className="from-group mb-3">
                        <label>Message</label>
                        <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message" ></textarea>
                    </div>
                  
                    <button
                        className="btn btn-primary btn-lg btn-full mb-4"
                       
                    >
                        Delete Account
                    </button>
                   
                </div>
            </MobileView>
 
        </>
    )
}
export default DeleteAccount