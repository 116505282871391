import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
function Notification(){
    const navigate = useNavigate()
    return(
        <>
        {/* <BrowserView>
        <Header/>
        <div className="subheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <h1>Notification</h1>
                    <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Data</Breadcrumb.Item>
              </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </BrowserView> */}
        <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" >
            <i class="d-icon-arrow-left tx-22" onClick={()=>{navigate("/dashboard")}}></i>
            <div className="ms-3">
            <h6 className="mb-0">Notification</h6>
            </div>
            </div>
            </div>
          </div>
        </header>
        </MobileView>
        </>
    )
}

export default Notification