import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ApiService } from "../../Components/Services/apiServices";
import Finance from 'financejs';
import xirr from 'xirr';
import moment from 'moment'
let apiServices = new ApiService();

const finance = new Finance();
function Portfoliodetails() {
    const navigate = useNavigate()
    const [GoalstoxHoldingsData, setGoalstoxHoldingsData] = useState([])
    const [singleHoldingsData, setSingalHoldingsData] = useState({})
    const [Userdata, setUserdata] = useState("")
    const [xirrVal, setXirr] = useState("")
    const [mutualFund, setMutualFund] = useState({})
    const { id,navval } = useParams()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            // getgoalstoxholdings()



            getsimilargoalstoxholding()
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                if (result.data.status == 'success') {
                    setUserdata(result.data.data)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    navigate("/")
                }
                else {
                    alert(result.data.message)
                }
            })

        }
        didMountRef.current = false;
    })

    const getgoalstoxholdings = () => {
        const dataString = {
            id: id
        }
        // setSpinnerLoading(true);
        apiServices.getgoalstoxholdingsdataPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                // console.log("",res.data.data[0].similarfunds);
                setGoalstoxHoldingsData(res.data.data)

            } else {
                // setSpinnerLoading(false);
            }
        })
    }
    const getsimilargoalstoxholding = () => {
        const dataString = {
            id: id
        }
        apiServices.getgoalstoxholdingsdetaildatapostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                // console.log("",res.data.data[0].similarfunds);
                console.log("Hjjgj", res.data.data)
                setGoalstoxHoldingsData(res.data.data)
                setSingalHoldingsData(res.data.holdingdata)
                setMutualFund(res.data.portfoliomutualfunddata)
                const cashFlows = res.data.xirrvalueArray;
                const dates = res.data.xirrdatesArray
                console.log('cashFlows',cashFlows)
                console.log(dates)

const transactions = cashFlows.map((amount, index) => {
    return {
        amount: amount,
        when: new Date(dates[index]) // Convert each date string to a Date object
    };
});

const rate = xirr(transactions);
const xirrvalue = (rate * 100).toFixed(2);
              
                setXirr((xirrvalue))


              
            } else {
                // setSpinnerLoading(false);
            }

        })
    }
    return (
        <>
            <BrowserView>
                <Header />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {singleHoldingsData ?
                                    <h1>{singleHoldingsData?.pg_schemename}</h1> :
                                    <Skeleton width="100%" height="14px" />}
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Portfolio</Breadcrumb.Item>

                                    <Breadcrumb.Item active href="">  {singleHoldingsData ? singleHoldingsData.pg_schemename : <Skeleton width="500px" height="14px" />}</Breadcrumb.Item>

                                </Breadcrumb>

                            </div>

                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row g-2">

                            <div className="col-lg-9">
                                {GoalstoxHoldingsData.length > 0 ?
                                    GoalstoxHoldingsData.map((subvalue) => (

                                        <div className="invbox">
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Trans Date</span>
                                                <span className="fw600">{subvalue.pg_new_trans_date}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Trans Type</span>
                                                <span className="fw600">{subvalue.pg_trans_desc}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Folio Number</span>
                                                <span className="fw600">{subvalue.pg_folio_no}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">NAV value per Unit</span>
                                                <span className="fw600">{subvalue.pg_pur_price}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Units</span>
                                                <span className="fw600">{subvalue.pg_unitholding}</span>
                                            </div>
                                            <div className="invboxsec">
                                                <span className="tx-gray tx-12">Amount</span>

                                                {subvalue.amount && subvalue.amount == 'negative' ?
                                                    <span className="fw600">-{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</span>
                                                    : <span className="fw600">{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</span>
                                                }
                                            </div>
                                            {/* <div className="invboxsec">
                                <span className="tx-gray tx-12">Remarks</span>
                                <span className="fw600">GOALSTOX</span>
                                </div> */}

                                        </div>))
                                    :
                                    <div className="invbox">
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Trans Date</span>
                                            <Skeleton width="50px" height="14px" />

                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Trans Type</span>
                                            <Skeleton width="50px" height="14px" />
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Folio Number</span>
                                            <Skeleton width="50px" height="14px" />
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">NAV value per Unit</span>
                                            <Skeleton width="50px" height="14px" />
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Units</span>
                                            <Skeleton width="50px" height="14px" />
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Amount</span>
                                            <Skeleton width="50px" height="14px" />
                                        </div>
                                        {/* <div className="invboxsec">
                              <span className="tx-gray tx-12">Remarks</span>
                              <span className="fw600">GOALSTOX</span>
                              </div> */}

                                    </div>

                                }

                            </div>
                            <div className="col-lg-3">
                                <div className="portfolioBox mb-3">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="probsec">
                                                <div className="probsecbox">
                                                    <h3 className="fw600">{navval ? '₹'+navval : '-'}</h3>
                                                    <p className="mb-0 tx-gray tx-12">Value At NAV </p>
                                                </div>
                                                <div className="probsecbox">
                                                    <h3 className="fw600">{xirrVal != "Infinity" ? xirrVal + '%' : "-"}</h3>
                                                    <p className="mb-0 tx-gray tx-12">XIRR</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/investment") }}></i>
                                <div className="ms-3">
                                    {singleHoldingsData ?
                                        <h6 className="mb-0">
                                            {singleHoldingsData?.pg_schemename} </h6> :
                                        <Skeleton width="100%" height="14px" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                <section className="sec-pad-sm mt-5 pt-4">
                    <div className="container">
                        <div className="row g-2">
                        <div className="siptabs">
                        <ul>
                            <li className="border-end active" 
                            ><div className="text-center">Value At NAV    <br></br><small>({navval ? '₹'+navval : '-'})</small></div></li>
                         
                            <li className="border-end active" 
                            ><div className="text-center">XIRR   <br></br><small>({xirrVal ? xirrVal+"%" : '-'})</small></div></li>
                        </ul>
                    </div>
                            <div className="col-lg-12">
                                {GoalstoxHoldingsData.length > 0 ?
                                    GoalstoxHoldingsData.map((subvalue) => (
                                        <div className="invbox">
                                            {/* <div className="invboxfund">
                                                <div className="invboxfund-icon"><img src="https://goalstox.codexo.in/csadmin/public/img/uploads/amc/1699270005.png"/></div>
                                                <div className="invboxfund-details">
                                                <a href="#" className="tx-14">ADITYA BIRLA SUN LIFE FRONTLINE EQUITY FUND - GROWTH</a>
                                                <ul>
                                                <li>External</li>
                                                </ul>
                                                </div>
                                            </div> */}
                                            <div style={{ width: '70%' }}>
                                                <span className="tx-gray tx-12">Trans Date : <span className="fw600">{subvalue.pg_new_trans_date}</span></span><br />
                                                <span className="tx-gray tx-12">Trans Type : <span className="fw600">{subvalue.pg_trans_desc}</span></span><br />
                                                <span className="tx-gray tx-12">Folio Number : <span className="fw600">{subvalue.pg_folio_no}</span></span>
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                <span className="tx-gray tx-12">NAV : <span className="fw600">{subvalue.pg_pur_price}</span></span><br />
                                                <span className="tx-gray tx-12">Units : <span className="fw600">{subvalue.pg_unitholding}</span></span><br />
                                                <span className="tx-gray tx-12">Amount :
                                                    <span className="fw600"> {subvalue.amount && subvalue.amount == 'negative' ?
                                                        <span className="fw600">-{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</span>
                                                        : <span className="fw600">{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</span>
                                                    }</span>
                                                </span>
                                            </div>
                                        </div>)) : <>
                                        <Skeleton width="100%" height={100} />
                                        <Skeleton width="100%" height={100} />
                                        <Skeleton width="100%" height={100} />
                                        <Skeleton width="100%" height={100} />
                                        <Skeleton width="100%" height={100} />
                                        <Skeleton width="100%" height={100} />
                                    </>


                                }

                            </div>
                        </div>
                    </div>
                </section>
            </MobileView>
        </>
    )
}

export default Portfoliodetails