import React from "react";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin, InfinitySpin } from "react-loader-spinner";
function Detailsnominee() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    let apiServices = new ApiService();
    const [nomineeData, setNomineeData] = useState([])
    const [nomineeopt, setnomineeopt] = useState("")
    const [Addnomineestep1, setAddnomineStep1] = useState(false)
    const [UserData, setUserData] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [nomineeFirst, setNomineeFirst] = useState(true)
    const [nomineeSecond, setNomineeSecond] = useState(false)
    const [nomineeThird, setNomineeThird] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)

    const [nomineeDetails, setNomineeDetails] = useState(
        {
            nomineeName: "",
            nomineeRelationship: "",
            nomineeApplicable: "",
            nomineeSelected: "N",


        },
    );
    const [nomineeDetails1, setNomineeDetails1] = useState(
        {
            nomineeName1: "",
            nomineeRelationship1: "",
            nomineeApplicable1: "",
            nomineeSelected1: "N",
            nomineeOption1: "Y",

        },
    );
    const [nomineeDetails2, setNomineeDetails2] = useState(
        {
            nomineeName2: "",
            nomineeRelationship2: "",
            nomineeApplicable2: "",
            nomineeSelected2: "N",
            nomineeOption2: "Y",
        },
    );
    const Location = window.location.pathname
    useEffect(() => {
        if (didMountRef.current) {
            // let dataString={}

            // apiServices.bsegetuserdataPostRequest({}).then(result => {

            //     if (result.data.status == 'success') {
            //         setNomineeData(result.data.bse_nominee_data)
            //         setUserData(result.data.data)
            //         setnomineeopt(result?.data?.bse_register_data?.bur_nominee_opt)
            //     }
            //     else if (result.data.status == "error" && result.data.message == "Session expired") {
            //         localStorage.removeItem('AUTH_TOKEN');
            //         navigate("/")
            //     }
            //     else {
            //         alert(result.data.message)
            //     }

            // })

            // if(result.data.bse_register_data?.bur_nominee_opt=="N"){
            //     setNomineeOption(result.data.bse_register_data?.bur_nominee_opt)
            //     setNomineeSecond(false)
            //     setNomineeThird(false)
            //     setNomineeFirst(false)
            //   }
            getUserdata()

        }
        didMountRef.current = false;
    }, []);
    const getUserdata = () => {
        setSubmitLoader(true)
        apiServices.bsegetuserdataPostRequest({}).then(result => {

            if (result.data.status == 'success') {
                setNomineeData(result.data.bse_nominee_data)
                setUserData(result.data.data)
                setnomineeopt(result?.data?.bse_register_data?.bur_nominee_opt)
                setSubmitLoader(false)
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                setSubmitLoader(false)
                navigate("/")
            }
            else {
                alert(result.data.message)
                setSubmitLoader(false)
            }

        })
    }

    const AddNominee = () => {
        setAddnomineStep1(true)
    }


    const handleSubmit = (e) => {

        if (!nomineeDetails.nomineeName) {
            toast.error('Please enter the Nominee Name 1 ');
            return;
        }
        if (!nomineeDetails.nomineeRelationship) {
            toast.error('Please enter the Nominee Relation 1');
            return;

        }
        if (!nomineeDetails.nomineeApplicable) {
            toast.error('Please enter the Nominee Applicable 1');
            return

        }
        if (nomineeDetails.nomineeApplicable && !nomineeDetails1.nomineeApplicable1 && !nomineeDetails2.nomineeApplicable2) {

            let nomenumber = Number(nomineeDetails.nomineeApplicable)

            if (nomenumber < 100 || nomenumber > 100) {

                toast.error(' Total Nominee Applicable percent ratio sholud be 100');
                return
            }
        }

        if (nomineeDetails1.nomineeName1) {
            if (!nomineeDetails1.nomineeName1) {
                toast.error('Please enter the Nominee Name 2 ');

            }
            if (!nomineeDetails1.nomineeRelationship1) {
                toast.error('Please enter the Nominee Relation 2');
                return

            }
            if (!nomineeDetails1.nomineeApplicable1) {
                toast.error('Please enter the Nominee Applicable 2');
                return

            }
            if (nomineeDetails1.nomineeApplicable1 && nomineeDetails.nomineeApplicable && !nomineeDetails2.nomineeApplicable2) {
                if (Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) < 100 || Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) > 100) {
                    toast.error('Total Nominee Applicable percent ratio should be 100');
                    return
                }
            }
            if (nomineeDetails2.nomineeName2) {
                if (!nomineeDetails2.nomineeName2) {
                    toast.error('Please enter the Nominee Name 3 ');
                    return

                }
                if (!nomineeDetails2.nomineeRelationship2) {
                    toast.error('Please enter the Nominee Relation 3');
                    return

                }
                if (!nomineeDetails2.nomineeApplicable2) {
                    toast.error('Please enter the Nominee Applicable 3');
                    return

                }


                if (nomineeDetails2.nomineeApplicable2 && nomineeDetails1.nomineeApplicable1 && nomineeDetails.nomineeApplicable) {
                    if (Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) + Number(nomineeDetails2.nomineeApplicable2) < 100 || Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) + Number(nomineeDetails2.nomineeApplicable2) > 100) {
                        toast.error('Total Nominee Applicable percent ratio should be 100');
                        return
                    }
                }

            }
        }

        setIsLoading(true)
        const dataString = {
            nomineeOpt: "Y",
            nomineeDetails: nomineeDetails,
            nomineeDetails1: nomineeDetails1,
            nomineeDetails2: nomineeDetails2
        };

        apiServices.bseusernomineePostRequest(dataString).then((result) => {
            if (result.data.status === "success") {
                const newdatastring = {
                    type: "MOD"
                }
                apiServices.clientregitrationPostRequest(newdatastring).then((response) => {
                    if (response.data.status == "success") {
                        toast.success("Nominee deatils added successfully")
                        setIsLoading(false)
                        setAddnomineStep1(false)
                        getUserdata()
                        //   navigate("/dashboard");
                    } else if (response?.data?.status == "error") {
                        toast.error(response?.data?.message)
                        setIsLoading(false)
                    }

                    else {
                        toast.error(response?.data?.data?.ResponseString)
                        setIsLoading(false)
                    }
                })
            } else if (
                result.data.status === "error" &&
                result.data.message === "Session expired"
            ) {
                setIsLoading(false)
                navigate("/");
                localStorage.removeItem("AUTH_TOKEN");
            } else {
                setIsLoading(false)
                toast.error(result.data.message);
            }
        })
            .catch((error) => {
                console.error("API Error:", error);
            });
    };


    const handleInputChange = (e, index) => {

        const { name, value } = e.target;
        setNomineeDetails({ ...nomineeDetails, [name]: value });
    };
    const handleInputChange1 = (e, index) => {


        const { name, value } = e.target;
        setNomineeDetails1({ ...nomineeDetails1, [name]: value });
    };
    const handleInputChange2 = (e, index) => {


        const { name, value } = e.target;
        setNomineeDetails2({ ...nomineeDetails2, [name]: value });
    };
    const showSecondNominee = () => {
        setNomineeSecond(true)
    }
    const showThirdNominee = () => {
        setNomineeThird(true)
    }
    const clickOnCloseSecondButton = () => {
        setNomineeSecond(false)
        setNomineeDetails1({ ...nomineeDetails1, nomineeName1: "", nomineeRelationship1: "", nomineeApplicable: "" })
    }
    const clickOnCloseThirdButton = () => {
        setNomineeThird(false)
        setNomineeDetails2({ ...nomineeDetails, nomineeName: "", nomineeRelationship: "", nomineeApplicable: "" })
    }
    console.log(nomineeopt, nomineeData.length, Addnomineestep1, "changepassword nominelength data nomineopt")

    return (
        <>
            {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <BrowserView>
                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/nominee-details">Nominee Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-md">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <div className="account-user mb-3">
                                        <div className="au-imag"><img src="/img/user.png" /></div>
                                        <div class="au-content"><h6 class="mb-0">Hi! {UserData?.gs_fname ? UserData.gs_fname : ""}</h6><p class="mb-0">{UserData?.gs_email}</p></div>
                                    </div>
                                    <div className="aclist mb-3">
                                        <ul>
                                            <li className={Location == "/setting" ? "active" : ""}><a href="/setting">Basic Details<i class="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/change-password" ? "active" : ""}><a href="/change-password">Change Password<i class="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/nominee-details" ? "active" : ""}><a href="/nominee-details">Nominee Details<i class="icon-arrow-right13"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9">
                                {/* <div className="acpanel">
                                    <div className="acpanel-header"><h4>Nominee details</h4></div>

                                </div> */}
                                {/* Add nominee detail button */}
                                {nomineeopt == "N" || nomineeopt == null || (nomineeopt == "Y" && nomineeData.length == 0) ? <>
                                    {Addnomineestep1 == false ? <>

                                        <div className="col-lg-4 ">
                                            <div className="addbanklist " onClick={() => { AddNominee() }}>
                                                <img src="img/plus.png" className="wd-42 mb-2"></img>
                                                <p className="mb-0 tx-theme fw700">Add Nominee Details</p>
                                            </div>
                                        </div>

                                    </> : ""}


                                </> : <>
                                    {/* Nominee details list */}

                                    {nomineeData.length > 0 && Addnomineestep1 == false && nomineeData.map((value, index) => (

                                        <>

                                            <div className="acpanel">
                                                <div className="acpanel-header"><h4>Nominee {index + 1}</h4></div>
                                                <div className="acpanel-body">



                                                    <div>


                                                        <div className="nomineelist-content">
                                                            <ul className="">
                                                            <li ><span>Name :</span>{value.und_nominee_name}</li>
                                                                <li > <span>Relationship :</span>{value.und_nominee_relationship_name?value.und_nominee_relationship_name :value.und_nominee_relationship}</li>
                                                                <li > <span>Nominee Applicable :</span>{value.und_nominee_applicable} %</li>
                                                            </ul>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>






                                        </>
                                    ))}
                                </>}
                                {/* Nominee details add Form */}
                                {Addnomineestep1 == true ? <>

                                    <div className="p-3 panel holding-panel phbox active">
                                        {nomineeFirst == true ?
                                            <>
                                                <div>

                                                    <div className="mb-2">
                                                        <h6>
                                                            Nominee Details 01 (Default Nominee)
                                                        </h6>
                                                        <div className="row g-2">
                                                            <div className="col-lg-6">
                                                                <div className="from-group mb-3">
                                                                    <label>Nominee Name<span className="tx-red">*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="nomineeName"
                                                                        id="nomineeName"
                                                                        defaultValue={nomineeDetails.nomineeName}
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="from-group mb-3">
                                                                    <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="nomineeRelationship"
                                                                        id="nomineeRelationship"
                                                                        defaultValue={nomineeDetails.nomineeRelationship}
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2">
                                                            <div className="col-lg-12">
                                                                <div className="from-group mb-3">
                                                                    <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                                    <input
                                                                        type="number"
                                                                        name="nomineeApplicable"
                                                                        id="nomineeApplicable"
                                                                        defaultValue={nomineeDetails.nomineeApplicable}
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-lg-6">
                            <div className="from-group mb-3">
                              <label>Nominee Minor Flag</label>
                              <select
                                name="nomineeSelected"
                                value={nominee.nomineeSelected}
                                className="custom-select"
                                id="nomineeSelected"
                                onChange={(e) => handleInputChange(e, index)}
                              >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                            </div>
                          </div> */}
                                                        </div>
                                                    </div>
                                                    {nomineeDetails.nomineeSelected == 'Y' ?
                                                        <div className="row g-2" >
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">
                                                                    <label>Nominee DOB</label>
                                                                    <input
                                                                        type="date"
                                                                        name="nomineeDob"
                                                                        id="nomineeDob"
                                                                        value={nomineeDetails.nomineeDob}


                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="from-group mb-3">
                                                                    <label>Nominee Guardian Name</label>
                                                                    <input
                                                                        type="text"
                                                                        name="nomineeGuardian"
                                                                        id="nomineeGuardian"
                                                                        value={nomineeDetails.nomineeGuardian}

                                                                        onChange={(e) => handleInputChange(e)}
                                                                    // onChange={(e) => setNomineeGuardian(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                    {nomineeSecond !== true ?
                                                        <button
                                                            className="btn btn-secondary btn-lg "
                                                            onClick={showSecondNominee}
                                                        >
                                                            Add More
                                                        </button> : ""}
                                                </div>
                                            </>



                                            : ""}

                                        {nomineeSecond == true ?


                                            <div>


                                                <div className="mb-2">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6>
                                                            Nominee Details 02
                                                        </h6>
                                                        <span ><i className="icon-cross" onClick={() => { clickOnCloseSecondButton() }}></i></span>
                                                    </div>

                                                    <div className="row g-2">
                                                        <div className="col-lg-6">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee Name<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="nomineeName1"
                                                                    id="nomineeName"
                                                                    defaultValue={nomineeDetails1.nomineeName1}
                                                                    onChange={(e) => handleInputChange1(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="nomineeRelationship1"
                                                                    id="nomineeRelationship"
                                                                    defaultValue={nomineeDetails1.nomineeRelationship1}
                                                                    onChange={(e) => handleInputChange1(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2">
                                                        <div className="col-lg-12">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="number"
                                                                    name="nomineeApplicable1"
                                                                    id="nomineeApplicable"
                                                                    defaultValue={nomineeDetails1.nomineeApplicable1}
                                                                    onChange={(e) => handleInputChange1(e)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* {nomineeDetails.nomineeSelected == 'Y' ?
                        <div className="row g-2" >
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label>Nominee DOB</label>
                              <input
                                type="date"
                                name="nomineeDob"
                                id="nomineeDob"
                                value={nomineeDetails.nomineeDob}

                                
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="from-group mb-3">
                              <label>Nominee Guardian Name</label>
                              <input
                                type="text"
                                name="nomineeGuardian"
                                id="nomineeGuardian"
                                value={nomineeDetails.nomineeGuardian}

                                onChange={(e) => handleInputChange(e)}
                              // onChange={(e) => setNomineeGuardian(e.target.value)}
                              />
                            </div>
                          </div>
                        </div> : ""} */}
                                                {nomineeThird !== true ?
                                                    <button
                                                        className="btn btn-secondary btn-lg "
                                                        onClick={showThirdNominee}
                                                    >
                                                        Add More
                                                    </button> : ""}



                                            </div> : ""}
                                        {nomineeThird == true ?


                                            <div>


                                                <div className="mb-2">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6>
                                                            Nominee Details 03
                                                        </h6>
                                                        <span ><i className="icon-cross" onClick={() => { clickOnCloseThirdButton() }}></i></span>
                                                    </div>

                                                    <div className="row g-2">
                                                        <div className="col-lg-6">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee Name<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="nomineeName2"
                                                                    id="nomineeName"
                                                                    defaultValue={nomineeDetails2.nomineeName2}
                                                                    onChange={(e) => handleInputChange2(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="nomineeRelationship2"
                                                                    id="nomineeRelationship"
                                                                    defaultValue={nomineeDetails2.nomineeRelationship2}
                                                                    onChange={(e) => handleInputChange2(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2">
                                                        <div className="col-lg-12">
                                                            <div className="from-group mb-3">
                                                                <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                                <input
                                                                    type="number"
                                                                    name="nomineeApplicable2"
                                                                    id="nomineeApplicable"
                                                                    defaultValue={nomineeDetails2.nomineeApplicable2}
                                                                    onChange={(e) => handleInputChange2(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {nomineeDetails.nomineeSelected == 'Y' ?
                        <div className="row g-2" >
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label>Nominee DOB</label>
                              <input
                                type="date"
                                name="nomineeDob"
                                id="nomineeDob"
                                value={nomineeDetails.nomineeDob}

                                // onChange={(e) => setNomineeDob(e.target.value)}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="from-group mb-3">
                              <label>Nominee Guardian Name</label>
                              <input
                                type="text"
                                name="nomineeGuardian"
                                id="nomineeGuardian"
                                value={nomineeDetails.nomineeGuardian}

                                onChange={(e) => handleInputChange(e)}
                              // onChange={(e) => setNomineeGuardian(e.target.value)}
                              />
                            </div>
                          </div>
                        </div> 
                        : ""} */}



                                            </div> : ""}
                                        <button
                                            className="btn btn-primary btn-lg btn-full mt-3"
                                            onClick={handleSubmit}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <img src="/img/loder01.gif" width="60px" height="11px" />
                                            ) : (
                                                "Save"
                                            )}

                                        </button>

                                    </div>

                                </> : ""}




                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">Nominees</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-main">


                    <div className="">
                        {/* Add nominee detail button */}
                        {nomineeopt == "N" || nomineeopt == null || (nomineeopt == "Y" && nomineeData.length == 0) || (nomineeopt == "N" && nomineeData.length > 0) ? <>
                            {Addnomineestep1 == false ? <>

                                <div >
                                    <div className="addbanklist " onClick={() => { AddNominee() }}>
                                        <img src="img/plus.png" className="wd-24 me-2"></img>
                                        <p className="mb-0 tx-theme fw700">Add Nominee Details</p>
                                    </div>
                                </div>

                            </> : ""}
                        </> : <>
                            {/* Nominee details list */}
                            {nomineeData.length > 0 && Addnomineestep1 == false && nomineeData.map((value, index) => (
                                <>
                                <div className="p15">
                                <div className="nomineelist-content" style={{border: "1px solid #ddd",borderRadius: "10px"}}>
                                <ul >
                                                                <li ><span>Name :</span>{value.und_nominee_name}</li>
                                                                <li > <span>Relationship :</span>{value.und_nominee_relationship_name?value.und_nominee_relationship_name :value.und_nominee_relationship}</li>
                                                                <li > <span>Nominee Applicable :</span>{value.und_nominee_applicable} %</li>
                                                            </ul>
                                </div>
                                </div>

                                    {/* <div className="acpanel-body acpanel mt-5 ">
                                        <div>
                                            <div>
                                                <h5 className="p-3 pb-0  d-flex align-items-center">Nominee {index + 1}</h5>
                                            </div>
                                            <hr></hr>
                                            <div className="p-4">
                                                <p className="tx-gray fw-bold tx-uppercase">Name :     <span className="text-dark">{value.und_nominee_name}</span></p>
                                                <p className="tx-gray fw-bold">Relationship : <span className="text-dark">{value.und_nominee_relationship}</span></p>
                                                <p className="tx-gray fw-bold">Nominee % : <span className="text-dark">{value.und_nominee_applicable}</span></p>

                                            </div>
                                        </div>

                                    </div> */}

                                </>
                            ))}
                        </>}
                        {/* Nominee details add Form */}
                        {Addnomineestep1 == true ? <>
                            <div className="p15  panel holding-panel phbox active">
                                {nomineeFirst == true ?
                                    <div>
                                        {/* {nomineeOption === "Y" ? ( */}
                                        <div className="mb-2">
                                            <h6 className="mt-3 mb-3">
                                                Nominee Details 1
                                                (Default Nominee)
                                            </h6>
                                            <div className="row g-2">
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name={"nomineeName"}
                                                            id="nomineeName"
                                                            value={nomineeDetails.nomineeName}
                                                            onChange={(e) => handleInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name={"nomineeRelationship"}
                                                            id="nomineeRelationship"
                                                            value={nomineeDetails.nomineeRelationship}
                                                            onChange={(e) => handleInputChange(e)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="from-group mb-3">
                                                            <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                            <input
                                                                type="number"
                                                                name={"nomineeApplicable"}
                                                                id="nomineeApplicable"
                                                                value={nomineeDetails.nomineeApplicable}
                                                                onChange={(e) => handleInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* ) : null} */}
                                        {nomineeSecond !== true ?
                                            <div className="dflex align-items-center tx-theme" onClick={showSecondNominee}><i className="icon-plus2 me-2"></i>Add More Nominee</div> : ""}
                                    </div>
                                    : ""}
                                {nomineeSecond == true ?
                                    <div>
                                        <div className="mb-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6>
                                                    Nominee Details 02
                                                </h6>
                                                <span ><i className="icon-cross" onClick={() => { clickOnCloseSecondButton() }}></i></span>
                                            </div>
                                            <div className="row g-2">
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="nomineeName1"
                                                            id="nomineeName"
                                                            defaultValue={nomineeDetails1.nomineeName1}
                                                            onChange={(e) => handleInputChange1(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="nomineeRelationship1"
                                                            id="nomineeRelationship"
                                                            defaultValue={nomineeDetails1.nomineeRelationship1}
                                                            onChange={(e) => handleInputChange1(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-3">
                                                        <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                        <input
                                                            type="number"
                                                            name="nomineeApplicable1"
                                                            id="nomineeApplicable"
                                                            defaultValue={nomineeDetails1.nomineeApplicable1}
                                                            onChange={(e) => handleInputChange1(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {nomineeThird !== true ?
                                            <div className="dflex align-items-center tx-theme" onClick={showThirdNominee}><i className="icon-plus2 me-2"></i>Add More Nominee</div> : ""}
                                    </div> : ""}
                                {nomineeThird == true ?
                                    <div>
                                        <div className="mb-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6>
                                                    Nominee Details 3
                                                </h6>
                                                <span ><i className="icon-cross" onClick={() => { clickOnCloseThirdButton() }}></i></span>
                                            </div>

                                            <div className="row g-2">
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="nomineeName2"
                                                            id="nomineeName"
                                                            defaultValue={nomineeDetails2.nomineeName2}
                                                            onChange={(e) => handleInputChange2(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-2">
                                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="nomineeRelationship2"
                                                            id="nomineeRelationship"
                                                            defaultValue={nomineeDetails2.nomineeRelationship2}
                                                            onChange={(e) => handleInputChange2(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="from-group mb-3">
                                                        <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                                        <input
                                                            type="number"
                                                            name="nomineeApplicable2"
                                                            id="nomineeApplicable"
                                                            defaultValue={nomineeDetails2.nomineeApplicable2}
                                                            onChange={(e) => handleInputChange2(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div> : ""}


                            </div>
                            <div className="footer-button">
                                <button
                                    className="btn btn-primary btn-lg btn-full "
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </> : ""}


                    </div>



                </div>

            </MobileView>
        </>
    )
}

export default Detailsnominee