import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
let apiServices = new ApiService();

function BankDetail() {
    const [bankData, setBankData] = useState([])
    const [micrName, setMicrName] = useState("")
    const [allBankData, setAllBankData] = useState({ bankaccountno: "", reenterbankaccountno: "", accounttype: "", bank: "", ifsc: "", micr: "", bankproof: "" })
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
        getBanks();
        const dataString = {
        }
        apiServices.getUserDataPostRequest(dataString).then(result => {
            if (result.data.status == 'success') {
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
              
                navigate("/")
            }
            else {
                alert(result.data.message)

            }
        })
    }
    didMountRef.current = false;
    }, []);
    const getBanks = () => {
        apiServices.getBanksGetRequest().then(result => {
            if (result.data.status == 'success') {
                setBankData(result.data.data)
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
              
               
             navigate("/login")
            }
            else {
                alert(result.data.message)
                
            }
        })
    }
    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setAllBankData({ ...allBankData, [key]: value })

        if (key == "ifsc") {
            if (value.length == 11) {

                apiServices.getMICRGetRequest(value).then(res => {
                    console.log(res)
                    if (res.status == 200) {
                        setMicrName(res.data.MICR)
                    }
                    else {
                        setMicrName('')
                    }
                })
                    .catch(error => {
                        setMicrName('')
                    }
                    )
            } else {
                setMicrName('')
            }
        }
        if (key == "crb_bank_micr1") {
            setMicrName(value)
        }
        else {
            setAllBankData({ ...allBankData, [key]: value })
        }
    }
    const savebankdetaildata = () => {
        var micr = '';
        if (micrName != null) {
            micr = micrName;
        }
        else {
            micr = allBankData.micr;
        }
        if (allBankData.bankaccountno == '' || allBankData.bankaccountno == undefined) {
            toast("Bank Account Number Field Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.reenterbankaccountno == '' || allBankData.reenterbankaccountno == undefined) {
            toast("Confirm Bank Account Number Field Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.reenterbankaccountno != allBankData.bankaccountno) {
            toast("Bank Account Number And Confirm Bank Account Number Field Should Be Same", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.accounttype == '' || allBankData.accounttype == undefined) {
            toast("Bank Account Type Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.bank == '' || allBankData.bank == undefined) {
            toast("Please Select Bank Account", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.ifsc == '' || allBankData.ifsc == undefined) {
            toast("Bank IFSC Field Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.ifsc.length < 11) {
            toast("IFSC Code is less than 11 characters", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (micr == '' || allBankData.micr == undefined) {
            toast("Bank MICR Field Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (micr.length < 9) {
            toast("Bank MICR Code is less than 9 characters", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        else if (allBankData.bankproof == '' || allBankData.bankproof == undefined) {
            toast("Bank Proof Field Is Required", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        const dataString = {
            "crb_bank_acc_no1": allBankData.bankaccountno,
            "crb_bank_acc_type1": allBankData.reenterbankaccountno,
            "crb_bank1": allBankData.bank,
            "crb_bank_micr1": micr,
            "crb_bank_ifsc1": allBankData.ifsc,
            "crb_bank_proof1": allBankData.bankproof,
        }
        apiServices.saveuserbankdataPostRequest(dataString).then(result => {
            if (result.data.status == 'success') {
            
                navigate("/investorconcent");
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                navigate("/login")
            }
            else {
                alert('something went wrong');
                window.location.reload();
            }
        })
    }
     return (
        <>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <div className="hero-banner hero-style-11 remote-training">
                <div className="container">
                    <div className="login-wrapper row align-items-center">
                        <div className="col-lg-12">
                            <div className="login-wrapper-inner">
                                <div className="mb-5">
                                    <h4 className="mb-2">Let's Create Your Profile</h4>
                                    <p>Your name will be taken as per (PAN Card)</p>

                                    <form>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="bankaccountno">Bank A/C No*</label>
                                                    <input type="text" name="bankaccountno" id="bankaccountno" placeholder="Bank A/C No" onChange={handleInput} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="reenterbankaccountno">Re-Enter Bank A/C No*</label>
                                                    <input type="text" name="reenterbankaccountno" id="reenterbankaccountno" onChange={handleInput} placeholder="Re-Enter Bank A/C No*" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="accounttype">Account Type*</label>
                                                    <select name="accounttype" id="accounttype" onChange={handleInput}>
                                                        <option value="">Select</option>
                                                        <option value="CA">Current Account</option>
                                                        <option value="CC">Cash Credit</option>
                                                        <option value="FCNR">Foreign Currency Non Resident</option>
                                                        <option value="NRE">Non Resident External Account</option>
                                                        <option value="NRO">Non Resident Ordinary A/c</option>
                                                        <option value="OD">Over Draft Account</option>
                                                        <option value="OTH">Others</option>
                                                        <option value="PSB">Post Office Savings Account</option>
                                                        <option value="SB">Savings Account</option>
                                                        <option value="SNRA">Special Non Resident Rupee A/c</option>
                                                        <option value="SNRR">Special Non Resident repatriable</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="bank">Bank*</label>
                                                    <select name="bank" id="bank" onChange={handleInput}>
                                                        <option value="">Select Bank</option>
                                                        {bankData.map((value) => (
                                                            <option value={value.bank_can_id}>{value.bank_name}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="ifsc">IFSC*</label>
                                                    <input type="text" name="ifsc" id="ifsc" placeholder="IFSC" onChange={handleInput} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">

                                                <div className="form-group">
                                                    <label for="micr">MICR*</label>
                                                    <input type="text" name="micr" id="micr" onChange={handleInput} placeholder="MICR" value={micrName != '' ? micrName : allBankData.micr} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="bankproof">Bank Proof*</label>
                                                    <select name="bankproof" id="bankproof" onChange={handleInput}>
                                                        <option value="">Select</option>
                                                        <option value="14">Latest Bank Passbook</option>
                                                        <option value="15">Latest Bank Account Statement</option>
                                                        <option value="77">Cancelled Cheque Copy</option>
                                                        <option value="78">Bank Letter</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="button" className="edu-btn btn-medium mt-3" onClick={savebankdetaildata}>Submit <i className="icon-4"></i></button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default BankDetail