import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ApiService } from "../../Components/Services/apiServices";
import SweetAlert from 'react-bootstrap-sweetalert';
function Orderdetail() {
    const navigate = useNavigate()
    let apiServices = new ApiService();
    const { id } = useParams()
    const didMountRef = useRef(true)
    const [imageUrl, setimageUrl] = useState("")
    const [fundimg, setfundimg] = useState("")
    const [Orderdetail, setOrderdetail] = useState()
    const [ifsccode,setifsccode]=useState("")
    const [bankdetail, setbankdetail] = useState("")
    const [show, setShow] = useState(false)
    const [warningShow , setWarningShow] = useState(false)
    const [cancelSIPMessage , setCancelSIPMessage] = useState("")
    const [isLoading,setIsLoading]=useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            let dataString = {
                txn_transaction_id: id
            }
            apiServices.getuserOrderdetailgetrequest(dataString).then((response) => {
                console.log(response, "responsehjk")
                if (response?.data?.status == "success") {
                    setOrderdetail(response?.data?.data)
                    setimageUrl(response?.data?.imgeURL)
                    setfundimg(response?.data?.amcmutualfund?.amc_image)
                    console.log(response?.data?.data?.trans_bank_ifsc,"ifscconsolerespo")
                    const dataStrings = {
          
                        neftIfscCode: response?.data?.data?.trans_bank_ifsc,
                        // bankBranch: bankDetails1.bankBranch
                      }
                    apiServices.ifscdataPostRequest(dataStrings).then(res => {
                        
                        if (res.status === 200 && res.data.ifsc && res.data.ifsc.bse_b_id) {
                            const bankName=res.data.ifsc.bse_bank_name
                            setbankdetail(bankName)
            
                        }
                      
                      })
                }

            })
           

          

           
        }
        didMountRef.current = false;
    }, [ifsccode])
    const getIndianTime = (createdat) => {
        const timestamp = createdat;
        const date = new Date(timestamp);

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        let formattedHours = hours % 12;
        formattedHours = formattedHours === 0 ? 12 : formattedHours;
        const period = hours < 12 ? 'AM' : 'PM';

        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${period}`;
        return formattedTime
    }
    const Hideaccountnumber = (accountNumbers) => {
        var accountNumber = accountNumbers.toString();
        let paddingChar = "*"
        const length = accountNumber.length;
        const hiddenPart = paddingChar.repeat(length - 4);
        const visiblePart = accountNumber.slice(-4);

        return hiddenPart + visiblePart;
    }

const cancelSIP =()=>{
    const dataString ={
        trans_id : Orderdetail.trans_id
    }
    setIsLoading(true)
    apiServices.bsecancelsippostrequest(dataString).then(res => {
        if(res.data.status == "success"){
            setIsLoading(false)
setShow(true)
setCancelSIPMessage(res.data.data)
        }
        else{
            setIsLoading(false)
            setCancelSIPMessage(res.data.data)
            setWarningShow(true)
        }

      })
}

const handleOkClick = () => {
    setShow(false);  
    navigate('/order');
  
  };
const handlewarningOkClick = () => {
    setWarningShow(false);
    window.location.reload()
  };
   
 

    return (
        <>
         <ToastContainer position={toast.POSITION.BOTTOM_CENTER} autoClose={3000} />
            <BrowserView>
                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="">Order Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row">
                               {Orderdetail!=="" ?<>
                                    
                               <div className="col-lg-8">
                                <div className="orderdetailsbox">
                                 
                                    <div className="orderdetailsbox-fund">
                                        <div className="oficonbox">
                                            <div className="oficonbox-icon"><img src={fundimg ? imageUrl + fundimg : "/img/defaltimage.png"} style={{ width: "55px", height: "55px" }}></img></div>
                                            <div className="tx-14 fw600">{Orderdetail?.trans_mf_name}</div>
                                        </div>
                                     
                                        <div className="tx-18 fw600">{Orderdetail?.trans_switch_redeem_type == "UNITS"  ?Orderdetail?.trans_switch_unit:""} {Orderdetail?.trans_switch_redeem_type}</div>
                                                <div className="tx-18 fw600">{Orderdetail?.trans_switch_redeem_type == "AMOUNT"  ?"₹"+Orderdetail?.trans_amount:""} </div>
                                    </div>
                                    <div className="odrderflist">
                                        <ul>
                                            <li ><span>Order Id:</span>{Orderdetail?.trans_order_no}
                                            {/* <i className=" fa fa-copy text-primary ms-1" onClick={()=>{copyorderidToClipboard(Orderdetail?.trans_order_no)}}></i> */}
                                            </li>
                                            {Orderdetail?.trans_status==3?<>
                                            
                                           {Orderdetail?.trans_folio_no? <li ><span>Folio: </span>{Orderdetail?.trans_folio_no}
                                           {/* <i className=" fa fa-copy text-primary ms-1" onClick={()=>{copyfolioToClipboard(Orderdetail?.trans_folio_no)}}></i> */}
                                           </li>:""}    
                                            
                                            </>:<>
                                            <li><span>Paid via:</span> {bankdetail}     {Orderdetail?.trans_bank_accno ? Hideaccountnumber(Orderdetail?.trans_bank_accno) : ""}</li>

                                            
                                            </>}
                                         

                                        </ul>
                                        {Orderdetail?.trans_status==3 ?<>
                                        
                                        
                                            <ul>
                                            <li><span>Units:</span>{Orderdetail?.trans_order_units}</li>
                                            <li><span>NAV date:</span>{Orderdetail?.trans_order_nav_date}</li>
                                         

                                        </ul>
                                        </>:""}
                                     
                                    </div>
                                    <div className="d-flex justify-content-between align-item-center">
                                        <div>
                                        {Orderdetail?.trans_type} via Bank
                                        </div>
                                       
                                        {Orderdetail?.trans_status==3? <div className="tx-14 fw600 text-success me-5">
                                            Repeat Order
                                        </div>:""}
                                       
                                      
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <div><p>Have question about this order?</p></div>
                                    <div><p className="text-success"><span><i className="fa fa-question-circle"></i></span> Get Help</p></div>
                                </div>
                              
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex justify-content-between">
                                    <p className="tx-14 fw600">Order Status</p>
                                    <p className=" tx-14 fw600 text-warning">{Orderdetail?.trans_status==1?"in process":Orderdetail?.trans_status==0?"Pending":Orderdetail?.trans_status==2?"Transaction Failed":Orderdetail?.trans_status==3?"Successful":""}<i className=" ms-1 fa fa-clock"></i></p>
                                </div>
                                <div className="orderstatusbox">
                                    <ul className="StepProgress">
                                        {Orderdetail?.trans_status==3 ?<>
                                            <li className="StepProgress-item is-done line"><strong>Payment confirmed</strong><br></br>
                                            
                                        </li>
                                        
                                        </>:<>
                                        
                                        <li className="StepProgress-item is-done line"><strong>Order placed on Goalstox</strong><br></br>
                                            {new Date(Orderdetail?.created_at).getDate() + "/" + (new Date(Orderdetail?.created_at).getMonth() + 1) + "/" + new Date(Orderdetail?.created_at).getFullYear()} , {Orderdetail?.created_at ? getIndianTime(Orderdetail?.created_at) : ""}
                                        </li>
                                        
                                        
                                        </>}
                                       
                                        {Orderdetail?.trans_status==3 ?<>
                                            <li className={`StepProgress-item is-done line`}><strong>Unit(s) allocated</strong><br></br>
                                           

                                        </li>
                                        </>:<>

                                        {Orderdetail?.trans_status!==2 ?<>
                                            <li className={Orderdetail?.trans_status ==4  ?`StepProgress-item is-cancel line`:`StepProgress-item is-done line`}><strong>Payment Initiated</strong><br></br>
                                            Expected By {new Date(Orderdetail?.created_at).getDate() + "/" + (new Date(Orderdetail?.created_at).getMonth() + 1) + "/" + new Date(Orderdetail?.created_at).getFullYear()} , {Orderdetail?.created_at ? getIndianTime(Orderdetail?.created_at) : ""}

                                        </li>
                                    
                                        </>:""}
                                        {Orderdetail?.trans_status ==4 ?<>
                                            <li className={`StepProgress-item is-cancel`}><strong>SIP Cancelled by user</strong><br></br>
                                            {new Date(Orderdetail?.trans_cancel_date).getDate() + "/" + (new Date(Orderdetail?.trans_cancel_date).getMonth() + 1) + "/" + new Date(Orderdetail?.trans_cancel_date).getFullYear()} , {Orderdetail?.trans_cancel_date ? getIndianTime(Orderdetail?.trans_cancel_date) : ""}
                                        </li>
                                        </>:""}                                       
                                        </>}
                                    </ul>

                                    {Orderdetail?.trans_status ==2 ?<>
                                        <p className="text-danger">{Orderdetail?.trans_status_text}</p>
                                    
                                    </>:<>
                                    
                                    {Orderdetail?.trans_status !==3 && Orderdetail?.trans_status!==2?<> <div>
                                        <p>Your SIP payment will be deducted from your bank account on {Orderdetail?.trans_sip_start_date}. Plesae ensure sufficent balance</p>
                                    </div></>:"" }
                                    
                                    
                                    </>}
                                    {/* {Orderdetail?.trans_status==1 && Orderdetail.trans_type == "SIP"?   
                        <button type="button" className="btn btn-primary btn-lg btn-full" onClick={cancelSIP} disabled={isLoading}>  {isLoading ? (
                                                        <img src="/img/loder01.gif" width="60px" height="11px" />
                                                      ) : (
                                                        "Cancel SIP" 
                                                      )}</button>  :""} */}
                                    
                                </div>
                            </div>
                                    
                                    
                                    </>:<>
                                    
                                    
                                    <div className="noimg">
            <img src="/img/notransactions.png"/>
            <h6>No Order Found</h6>
            <a href="/order" class="btn btn-outline-primary btn-md mt-3">Create Order</a>
        </div>
                                    
                                    
                                    
                                    
                                    </>}
                          
                        </div>
                    </div>
                </section>

             
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/order") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">Order Details</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="mobile-main">
                    <section className="sec-pad-sm">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="banklist" style={{ height: "auto" }}>
                                        <div className="banklist-header dflex align-items-center justify-content-between">




                                            <div className="fundname  invboxfund-details">
                                                <div className="tx-16 fw600">{Orderdetail?.trans_mf_name ? Orderdetail?.trans_mf_name : "Order name"}</div>
                                                <ul>
                                                    <li>
                                                        {Orderdetail?.trans_order_no}
                                                    </li>
                                                </ul>


                                            </div>



                                            <p> Amount:{Orderdetail?.trans_amount}</p>
                                            <div className="line18">
                                                <div className="tx-13 fw600">
                                                    {new Date(Orderdetail?.created_at).getDate() + "/" + (new Date(Orderdetail?.created_at).getMonth() + 1) + "/" + new Date(Orderdetail?.created_at).getFullYear()}
                                                </div>
                                                <div className="tx-12">
                                                    {Orderdetail?.created_at ? getIndianTime(Orderdetail?.created_at) : ""}

                                                </div>
                                            </div>



                                        </div>
                                        <div className="banklist-content">
                                            <ul>

                                                <li><span>Account number</span>{Orderdetail?.trans_bank_accno}</li>
                                                <li><span>IFSC Code</span>{Orderdetail?.trans_bank_ifsc}</li>
                                                <li><span>Bank Mandate</span>{Orderdetail?.trans_bank_mandate_id}</li>
                                                <li><span>Transaction Frequency</span>{Orderdetail?.trans_frequency}</li>
                                                <li><span>Installments</span>{Orderdetail?.trans_no_of_installments}</li>

                                                <li><span>Sip start</span>{Orderdetail?.trans_sip_start_date}</li>
                                                <li><span>Transaction status</span>{Orderdetail?.trans_status_text}</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>



            </MobileView>

            <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={cancelSIPMessage} show={show} onConfirm={handleOkClick}>
      </SweetAlert>
      <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={cancelSIPMessage} show={warningShow} onConfirm={handlewarningOkClick}>
      </SweetAlert>
        </>
    )
}

export default Orderdetail