import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { Parser } from 'hot-formula-parser';
import { fv, pv } from 'financial';

import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";

import { ToastContainer, toast } from "react-toastify";
const apiServices = new ApiService()
function CreateGoal2() {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [goal_type, setGoalType] = useState("");
  const [customize, setCustomize] = useState(0);
  const [cn, setcn] = useState("");
  const [q, setq] = useState(0);
  const [n3, setn3] = useState(0);
  const [r3, setr3] = useState(0);
  const [i3, seti3] = useState(0);
  const [la, setla] = useState(0);
  const [y, setY] = useState();
  const [portfolioname, setPortfolioname] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [amountInput, setAmountInput] = useState("")
  const [yearInput, setYearInput] = useState("")
  const [returnInput, setReturnInput] = useState("")
  const [pl, setpl] = useState("")
  const [adjustValue, setAdjustValue] = useState("")
  const [loanValue, setLoanValue] = useState("")
  const [serveValue, setServeValue] = useState("")
  const [pmtvalue, setpmtvalue] = useState(null)
  const [ca, setca] = useState("")
  const [ra, setra] = useState("")
  const [pr, setpr] = useState("")
  const [ar, setar] = useState("")
  const [ma, setma] = useState("")
  const [retirementTime, setRetirementAge] = useState("")
  const [realRate, setRealRate] = useState("")
  const [monthlyPension, setMonthlyPension] = useState("")
  const [corpusNeeded, setCorpusNeeded] = useState("")
  const parser = new Parser();
  useEffect(() => {
  }, [])
  const history = useNavigate()
  const onClosebox = () => {
    window.location.reload()
  }
  // function runs_needed(overs, balls, wickets, target)
  // { if (overs >= total_overs) {return target;}
  // x1 = target-target*resources[total_overs-overs][wickets]/100; if (balls == 0) {return x1;}
  // x2 = target-target*resources[total_overs-overs-1][wickets]/100; x3 = x1 + (x2-x1)*balls/6;
  // return (x3);}
  const handleChnageGoalType = (e) => {
    setGoalType(e.target.value)
    if (e.target.value == "MARRIAGE" || e.target.value == "VACATION") {
      setcn("")
      setq("")
      setn3(5)
      setr3(7)
      seti3(12)
      setla(0)
    }
    if (e.target.value == "EDUCATION" || e.target.value == "CUSTOM") {
      setcn("")
      setq("")
      setn3(5)
      setr3(10)
      seti3(12)
      setla(0)
    }
    if (e.target.value == "HOUSE") {

      setq("")
      setpl(75)
      setn3(5)
      setr3(7)
      seti3(12)
      setla(0)
    }
    if (e.target.value == "RETIREMENT") {
      setca("")
      setra("")
      setr3(6)
      seti3(12)
      setar(11)
      setpr("")
      setma(85)
      setla(0)
      // setRetirementAge(60 - 50)
    }
    if (e.target.value == "EDUCATION") {
      setAmountInput("Forget inflation for a moment, what's the cold, hard rupee amount for molding the young genius in today.s value ?")
      setYearInput("How many years until your mini-me dons the backpack and heads to his education you are planning for? Let's set the countdown.")
      setReturnInput("Estimate Returns on your investments: What's your return forecast for these investments? Remember, aiming lower means dodging the risk bullet. It's the financial limbo – how low can you go without risking your goal? Financial highway at a comfortable speed.")
      setStep(1)
    }
    else if (e.target.value == "MARRIAGE") {
      setAmountInput("No need to bust out the inflation calculators just yet! How much are we talking for the grand celebration in today's money? Your estimate, without the inflation jazz")
      setYearInput("Wedding bells ringing in the future! How many years until the big day? Your estimate, no time-traveling necessary.")
      setReturnInput("   Betting on a good return, but not going all in? What's your safe bet? Remember, we want it higher than inflation but not sky-high risky.")
      setStep(1)
    }
    else if (e.target.value == "VACATION") {
      setAmountInput("Forget about financial crystal balls for now. What's the cost of this holiday fantasy in today's cold, hard cash? No crystal ball needed, just your best guesstimate!")
      setYearInput("How many moons until your dream holiday? Let's set the countdown for the perfect getaway.")
      setReturnInput("Betting on a good return, but not going all in? What's your safe bet? Remember, we want it higher than inflation but not sky-high risky.")
      setStep(1)
    }
    else if (e.target.value == "CUSTOM") {
      setAmountInput("Let's talk numbers! How much moolah are we aiming for with this goal? No inflation worries, just the raw value today.")
      setYearInput(" Let's set the countdown clock! How many years until this goal takes its bow on your financial stage? Your estimate?")
      setReturnInput("Estimate Returns on your investments: What's your return forecast for these investments? Remember, aiming lower means dodging the risk bullet. It's the financial limbo – how low can you go without risking your goal? Financial highway at a comfortable speed.")
      setStep(1)
    }
    else if (e.target.value == "HOUSE") {
      setStep(5)
    }
    else if (e.target.value == "RETIREMENT") {
      setStep(8)
    }
  }
  console.log(step)
  const gotoStep2 = () => {
    if (cn == null || cn == '' && goal_type == "EDUCATION") {
      toast.error('Child name is required .')
      return false;
    }
    if (cn == null || cn == '' && goal_type == "MARRIAGE") {
      toast.error('Son/Daughter name is required .')
      return false;
    }
    if (cn == null || cn == '' && goal_type == "VACATION") {
      toast.error('Location is required .')
      return false;
    }
    if (cn == null || cn == '' && goal_type == "CUSTOM") {
      toast.error('Goal is required .')
      return false;
    }
    if (q == null || q == '') {
      toast.error('Money  field is required .')
      return false;
    }
    if (q < 10000) {
      toast.error("Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.")
      return false;
    }
    if (q > 100000000) {
      toast.error("Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here")
      return false;
    }
    if (n3 == null || n3 == '') {
      toast.error('Years field is required .')
      return false;
    }
    if (n3 <= 0) {
      toast.error("Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.")
      return false;
    }
    if (n3 > 30) {
      toast.error("Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!")
      return false;
    }
    setStep(2)
  }
  const gotoStep3 = () => {
    const result = (q * Math.pow(1 + n3 / 100, r3)).toFixed(2);
    setY(result)
    setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${result})`))
    if (isNaN(r3)) {
      toast("Amount is Required");
      return false;
    }
    if (r3 < 3) {
      toast.error("That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.")
      return false;
    }
    if (r3 > 22) {
      toast.error("Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations")
      return false;
    }
    if (isNaN(i3)) {
      toast.error('Rate of inflation field is required .')
      return false;
    }
    if (i3 < r3 + 2) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between ${r3 + 2} and 24 – let's keep those financial vibes positive.`);
      return false;
    }
    if (i3 > 24) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between ${r3 + 2} and 24 finding the right orbit is key to financial success`);
      return false;
    }
    setStep(3)
  }
  const gotostep4 = () => {
    if (la < 0) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between 0 and ${y} – let's keep those financial vibes positive.`)
      return false;
    }
    if (la > y) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between 0 and ${y} finding the right orbit is key to financial success.`)
      return false;
    }
    setStep(4)
  }
  const gotostep6 = () => {
    if (q == null || q == '') {
      toast.error('Money  field is required.')
      return false;
    }
    if (q < 10000) {
      toast.error("Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.")
      return false;
    }
    if (q > 100000000) {
      toast.error("Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here")
      return false;
    }
    if (pl == null || pl == '') {
      toast.error('Money  field is required.')
      return false;
    }
    if (pl < 5) {
      toast.error(" Your loan amount seems too low, why take a lon then? Try a loan percentage between 5% and 90%")
      return false;
    }
    if (pl > 90) {
      toast.error("Your loan amount is too high, most banks won't fund such high lonat percent. Try a value between 5% to 90%")
      return false;
    }
    if (n3 == null || n3 == '') {
      toast.error('Years field is required .')
      return false;
    }
    if (n3 <= 0) {
      toast.error("Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.")
      return false;
    }
    if (n3 > 30) {
      toast.error("Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!")
      return false;
    }
    setStep(6)
  }
  const gotoStep7 = () => {
    let adjustvalue = q * Math.pow(1 + r3 / 100, n3)
    setAdjustValue(adjustvalue.toFixed(2))
    let loanvalue = adjustvalue * pl / 100
    setLoanValue(loanvalue.toFixed(2))
    let servingvalue = adjustvalue - loanvalue
    setServeValue(servingvalue.toFixed(2))
    setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${servingvalue})`))
    if (isNaN(r3)) {
      toast("Amount is Required");
      return false;
    }
    if (r3 < 3) {
      toast.error("That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.")
      return false;
    }
    if (r3 > 22) {
      toast.error("Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations")
      return false;
    }
    if (isNaN(i3)) {
      toast.error('Rate of inflation field is required .')
      return false;
    }
    if (i3 < r3 + 2) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between ${r3 + 2} and 24 – let's keep those financial vibes positive.`);
      return false;
    }
    if (i3 > 24) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between ${r3 + 2} and 24 finding the right orbit is key to financial success`);
      return false;
    }
    setStep(7)
  }
  const gotostep12 = () => {
    if (la < 0) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between 0 and ${adjustValue} – let's keep those financial vibes positive.`)
      return false;
    }
    if (la > adjustValue) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between 0 and ${adjustValue} finding the right orbit is key to financial success.`)
      return false;
    }
    setStep(12)
  }
  const returnfromportfoliopage = () => {
    if (goal_type == "EDUCATION" || goal_type == "MARRIAGE" || goal_type == "VACATION" || goal_type == "CUSTOM") {
      setStep(3)
    }
    else if (goal_type == "HOUSE") {
      setStep(7)
    }
    else if (goal_type == "RETIREMENT") {
      setStep(11)
    }
  }
  const gotostep9 = () => {
    if (ca == "") {
      toast.error(`Current age is required`)
      return false;
    }
    if (ca < 5) {
      toast.error(`Oops! It seems you're just starting the adventure of counting birthdays. Retirement planning is a bit too early for now.Choose an age between 5 and 65 for our wizardry!`);
      return false;
    }
    if (ca > 65) {
      toast.error(`Looks like you've already earned the badge of retirement! The age you have chose is too high. For this particular magic trick, though, pick an age between 5 and 65.`);
      return false;
    }
    if (ra == "") {
      toast.error(`Retirement age is required`)
      return false;
    }
    if (ra < ca) {
      toast.error(`Your retirement age should be a number between ${ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!`)
      return false;
    }
    if (ra > 80) {
      toast.error(`Your retirement age should be a number between ${ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!`)
      return false;
    }
    setStep(9)
  }
  const gotoStep10 = () => {
    if (isNaN(r3)) {
      toast("Amount is Required");
      return false;
    }
    if (r3 < 3) {
      toast.error("That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.")
      return false;
    }
    if (r3 > 22) {
      toast.error("Hold your horses! That's too high. Aim for a number between 3% and 22% – the sweet spot for realistic expectations")
      return false;
    }
    if (isNaN(i3)) {
      toast.error('Rate of inflation field is required .')
      return false;
    }
    if (i3 < r3 + 2) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between ${r3 + 2} and 24 – let's keep those financial vibes positive.`);
      return false;
    }
    if (i3 > 24) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between ${r3 + 2} and 24 finding the right orbit is key to financial success`);
      return false;
    }
    setStep(10)
  }
  const gotoStep11 = () => {
    console.log("dgdfg", ma)
    console.log(ca)
    const realrate = ((1 + ar / 100) / (1 + r3 / 100)) - 1;
    setRealRate((realrate * 100).toFixed(2))
    const monthlypension = fv(r3 / 100 / 12, retirementTime * 12, 0, -pr)
    setMonthlyPension(monthlypension.toFixed(2))
    const corpusneeded = pv(realrate / 12, (ma - ra) * 12, -monthlypension, 0)
    setCorpusNeeded(corpusneeded.toFixed(2))
    const pmtValue = parser.parse(`PMT(${i3}/100/12,${retirementTime}*12,0,-${corpusneeded})`)
    setpmtvalue(pmtValue)
    if (ar == "") {
      toast.error(`Expected return after retierment is required`);
      return false;
    }
    if (ar < r3 + 2) {
      toast.error(` Oops! Your assumed return is hardly beating inflation. Shoot for a number between ${r3 + 2}and 23% – the balanced path.`)
      return false;
    }
    if (ar > 23) {
      toast.error(`Slow down there! A very high assumption brings very high risk. Opt for a number between ${r3 + 2} and 23% – finding the sweet spot is key.`)
      return false;
    }
    if (pr == "") {
      toast.error(`Pension required in current value`);
      return false;
    }
    if (pr < 5000) {
      toast.error(" That's too low – you're worth more! Aim for a pension value between Rs 5000 and 20 Lakh per month.")
      return false;
    }
    if (pr > 2000000) {
      toast.error("Whoa, big spender! Your monthly pension value is too high. Try a figure between Rs 5000 and Rs 20 lakh per month – finding the right balance is the goal.")
      return false;
    }
    if (ma == "") {
      toast.error(`Mortality Age is required`);
      return false;
    }
    if (ma < ra) {
      toast.error(`Slow down there! That mortality age is too low, infact lower than your retirement age! Consider a range between ${ra} to 95 – it's like finding the right tune for a long and happy life.`);
      return false;
    }
    if (ma > 105) {
      toast.error(`That's reaching for the stars! Assume a mortality age between ${ra} to 105 – it's like choosing a good book – not too short, not too long.`);
      return false;
    }
    setStep(11)
  }
  const gotoNextPage = () => {
    if (la < 0) {
      toast.error(` Oops, investments can't be negative! Keep it positive, between 0 and ${corpusNeeded} – let's keep those financial vibes positive.`)
      return false;
    }
    if (la > corpusNeeded) {
      toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between 0 and ${corpusNeeded} finding the right orbit is key to financial success.`)
      return false;
    }
    setStep(13)
  }
  const submitAllgoals = () => {
    console.log(retirementTime)
    if (portfolioname == "") {
      toast.error(`Please enter portfolio name`)
      return false
    }
    let numberofyear = ""
    if (goal_type == "RETIREMENT") {
      numberofyear = retirementTime
    }
    else {
      numberofyear = n3
    }
    const dataString = {
      goal_type: goal_type,
      goal_current_age: ca,
      goal_retirement_age: ra,
      goal_year_of_retirement_age: retirementTime,
      goal_expected_inflation: r3,
      goal_expected_return: i3,
      goal_return_after_retirement: ar,
      goal_pension_value: pr,
      goal_mortality_age: ma,
      goal_real_rate: realRate,
      goal_monthly_pension: monthlyPension,
      goal_corpus_needed: corpusNeeded,
      goal_lumpsum_value: la,
      goal_monthly_investment: pmtvalue.result,
      goal_name: cn,
      goal_current_amount: q,
      goal_number_of_year: numberofyear,
      goal_actual_fund: y,
      goal_percentage_of_loan: pl,
      goal_value_of_home: adjustValue,
      goal_value_of_loan: loanValue,
      goal_user_contribution: serveValue,
      goal_monthly_investment_rounded_off: Math.round(pmtvalue.result / 100) * 100

    }
    apiServices.savegoaldatapostrequest(dataString).then((res) => {
      if (res.data.status == "success") {
        navigate("/dashboard")
      }
    })
  }
  return (
    <>
          <Header />

                <BrowserView>
      <div className="auth-page-wrapper pt-50">
        <div className="auth-page-content">
          <div className="container">
            {/* <div className="backbutton-desktop" onClick={handleBackClick} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
            {/* <CustomProgressBar percent={70} /> */}
            <div className="row justify-content-center mt-3">
              <div className="col-lg-7">
                <div className="crds">
                  <div className="crds-body p-50">
                    {/* <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 5 of 7</p>

                      </div> */}
                    <div className="goalcbox">
                      {step == 1 && goal_type == "" ?
                        <div className="goalcboxClose" onClick={() => { history('/dashboard') }}><i class="icon-cross2"></i></div>
                        : <div className="goalcboxClose" onClick={() => { onClosebox() }}><i class="icon-cross2"></i></div>}
                      <div className="goalcbox-header">
                        <h4>Let’s set a goal for you</h4>
                        <p className="mb-0">Find out the future value of your Goal & your Monthly Investment to achieve it</p>
                      </div>
                      <div className="goalcbox-body">
                        {
                          step == 1 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option>Select Goal</option>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                {goal_type !== "" ? <>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      {goal_type == 'EDUCATION' ? <><label>Child Name<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">What's the adorable name of your future scholar? We're all ears for the next Ivy League prodigy in the making.</p></>
                                        : ""}
                                      {goal_type == 'MARRIAGE' ? <> <label>Son/ Daughter's Name<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">What is the name of your son/ daughter you want to start svaings for, for future wedding?</p></> : ""}
                                      {goal_type == 'VACATION' ? <> <label>Location<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">So, where's the dream destination for your holiday escapade? Ready to teleport your savings to paradise?</p></> : ""}
                                      {goal_type == 'CUSTOM' ? <> <label>What is this goal?<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">Spill the beans! What's the scoop on this new goal of yours?</p></> : ""}
                                      <input type="text" value={cn} onChange={(e) => { setcn(e.target.value) }} className="form-control" />
                                    </div>
                                  </div>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                                      <p className="inpfeed mb-0">{amountInput}</p>
                                      <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                      {q < 100000 && q > 0 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                      {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                      </p> : null}
                                    </div>
                                  </div>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                                      <p className="inpfeed  mb-0"> {yearInput}</p>
                                      <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                                      {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                      {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotoStep2}>
                                      Continue
                                    </button>
                                  </div>
                                </> : ""}
                              </div>
                            </div> : ''
                        }
                        {
                          step == 2 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Meet Inflation, the mischief maker in the world of costs. Guess high to outsmart this sneaky foe – the troublemaker in your financial story!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                          console.log("ssss")
                                        }
                                        else {
                                          seti3(12)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 22% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(1) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep3}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 3 ?
                            <div id="step3" className="">
                              <CustomProgressBar percent={75} />
                              <h3 className="text-center mt-5 mb-4">Here is what you need ...</h3>
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        console.log(i3, n3, e.target.value, y)
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                    {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">At an inflation rate of {r3} you will need Rs</th>
                                      <td>₹{y}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center mt-4">
                                  <button type="button" onClick={() => { setStep(2) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotostep4}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 4 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>

                                </div>
                                <div className="fw-bold">

                                And you need a monthly investment of Rs<span>₹{Math.round(pmtvalue.result / 100) * 100}</span>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" width="50%">How much money is required ? Rs</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                        {q < 100000 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                        {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">And in how many years from now ?</th>
                                      <td width="50%"> <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => {
                                        if (e.target.value == '' || e.target.value == 0) {
                                          setn3(1)
                                          setY(q * Math.pow(1 + 1 / 100, r3))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,1*12,0,-${la})`))
                                        } else {
                                          setn3(e.target.value)
                                          setY(q * Math.pow(1 + parseInt(e.target.value) / 100, r3))
                                          console.log(i3, parseInt(e.target.value), la)
                                          console.log(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${y})`))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${y})`))
                                        };
                                      }} className="form-control" />
                                        {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                        {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            seti3(parseInt(e.target.value) + 2)
                                            setY(q * Math.pow(1 + n3 / 100, 0))
                                          } else {
                                            seti3(parseInt(e.target.value) + 2)
                                            console.log(n3, e.target.value)
                                            console.log(q * Math.pow(1 + n3 / 100, parseInt(e.target.value)))
                                            setY(q * Math.pow(1 + n3 / 100, parseInt(e.target.value)))
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">What is your expected rate of return</th>
                                      <td> <input
                                        type="number"
                                        name="roi3"
                                        value={i3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          seti3(value);
                                        }}
                                        className="form-control"
                                      />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  <input
                                        type="number"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                          if (e.target.value == '') {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                          }
                                          else {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                          }
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">At an inflation rate of {r3} you will need Rs</th>
                                      <td>₹{y}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table table-dark">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                        {
                          step == 5 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{amountInput}</p>
                                    <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                    {q < 100000 && q > 0 ? <p className="tx-11" style={{ color: "red" }}>Whoops! I think its impossible to buy a house at such small price. Try using a va,ue between 100,000 and 10 Crs</p> : ""}
                                    {q > 100000000 ? <p className="tx-11" style={{ color: "red" }}>That amount is too high to be planned here. You should consult your advisor, or write to us at invest@goalstox.com. Or you should choose a value between 100000 to 10 Crs</p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>What % of this you will take as loan?<span className="text-danger fs-5" >*</span></label>
                                    <p className="inpfeed mb-0">Planning to take a mortgage?  Remember, higher loans mean bigger EMIs. What's your loan appetite – a nibble or a big bite?</p>
                                    <input type="number" name="moneyreq" value={pl} onChange={(e) => { setpl(e.target.value) }} className="form-control" />
                                    {pl < 5 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount seems too low, why take a lon then? Try a loan percentage between 5% and 90%   </p> : ""}
                                    {pl > 90 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount is too high, most banks won't fund such high lonat percent. Try a value between 5% to 90%</p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                                    <p className="inpfeed  mb-0"> {yearInput}</p>
                                    <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                                    {n3 < 0 ? <p className="tx-11" style={{ color: "red" }}> Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.  </p> : ""}
                                    {n3 > 30 ? <p className="tx-11" style={{ color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotostep6} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 6 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Friend or Foe? Let's talk inflation. What is guess for inflation? The higher you guess, the safer you play. It's like putting on an extra layer of financial sunscreen – better safe than sorry!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                          console.log("ssss")
                                        }
                                        else {
                                          seti3(12)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(5) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep7} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 7 ?
                            <div id="step3" className="">
                              <CustomProgressBar percent={75} />
                              <h3 className="text-center mt-5 mb-4">Here is what you need ...</h3>
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${serveValue})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${serveValue})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {adjustValue} – let's keep those financial vibes positive.</p> : ""}
                                    {la > adjustValue ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {adjustValue} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < adjustValue ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Inflation adjusted value of your home</th>
                                      <td>₹{adjustValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Value of Loan </th>
                                      <td>₹{loanValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Your contribution which you are saving for </th>
                                      <td>₹{serveValue}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center mt-4">
                                  <button type="button" onClick={() => { setStep(6) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotostep12}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 8 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Current Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Time to spill the ageless secret – What's the number of candles on your birthday cake this year?</p>
                                    <input type="number" value={ca} onChange={(e) => { setca(e.target.value); setRetirementAge(ra - e.target.value) }} className="form-control" />
                                    {ca < 5 && ca > 0 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! It seems you're just starting the adventure of counting birthdays. Retirement planning is a bit too early for now.Choose an age between 5 and 65 for our wizardry! </p> : ""}
                                    {ca > 65 ? <p style={{ fontSize: "12px", color: "red" }}>Looks like you've already earned the badge of retirement! The age you have chose is too high. For this particular magic trick, though, pick an age between 5 and 65.
                                    </p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Retierment Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">When do you want to bid farewell to the work hustle? Choose an age between {parseInt(ca) + 1} and 80 – it's like picking the winning lottery number for your retirement dreams!</p>
                                    <input type="number" name="moneyreq" value={ra} onChange={(e) => { setra(e.target.value); setRetirementAge(e.target.value - ca) }} className="form-control" />
                                    {ra < ca ? <p style={{ fontSize: "12px", color: "red" }}>Your current age should be less than retierment age</p> : ""}
                                    {ra > 80 ? <p style={{ fontSize: "12px", color: "red" }}> Your retirement age should be a number between {ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!
                                    </p> : ""}
                                  </div>
                                </div>
                                {/* {ra > ca && retirementTime > 0 ?
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>And in how many years from now ? <span className="tx-theme fw600">{retirementTime}</span></label>
                                    </div>
                                  </div> : ""} */}
                                <div className="d-flex align-items-center">
                                  <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotostep9}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 9 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Friend or Foe? Let's talk inflation. What is guess for inflation? The higher you guess, the safer you play. It's like putting on an extra layer of financial sunscreen – better safe than sorry!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                        }
                                        else {
                                          seti3(12)
                                        }
                                        if (parseInt(e.target.value) + 2 < 11 && 11 < 20) {
                                          setar(11)
                                        }
                                        else {
                                          setar(parseInt(e.target.value) + 2)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                        if (r3 + 2 < parseInt(e.target.value) - 2 && 20 > parseInt(e.target.value) - 2) {
                                          setar(parseInt(e.target.value) - 2)
                                        }
                                        else {
                                          setar(r3 + 2)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(8) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep10} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 10 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Expected return after retierment<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Post-retirement returns are like fine wine – they might age, but the taste could change. We've factored in a 2% reduction from your expected return. How much are you comfortable with?
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={ar}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setar(value);
                                      }}
                                      className="form-control"
                                    />
                                    {ar < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}> Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 23% – the balanced path.</p> : null}
                                    {ar > 23 ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 23% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Pension required in current value (monthly)<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Forget about inflation for a moment. If you were retiring today, how much monthly pension would keep you smiling? No crystal balls, just a straightforward look at your present needs. </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={pr}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setpr(value);
                                      }}
                                      className="form-control"
                                    />
                                    {pr < 5000 && pr > 0 ? <p style={{ fontSize: "12px", color: "red" }}>you're worth more! Aim for a pension value between Rs 5000 and 20 Lakh per month.</p> : null}
                                    {pr > 1000000 ? <p style={{ fontSize: "12px", color: "red" }}> Whoa, big spender! Your monthly pension value is too high. Try a figure between Rs 5000 and Rs 20 lakh per month – finding the right balance is the goal.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Expected Mortality Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> According to UN estimates, we're all striving for an average mortality age of 82 by 2100*. Choose wisely –  a higher number means you're planning for more years of financial peace. It's like betting on a longer, happier life. *Source
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={ma}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setma(value);
                                      }}
                                      className="form-control"
                                    />
                                    {ma < ra ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! That mortality age is too low, infact lower than your retirement age! Consider a range between {ra} to 95 – it's like finding the right tune for a long and happy life.</p> : null}
                                    {ma > 95 ? <p style={{ fontSize: "12px", color: "red" }}> That's reaching for the stars! Assume a mortality age between {ra} to 95 – it's like choosing a good book – not too short, not too long.</p> : null}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(9) }} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep11} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 11 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${retirementTime}*12,0,-${corpusNeeded})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${retirementTime}*12,${parseInt(e.target.value)},-${corpusNeeded})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {corpusNeeded} – let's keep those financial vibes positive.</p> : ""}
                                    {la > corpusNeeded ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {corpusNeeded} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" >Real rate of return</th>
                                      <td>{realRate}%</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Monthly pension at retierment </th>
                                      <td>₹{monthlyPension}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Corpus needed</th>
                                      <td>₹{corpusNeeded}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(10) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoNextPage}  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 12 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>
                                </div>
                                <div className="fw-bold">And you need a monthly investment of Rs <span>₹{Math.round(pmtvalue.result / 100) * 100}</span></div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr >
                                      <th scope="row" width="50%">How much money is required ? Rs</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                        {q < 100000 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                        {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">What % of this you will take as loan?</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={pl}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setpl(value);
                                            if (e.target.value == '' || e.target.value == 0) {
                                              setLoanValue(adjustValue * 0)
                                              setServeValue(adjustValue - loanValue)
                                            } else {
                                              console.log(adjustValue, e.target.value)
                                              setLoanValue(adjustValue * parseInt(e.target.value) / 100)
                                              setServeValue(adjustValue - adjustValue * parseInt(e.target.value) / 100)
                                            }
                                          }}
                                          className="form-control" />
                                      </td>
                                      {pl < 5 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount seems too low, why take a lon then? Try a loan percentage between 5% and 90%   </p> : ""}
                                      {pl > 90 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount is too high, most banks won't fund such high lonat percent. Try a value between 5% to 90%</p> : ""}
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">And in how many years from now ?</th>
                                      <td width="50%"> <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => {
                                        if (e.target.value == '' || e.target.value == 0) {
                                          setn3(1)
                                          setAdjustValue(q * Math.pow(1 + r3 / 100, 0))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,1*12,0,-${serveValue})`))
                                        } else {
                                          setn3(e.target.value)
                                          setAdjustValue(q * Math.pow(1 + r3 / 100, parseInt(e.target.value)))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${serveValue})`))
                                        };
                                      }} className="form-control" />
                                        {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                        {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            seti3(parseInt(e.target.value) + 2)
                                            setAdjustValue(q * Math.pow(1 + 0, n3))
                                          } else {
                                            seti3(parseInt(e.target.value) + 2)
                                            setAdjustValue(q * Math.pow(1 + parseInt(e.target.value) / 100, n3))
                                          }
                                          if (parseInt(e.target.value) < 22) {
                                            seti3(parseInt(e.target.value) + 2)
                                          }
                                          else {
                                            seti3(12)
                                          }
                                          if (parseInt(e.target.value) < 22) {
                                            setar(parseInt(e.target.value) + 2)
                                          }
                                          else {
                                            setar(11)
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">What is your expected rate of return</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={i3}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            seti3(value);
                                          }}
                                          className="form-control"
                                        />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  <input
                                        type="number" width="50%"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                          if (e.target.value == '') {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                          }
                                          else {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                          }
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Inflation adjusted value of your home</th>
                                      <td>₹{adjustValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Value of Loan </th>
                                      <td>₹{loanValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Your contribution which you are saving for </th>
                                      <td>₹{serveValue}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table table-dark">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                        {
                          step == 13 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>
                                </div>
                                <div className="fw-bold">
                                And you need a monthly investment of Rs <span>₹{Math.round(pmtvalue.result / 100) * 100}</span>

                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" width="50%">Current Age</th>
                                      <td width="50%">
                                        <input type="number" value={ca} onChange={(e) => { setca(e.target.value); setRetirementAge(ra - e.target.value) }} className="form-control" />
                                        {ca < 5 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! It seems you're just starting the adventure of counting birthdays. Retirement planning is a bit too early for now.Choose an age between 5 and 65 for our wizardry! </p> : ""}
                                        {ca > 65 ? <p style={{ fontSize: "12px", color: "red" }}>Looks like you've already earned the badge of retirement! The age you have chose is too high. For this particular magic trick, though, pick an age between 5 and 65.
                                        </p> : ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Retierment Age</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={ra} onChange={(e) => {
                                          setra(e.target.value); setRetirementAge(e.target.value - ca)
                                          if (e.target.value == "" && e.target.value == 0) {

                                            setMonthlyPension(fv(0 / 12, retirementTime * 12, 0, -pr))
                                          }
                                          else {
                                            console.log("servevalue", realRate / 12, (ma - parseInt(e.target.value)) * 12, -monthlyPension, 0)
                                            setCorpusNeeded(pv(realRate / 12, (ma - parseInt(e.target.value)) * 12, -monthlyPension, 0))
                                          }
                                        }} className="form-control" />
                                        {ra < ca ? <p style={{ fontSize: "12px", color: "red" }}>Your current age should be less than retierment age</p> : ""}
                                        {ra > 80 ? <p style={{ fontSize: "12px", color: "red" }}> Your retirement age should be a number between {ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!
                                        </p> : ""}
                                      </td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            setRealRate(((1 + ar / 100) / (1 + 0)) - 1)
                                            setMonthlyPension(fv(0 / 12, retirementTime * 12, 0, -pr))
                                          } else {
                                            setRealRate(((1 + ar / 100) / (1 + parseInt(e.target.value) / 100)) - 1)
                                            setMonthlyPension(fv(parseInt(e.target.value) / 100 / 12, retirementTime * 12, 0, -pr))
                                          }
                                          if (r3 + 2 < parseInt(e.target.value) - 2 && 20 > parseInt(e.target.value) - 2) {
                                            setar(parseInt(e.target.value) - 2)
                                          }
                                          else {
                                            setar(r3 + 2)
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">What is your expected rate of return</th>
                                      <td>
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={i3}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            seti3(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setpmtvalue("")
                                            }
                                            else {
                                              setpmtvalue(parser.parse(`PMT(${parseInt(e.target.value)}/100/12,${retirementTime}*12,0,-${corpusNeeded})`))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="50%" scope="row">Expected return after retierment</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="ror3"
                                          value={ar}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setar(value);
                                            if (e.target.value == '' || e.target.value == 0) {
                                              setRealRate(((1 + 0) / (1 + r3 / 100)) - 1)
                                            } else {
                                              let realrate = (((1 + parseInt(e.target.value) / 100) / (1 + r3 / 100)) - 1)
                                              setRealRate(realrate * 100)
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {ar < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}> Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 23% – the balanced path.</p> : null}
                                        {ar > 23 ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 23% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Pension required in current value (monthly)</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={pr}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setpr(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setMonthlyPension(fv(r3 / 100 / 12, retirementTime * 12, 0, -0))
                                            }
                                            else {
                                              setMonthlyPension(fv(r3 / 100 / 12, retirementTime * 12, 0, -parseInt(e.target.value)))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {pr < 5000 ? <p style={{ fontSize: "12px", color: "red" }}>you're worth more! Aim for a pension value between Rs 5000 and 20 Lakh per month.</p> : null}
                                        {pr > 1000000 ? <p style={{ fontSize: "12px", color: "red" }}> Whoa, big spender! Your monthly pension value is too high. Try a figure between Rs 5000 and Rs 20 lakh per month – finding the right balance is the goal.</p> : null}
                                      </td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">Expected Mortality Age</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="ror3"
                                          value={ma}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setma(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setCorpusNeeded(pv(realRate / 12, (0 - ra) * 12, -monthlyPension, 0))
                                            }
                                            else {
                                              setCorpusNeeded(pv(realRate / 12, (parseInt(e.target.value) - ra) * 12, -monthlyPension, 0))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {ma < ra ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! That mortality age is too low, infact lower than your retirement age! Consider a range between {ra} to 95 – it's like finding the right tune for a long and happy life.</p> : null}
                                        {ma > 95 ? <p style={{ fontSize: "12px", color: "red" }}> That's reaching for the stars! Assume a mortality age between {ra} to 95 – it's like choosing a good book – not too short, not too long.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  <input
                                        type="number"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">And in how many years from now ?</th>
                                      <td>{retirementTime}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Real rate of return</th>
                                      <td>{realRate}%</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Monthly pension at retierment</th>
                                      <td>₹{monthlyPension}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Corpus needed </th>
                                      <td>₹{corpusNeeded}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table ">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </BrowserView>
      <MobileView>
      <div className="auth-page-wrapper pt-50">
        <div className="auth-page-content">
          <div className="container">
            {/* <div className="backbutton-desktop" onClick={handleBackClick} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
            {/* <CustomProgressBar percent={70} /> */}
            <div className="row justify-content-center mt-3">
              <div className="col-lg-7">
                <div className="crds">
                  <div className="crds-body">
                    {/* <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 5 of 7</p>

                      </div> */}
                    <div className="goalcbox">
                      {step == 1 && goal_type == "" ?
                        <div className="goalcboxClose" onClick={() => { history('/dashboard') }}><i class="icon-cross2"></i></div>
                        : <div className="goalcboxClose" onClick={() => { onClosebox() }}><i class="icon-cross2"></i></div>}
                      <div className="goalcbox-header">
                        <h4>Let’s set a goal for you</h4>
                        <p className="mb-0">Find out the future value of your Goal & your Monthly Investment to achieve it</p>
                      </div>
                      <div className="goalcbox-body">
                        {
                          step == 1 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option>Select Goal</option>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                {goal_type !== "" ? <>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      {goal_type == 'EDUCATION' ? <><label>Child Name<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">What's the adorable name of your future scholar? We're all ears for the next Ivy League prodigy in the making.</p></>
                                        : ""}
                                      {goal_type == 'MARRIAGE' ? <> <label>Son/ Daughter's Name<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">What is the name of your son/ daughter you want to start svaings for, for future wedding?</p></> : ""}
                                      {goal_type == 'VACATION' ? <> <label>Location<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">So, where's the dream destination for your holiday escapade? Ready to teleport your savings to paradise?</p></> : ""}
                                      {goal_type == 'CUSTOM' ? <> <label>What is this goal?<span className="text-danger fs-5">*</span></label>
                                        <p className="inpfeed mb-0">Spill the beans! What's the scoop on this new goal of yours?</p></> : ""}
                                      <input type="text" value={cn} onChange={(e) => { setcn(e.target.value) }} className="form-control" />
                                    </div>
                                  </div>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                                      <p className="inpfeed mb-0">{amountInput}</p>
                                      <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                      {q < 100000 && q > 0 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                      {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                      </p> : null}
                                    </div>
                                  </div>
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                                      <p className="inpfeed  mb-0"> {yearInput}</p>
                                      <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                                      {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                      {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotoStep2}>
                                      Continue
                                    </button>
                                  </div>
                                </> : ""}
                              </div>
                            </div> : ''
                        }
                        {
                          step == 2 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Meet Inflation, the mischief maker in the world of costs. Guess high to outsmart this sneaky foe – the troublemaker in your financial story!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                          console.log("ssss")
                                        }
                                        else {
                                          seti3(12)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 22% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(1) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep3}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 3 ?
                            <div id="step3" className="">
                              <CustomProgressBar percent={75} />
                              <h3 className="text-center mt-5 mb-4">Here is what you need ...</h3>
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        console.log(i3, n3, e.target.value, y)
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                    {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">At an inflation rate of {r3} you will need Rs</th>
                                      <td>₹{y}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center mt-4">
                                  <button type="button" onClick={() => { setStep(2) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotostep4}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 4 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>

                                </div>
                                <div className="fw-bold">

                                And you need a monthly investment of Rs<span>₹{Math.round(pmtvalue.result / 100) * 100}</span>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" width="50%">How much money is required ? Rs</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                        {q < 100000 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                        {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">And in how many years from now ?</th>
                                      <td width="50%"> <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => {
                                        if (e.target.value == '' || e.target.value == 0) {
                                          setn3(1)
                                          setY(q * Math.pow(1 + 1 / 100, r3))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,1*12,0,-${la})`))
                                        } else {
                                          setn3(e.target.value)
                                          setY(q * Math.pow(1 + parseInt(e.target.value) / 100, r3))
                                          console.log(i3, parseInt(e.target.value), la)
                                          console.log(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${y})`))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${y})`))
                                        };
                                      }} className="form-control" />
                                        {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                        {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            seti3(parseInt(e.target.value) + 2)
                                            setY(q * Math.pow(1 + n3 / 100, 0))
                                          } else {
                                            seti3(parseInt(e.target.value) + 2)
                                            console.log(n3, e.target.value)
                                            console.log(q * Math.pow(1 + n3 / 100, parseInt(e.target.value)))
                                            setY(q * Math.pow(1 + n3 / 100, parseInt(e.target.value)))
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">What is your expected rate of return</th>
                                      <td> <input
                                        type="number"
                                        name="roi3"
                                        value={i3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          seti3(value);
                                        }}
                                        className="form-control"
                                      />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  <input
                                        type="number"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                          if (e.target.value == '') {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                          }
                                          else {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                          }
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">At an inflation rate of {r3} you will need Rs</th>
                                      <td>₹{y}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table table-dark">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                        {
                          step == 5 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{amountInput}</p>
                                    <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                    {q < 100000 && q > 0 ? <p className="tx-11" style={{ color: "red" }}>Whoops! I think its impossible to buy a house at such small price. Try using a va,ue between 100,000 and 10 Crs</p> : ""}
                                    {q > 100000000 ? <p className="tx-11" style={{ color: "red" }}>That amount is too high to be planned here. You should consult your advisor, or write to us at invest@goalstox.com. Or you should choose a value between 100000 to 10 Crs</p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>What % of this you will take as loan?<span className="text-danger fs-5" >*</span></label>
                                    <p className="inpfeed mb-0">Planning to take a mortgage?  Remember, higher loans mean bigger EMIs. What's your loan appetite – a nibble or a big bite?</p>
                                    <input type="number" name="moneyreq" value={pl} onChange={(e) => { setpl(e.target.value) }} className="form-control" />
                                    {pl < 5 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount seems too low, why take a lon then? Try a loan percentage between 5% and 90%   </p> : ""}
                                    {pl > 90 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount is too high, most banks won't fund such high lonat percent. Try a value between 5% to 90%</p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                                    <p className="inpfeed  mb-0"> {yearInput}</p>
                                    <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                                    {n3 < 0 ? <p className="tx-11" style={{ color: "red" }}> Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.  </p> : ""}
                                    {n3 > 30 ? <p className="tx-11" style={{ color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotostep6} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 6 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Friend or Foe? Let's talk inflation. What is guess for inflation? The higher you guess, the safer you play. It's like putting on an extra layer of financial sunscreen – better safe than sorry!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                          console.log("ssss")
                                        }
                                        else {
                                          seti3(12)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(5) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep7} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 7 ?
                            <div id="step3" className="">
                              <CustomProgressBar percent={75} />
                              <h3 className="text-center mt-5 mb-4">Here is what you need ...</h3>
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${serveValue})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${serveValue})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {adjustValue} – let's keep those financial vibes positive.</p> : ""}
                                    {la > adjustValue ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {adjustValue} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < adjustValue ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Inflation adjusted value of your home</th>
                                      <td>₹{adjustValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Value of Loan </th>
                                      <td>₹{loanValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Your contribution which you are saving for </th>
                                      <td>₹{serveValue}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center mt-4">
                                  <button type="button" onClick={() => { setStep(6) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotostep12}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 8 ?
                            <div id="step1" className="">
                              <CustomProgressBar percent={25} />
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                    <select name="goal_type" className="custom-select" defaultValue={goal_type} onChange={handleChnageGoalType}>
                                      <option value="RETIREMENT">Retierment Goal / All other goals where you invest regularly and then receive payments also regularly</option>
                                      <option value="EDUCATION">Child's Education Goal</option>
                                      <option value="MARRIAGE">Child's wedding Goal</option>
                                      <option value="VACATION">Vacation Goal</option>
                                      <option value="HOUSE">Buying your dream home on EMIs</option>
                                      <option value="CUSTOM">Custom Goal</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Current Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Time to spill the ageless secret – What's the number of candles on your birthday cake this year?</p>
                                    <input type="number" value={ca} onChange={(e) => { setca(e.target.value); setRetirementAge(ra - e.target.value) }} className="form-control" />
                                    {ca < 5 && ca > 0 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! It seems you're just starting the adventure of counting birthdays. Retirement planning is a bit too early for now.Choose an age between 5 and 65 for our wizardry! </p> : ""}
                                    {ca > 65 ? <p style={{ fontSize: "12px", color: "red" }}>Looks like you've already earned the badge of retirement! The age you have chose is too high. For this particular magic trick, though, pick an age between 5 and 65.
                                    </p> : ""}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Retierment Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">When do you want to bid farewell to the work hustle? Choose an age between {parseInt(ca) + 1} and 80 – it's like picking the winning lottery number for your retirement dreams!</p>
                                    <input type="number" name="moneyreq" value={ra} onChange={(e) => { setra(e.target.value); setRetirementAge(e.target.value - ca) }} className="form-control" />
                                    {ra < ca ? <p style={{ fontSize: "12px", color: "red" }}>Your current age should be less than retierment age</p> : ""}
                                    {ra > 80 ? <p style={{ fontSize: "12px", color: "red" }}> Your retirement age should be a number between {ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!
                                    </p> : ""}
                                  </div>
                                </div>
                                {/* {ra > ca && retirementTime > 0 ?
                                  <div className='col-lg-12'>
                                    <div className="gform-box mg-b-20">
                                      <label>And in how many years from now ? <span className="tx-theme fw600">{retirementTime}</span></label>
                                    </div>
                                  </div> : ""} */}
                                <div className="d-flex align-items-center">
                                  <button className="btn btn-outline-primary btn-lg btn-full mt-3 " onClick={gotostep9}>
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 9 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> Friend or Foe? Let's talk inflation. What is guess for inflation? The higher you guess, the safer you play. It's like putting on an extra layer of financial sunscreen – better safe than sorry!
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={r3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setr3(value);
                                        if (parseInt(e.target.value) < 22) {
                                          seti3(parseInt(e.target.value) + 2)
                                        }
                                        else {
                                          seti3(12)
                                        }
                                        if (parseInt(e.target.value) + 2 < 11 && 11 < 20) {
                                          setar(11)
                                        }
                                        else {
                                          setar(parseInt(e.target.value) + 2)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                    {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                    </p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">{returnInput}
                                    </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={i3}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        seti3(value);
                                        if (r3 + 2 < parseInt(e.target.value) - 2 && 20 > parseInt(e.target.value) - 2) {
                                          setar(parseInt(e.target.value) - 2)
                                        }
                                        else {
                                          setar(r3 + 2)
                                        }
                                      }}
                                      className="form-control"
                                    />
                                    {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                    {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 24% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(8) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep10} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 10 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Expected return after retierment<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Post-retirement returns are like fine wine – they might age, but the taste could change. We've factored in a 2% reduction from your expected return. How much are you comfortable with?
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={ar}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setar(value);
                                      }}
                                      className="form-control"
                                    />
                                    {ar < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}> Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 23% – the balanced path.</p> : null}
                                    {ar > 23 ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 23% – finding the sweet spot is key.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Pension required in current value (monthly)<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0">Forget about inflation for a moment. If you were retiring today, how much monthly pension would keep you smiling? No crystal balls, just a straightforward look at your present needs. </p>
                                    <input
                                      type="number"
                                      name="roi3"
                                      value={pr}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setpr(value);
                                      }}
                                      className="form-control"
                                    />
                                    {pr < 5000 && pr > 0 ? <p style={{ fontSize: "12px", color: "red" }}>you're worth more! Aim for a pension value between Rs 5000 and 20 Lakh per month.</p> : null}
                                    {pr > 1000000 ? <p style={{ fontSize: "12px", color: "red" }}> Whoa, big spender! Your monthly pension value is too high. Try a figure between Rs 5000 and Rs 20 lakh per month – finding the right balance is the goal.</p> : null}
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Expected Mortality Age<span className="text-danger fs-5">*</span></label>
                                    <p className="inpfeed mb-0"> According to UN estimates, we're all striving for an average mortality age of 82 by 2100*. Choose wisely –  a higher number means you're planning for more years of financial peace. It's like betting on a longer, happier life. *Source
                                    </p>
                                    <input
                                      type="number"
                                      name="ror3"
                                      value={ma}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setma(value);
                                      }}
                                      className="form-control"
                                    />
                                    {ma < ra ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! That mortality age is too low, infact lower than your retirement age! Consider a range between {ra} to 95 – it's like finding the right tune for a long and happy life.</p> : null}
                                    {ma > 95 ? <p style={{ fontSize: "12px", color: "red" }}> That's reaching for the stars! Assume a mortality age between {ra} to 95 – it's like choosing a good book – not too short, not too long.</p> : null}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(9) }} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoStep11} >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 11 ?
                            <div id="step2" className="">
                              <CustomProgressBar percent={50} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                              <div className="row g-3 mt-3">
                                <div className='col-lg-12'>
                                  <div className="form-group mb-1">
                                    <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
                                    <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={la}
                                      onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setla(value);
                                        if (e.target.value == '') {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${retirementTime}*12,0,-${corpusNeeded})`))
                                        }
                                        else {
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${retirementTime}*12,${parseInt(e.target.value)},-${corpusNeeded})`))
                                        }
                                      }}
                                    />
                                    {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {corpusNeeded} – let's keep those financial vibes positive.</p> : ""}
                                    {la > corpusNeeded ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {corpusNeeded} finding the right orbit is key to financial success.</p> : ""}
                                    {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                  </div>
                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" >Real rate of return</th>
                                      <td>{realRate}%</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Monthly pension at retierment </th>
                                      <td>₹{monthlyPension}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Corpus needed</th>
                                      <td>₹{corpusNeeded}</td>
                                    </tr>
                                    {pmtvalue.result > 0 ?
                                      <tr>
                                        <th scope="row">And you need a monthly investment of Rs</th>
                                        <td>₹{pmtvalue.result.toFixed(2)}</td>
                                      </tr> : ""}
                                  </tbody>
                                </table>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(10) }} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-outline-primary btn-lg btn-full ms-3 " onClick={gotoNextPage}  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div> : ''
                        }
                        {
                          step == 12 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>
                                </div>
                                <div className="fw-bold">And you need a monthly investment of Rs <span>₹{Math.round(pmtvalue.result / 100) * 100}</span></div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr >
                                      <th scope="row" width="50%">How much money is required ? Rs</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                                        {q < 100000 ? <p style={{ fontSize: "12px", color: "red" }}>Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.</p> : null}
                                        {q > 100000000 ? <p style={{ fontSize: "12px", color: "red" }}>Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">What % of this you will take as loan?</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={pl}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setpl(value);
                                            if (e.target.value == '' || e.target.value == 0) {
                                              setLoanValue(adjustValue * 0)
                                              setServeValue(adjustValue - loanValue)
                                            } else {
                                              console.log(adjustValue, e.target.value)
                                              setLoanValue(adjustValue * parseInt(e.target.value) / 100)
                                              setServeValue(adjustValue - adjustValue * parseInt(e.target.value) / 100)
                                            }
                                          }}
                                          className="form-control" />
                                      </td>
                                      {pl < 5 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount seems too low, why take a lon then? Try a loan percentage between 5% and 90%   </p> : ""}
                                      {pl > 90 ? <p className="tx-11" style={{ color: "red" }}> Your loan amount is too high, most banks won't fund such high lonat percent. Try a value between 5% to 90%</p> : ""}
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">And in how many years from now ?</th>
                                      <td width="50%"> <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => {
                                        if (e.target.value == '' || e.target.value == 0) {
                                          setn3(1)
                                          setAdjustValue(q * Math.pow(1 + r3 / 100, 0))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,1*12,0,-${serveValue})`))
                                        } else {
                                          setn3(e.target.value)
                                          setAdjustValue(q * Math.pow(1 + r3 / 100, parseInt(e.target.value)))
                                          setpmtvalue(parser.parse(`PMT(${i3}/100/12,${parseInt(e.target.value)}*12,0,-${serveValue})`))
                                        };
                                      }} className="form-control" />
                                        {n3 < 1 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.</p> : null}
                                        {n3 > 30 ? <p style={{ fontSize: "12px", color: "red" }}>Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!</p> : ""}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            seti3(parseInt(e.target.value) + 2)
                                            setAdjustValue(q * Math.pow(1 + 0, n3))
                                          } else {
                                            seti3(parseInt(e.target.value) + 2)
                                            setAdjustValue(q * Math.pow(1 + parseInt(e.target.value) / 100, n3))
                                          }
                                          if (parseInt(e.target.value) < 22) {
                                            seti3(parseInt(e.target.value) + 2)
                                          }
                                          else {
                                            seti3(12)
                                          }
                                          if (parseInt(e.target.value) < 22) {
                                            setar(parseInt(e.target.value) + 2)
                                          }
                                          else {
                                            setar(11)
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">What is your expected rate of return</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={i3}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            seti3(value);
                                          }}
                                          className="form-control"
                                        />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  <input
                                        type="number" width="50%"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                          if (e.target.value == '') {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,0,-${y})`))
                                          }
                                          else {
                                            setpmtvalue(parser.parse(`PMT(${i3}/100/12,${n3}*12,${parseInt(e.target.value)},-${y})`))
                                          }
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Inflation adjusted value of your home</th>
                                      <td>₹{adjustValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Value of Loan </th>
                                      <td>₹{loanValue}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Your contribution which you are saving for </th>
                                      <td>₹{serveValue}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table table-dark">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                        {
                          step == 13 ?
                            <div id="step16" className="">
                              <CustomProgressBar percent={100} />
                              {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                              <div className='row g-3 mt-3'>
                                <div className='col-lg-12'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                    <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>
                                </div>
                                <div className="fw-bold">
                                And you need a monthly investment of Rs <span>₹{Math.round(pmtvalue.result / 100) * 100}</span>

                                </div>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row" width="50%">Current Age</th>
                                      <td width="50%">
                                        <input type="number" value={ca} onChange={(e) => { setca(e.target.value); setRetirementAge(ra - e.target.value) }} className="form-control" />
                                        {ca < 5 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! It seems you're just starting the adventure of counting birthdays. Retirement planning is a bit too early for now.Choose an age between 5 and 65 for our wizardry! </p> : ""}
                                        {ca > 65 ? <p style={{ fontSize: "12px", color: "red" }}>Looks like you've already earned the badge of retirement! The age you have chose is too high. For this particular magic trick, though, pick an age between 5 and 65.
                                        </p> : ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Retierment Age</th>
                                      <td width="50%">
                                        <input type="number" name="moneyreq" value={ra} onChange={(e) => {
                                          setra(e.target.value); setRetirementAge(e.target.value - ca)
                                          if (e.target.value == "" && e.target.value == 0) {

                                            setMonthlyPension(fv(0 / 12, retirementTime * 12, 0, -pr))
                                          }
                                          else {
                                            console.log("servevalue", realRate / 12, (ma - parseInt(e.target.value)) * 12, -monthlyPension, 0)
                                            setCorpusNeeded(pv(realRate / 12, (ma - parseInt(e.target.value)) * 12, -monthlyPension, 0))
                                          }
                                        }} className="form-control" />
                                        {ra < ca ? <p style={{ fontSize: "12px", color: "red" }}>Your current age should be less than retierment age</p> : ""}
                                        {ra > 80 ? <p style={{ fontSize: "12px", color: "red" }}> Your retirement age should be a number between {ca} and 80. After all, age is just a number, but for retirement planning, it's a crucial one!
                                        </p> : ""}
                                      </td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">What is your expected rate of inflation</th>
                                      <td width="50%">  <input
                                        type="number"
                                        name="ror3"
                                        value={r3}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setr3(value);
                                          if (e.target.value == '' || e.target.value == 0) {
                                            // setr3(0);
                                            setRealRate(((1 + ar / 100) / (1 + 0)) - 1)
                                            setMonthlyPension(fv(0 / 12, retirementTime * 12, 0, -pr))
                                          } else {
                                            setRealRate(((1 + ar / 100) / (1 + parseInt(e.target.value) / 100)) - 1)
                                            setMonthlyPension(fv(parseInt(e.target.value) / 100 / 12, retirementTime * 12, 0, -pr))
                                          }
                                          if (r3 + 2 < parseInt(e.target.value) - 2 && 20 > parseInt(e.target.value) - 2) {
                                            setar(parseInt(e.target.value) - 2)
                                          }
                                          else {
                                            setar(r3 + 2)
                                          }
                                        }}
                                        className="form-control"
                                      />
                                        {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
                                        {r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
                                        </p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">What is your expected rate of return</th>
                                      <td>
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={i3}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            seti3(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setpmtvalue("")
                                            }
                                            else {
                                              setpmtvalue(parser.parse(`PMT(${parseInt(e.target.value)}/100/12,${retirementTime}*12,0,-${corpusNeeded})`))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {r3 + 2 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Invalid rate of inflation</p> : null}
                                        {i3 < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 24% – the balanced path.</p> : null}
                                        {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} to 24% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="50%" scope="row">Expected return after retierment</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="ror3"
                                          value={ar}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setar(value);
                                            if (e.target.value == '' || e.target.value == 0) {
                                              setRealRate(((1 + 0) / (1 + r3 / 100)) - 1)
                                            } else {
                                              let realrate = (((1 + parseInt(e.target.value) / 100) / (1 + r3 / 100)) - 1)
                                              setRealRate(realrate * 100)
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {ar < r3 + 2 ? <p style={{ fontSize: "12px", color: "red" }}> Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3 + 2} and 23% – the balanced path.</p> : null}
                                        {ar > 23 ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! A very high assumption brings very high risk. Opt for a number between {r3 + 2} and 23% – finding the sweet spot is key.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" width="50%">Pension required in current value (monthly)</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="roi3"
                                          value={pr}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setpr(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setMonthlyPension(fv(r3 / 100 / 12, retirementTime * 12, 0, -0))
                                            }
                                            else {
                                              setMonthlyPension(fv(r3 / 100 / 12, retirementTime * 12, 0, -parseInt(e.target.value)))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {pr < 5000 ? <p style={{ fontSize: "12px", color: "red" }}>you're worth more! Aim for a pension value between Rs 5000 and 20 Lakh per month.</p> : null}
                                        {pr > 1000000 ? <p style={{ fontSize: "12px", color: "red" }}> Whoa, big spender! Your monthly pension value is too high. Try a figure between Rs 5000 and Rs 20 lakh per month – finding the right balance is the goal.</p> : null}
                                      </td>
                                    </tr>
                                    <tr >
                                      <th scope="row" width="50%">Expected Mortality Age</th>
                                      <td width="50%">
                                        <input
                                          type="number"
                                          name="ror3"
                                          value={ma}
                                          onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setma(value);
                                            if (e.target.value == "" && e.target.value == 0) {
                                              setCorpusNeeded(pv(realRate / 12, (0 - ra) * 12, -monthlyPension, 0))
                                            }
                                            else {
                                              setCorpusNeeded(pv(realRate / 12, (parseInt(e.target.value) - ra) * 12, -monthlyPension, 0))
                                            }
                                          }}
                                          className="form-control"
                                        />
                                        {ma < ra ? <p style={{ fontSize: "12px", color: "red" }}> Slow down there! That mortality age is too low, infact lower than your retirement age! Consider a range between {ra} to 95 – it's like finding the right tune for a long and happy life.</p> : null}
                                        {ma > 95 ? <p style={{ fontSize: "12px", color: "red" }}> That's reaching for the stars! Assume a mortality age between {ra} to 95 – it's like choosing a good book – not too short, not too long.</p> : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Any Lumpsum amount you want to invest today for this goal</th>
                                      <td>  
                                        <input
                                        type="number"
                                        className="form-control"
                                        value={la}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          setla(value);
                                        }}
                                      />
                                        {la < 0 ? <p className="tx-11" style={{ color: "red" }}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p> : ""}
                                        {la > y ? <p className="tx-11" style={{ color: "red" }}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p> : ""}
                                        {pmtvalue.result < 0 && la < y ? <p className="tx-11" style={{ color: "red" }}>Lumpsum amount is invalid</p> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th scope="row">And in how many years from now ?</th>
                                      <td>{retirementTime}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Real rate of return</th>
                                      <td>{realRate}%</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Monthly pension at retierment</th>
                                      <td>₹{monthlyPension}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Corpus needed </th>
                                      <td>₹{corpusNeeded}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <table class="table ">
                                  <thead>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">And you need a monthly investment of Rs</th>
                                      <td>₹{Math.round(pmtvalue.result / 100) * 100} </td>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </div>
                              <div className="d-flex align-items-center mt-4">
                                <button type="button" onClick={returnfromportfoliopage} className="btn btn-outline-primary  btn-full btn-lg button01">Previous</button>
                                <button className="btn btn-outline-primary btn-lg btn-full ms-3 " disabled={isLoading} onClick={submitAllgoals}>
                                  {isLoading ? (
                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                              {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}
                            </div> : ''
                        }
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </MobileView>
      <Footer />

    </>
  )
}
export default CreateGoal2


{/*  */ }