import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';




let apiServices = new ApiService();
function Home() {
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [showPassword, setShowPassword] = useState("password")
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
            }
            apiServices.bsegetuserdataPostRequest(dataString).then(res => {
                if (res.data.status == 'success') {
                    if (res.data.data.gs_status == 0) {
                        setIsLoading(false)
                        navigate("/completeprofile");
                    }
                    else if (res.data.data.gs_bse_onboard_status == 0) {
                        setIsLoading(false)
                        window.location.href = "/bseonboarding/" + res.data.data.gs_bse_onboard_step
                    }
                }
            })
          
    }
        didMountRef.current = false;
    })
    const loginprocess = (e) => {
        e.preventDefault();
        if (userEmail == '' || userPassword == '') {
            toast("Please Enter Email Or Password");
            return false;
        } else {
            setErrorMessage('');
        }
        setIsLoading(true)
        const dataString = {
            "user_email": userEmail,
            "user_password": userPassword
        }
        apiServices.bselogincheckPostRequest(dataString).then(res => {
            console.log("response", res)
            if (res.data.status == 'success') {
                localStorage.setItem('AUTH_TOKEN', res.data.auth_token);
                if (res.data.data.gs_status == 0) {
                    setIsLoading(false)
                    navigate("/completeprofile");
                }
                else if (res.data.data.gs_bse_onboard_status == 0) {
                    setIsLoading(false)
                    console.log("atob", res.data.data.gs_bse_onboard_step)
                    window.location.href = "/bseonboarding/" + res.data.data.gs_bse_onboard_step
                }
                else if (res.data.data.gs_bse_onboard_status == 1) {
                    setIsLoading(false)
                    console.log("atob", res.data.data.gs_bse_onboard_step)
                    window.location.href = "/bseaufform";
                }
                else if (res.data.data.gs_bse_onboard_status == 2) {
                    setIsLoading(false)
                    console.log("atob", res.data.data.gs_bse_onboard_step)
                    window.location.href = "/dashboard";
                }
            }
            else {
                setIsLoading(false)
                toast(res.data.message);
                return false;
            }
        })
            .catch(error => {
            })
    };
    const handleClickShowPassword = () => {
        if (showPassword == "password") {
            setShowPassword("text")
        }
        else {
            setShowPassword("password")
        }
    };
    const gotoforgetpassword = () => {
        navigate("/forgetpassword")
    }
    return (
        <>
            <BrowserView>
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="crds">
                                        <div className="crds-body p-50">
                                            <div className="text-center mb-30">
                                                <img src="/img/logo.png" className="wd-150 mb-30"></img>
                                                <h5>Login to Goalstox</h5>
                                                <p>Manage your portfolio, transactions and more!</p>
                                            </div>
                                            <div className="from-group mb-20">
                                                <label> Email address</label>
                                                <input type="email" name="current-log-email" onChange={(e) => setUserEmail(e.target.value)} ></input>
                                            </div>
                                            <div className="from-group mb-20">
                                                <label>Password <span className="ml-auto tx-theme" onClick={gotoforgetpassword} style={{ cursor: "pointer" }}>Forgot Password?</span></label>
                                                <input type={showPassword} name="current-log-password" onChange={(e) => setUserPassword(e.target.value)}></input>
                                                {showPassword == "password" ?
                                                    <span className="password-show"><i className="fas fa-eye" onClick={handleClickShowPassword}></i></span>
                                                    : <span className="password-show"><i className="fa fa-eye-slash" onClick={handleClickShowPassword}></i></span>}
                                            </div>
                                            <button
                                                className="btn btn-primary btn-lg btn-full mb-4"
                                                onClick={loginprocess}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Sign In"
                                                )}
                                            </button>
                                            <p className="text-center"><span className="tx-theme" onClick={() => navigate("/login-mobile")} style={{ cursor: "pointer" }}>Login with OTP</span></p>
                                            <p className="mb-0 text-center">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                <div className="p25 mt-5">
                    {/* <Link to="http://192.168.1.20:3020/" className="backbutton"><img src="img/back.png" className="wd-20" /></Link> */}

                    <div className="mb-4 text-center">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <h5>Login to Goalstox</h5>
                        <p className="tx-gray tx-12">Manage your portfolio, transactions and more!</p>
                    </div>
                    <div className="from-group mb-3">
                        <label>Enter your  email address</label>
                        <input type="email" name="current-log-email" onChange={(e) => setUserEmail(e.target.value)} placeholder="yourname@yourmail.com"></input>
                    </div>
                    <div className="from-group mb-3">
                        <label>Password <span className="ml-auto tx-theme" onClick={gotoforgetpassword} style={{ cursor: "pointer" }}>Forgot Password?</span></label>
                        <input type={showPassword} name="current-log-password" placeholder="Password" onChange={(e) => setUserPassword(e.target.value)}></input>
                        {showPassword == "password" ?
                            <span className="password-show"><i class="fas fa-eye" onClick={handleClickShowPassword}></i></span>
                            : <span className="password-show"><i class="fa fa-eye-slash" onClick={handleClickShowPassword}></i></span>}
                    </div>
                    <button
                        className="btn btn-primary btn-lg btn-full mb-4"
                        onClick={loginprocess}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                            "Sign In"
                        )}
                    </button>
                    <p className=" text-center "><span className="tx-theme" onClick={() => navigate("/login-mobile")} style={{ cursor: "pointer" }}>Login with OTP</span></p>
                    <p className="mb-0 text-center">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>
                </div>
            </MobileView>
            
        </>
    )
}
export default Home