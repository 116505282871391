import React from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar,Step } from "react-step-progress-bar";
function CustomProgressBar({ percent }) {
  return (
    <ProgressBar
    percent={percent} 
    filledBackground="linear-gradient(to right, #304AA6)"
  >
  </ProgressBar>
  )
}

export default CustomProgressBar


