import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { usePDF } from 'react-to-pdf';
import { TailSpin } from "react-loader-spinner";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
let apiServices = new ApiService();

function PortfolioValuationReport(){
    const didMountRef = useRef(true)
    const [Userdata, setUserdata] = useState("")
    const [Headingdata, setHeadingdata] = useState({})
    const [goalstoxHoldingsData, setGoalstoxHoldingsData] = useState([])
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const { toPDF, targetRef } = usePDF({filename: 'portfolio-valuation-report.pdf'});
    const Navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                if (result.data.status == 'success') {
                    setUserdata(result.data.data)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    // history("/")
                }
                else {
                    alert(result.data.message)
                }
            })
            getgoalstoxholdings()
        }
        didMountRef.current = false;
    })
    const getgoalstoxholdings = () => {
        setSpinnerLoading(true)
        const dataString = {
            id: Userdata?.gs_id
        }
        apiServices.getgoalstoxholdingvaluationsreportpostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                setGoalstoxHoldingsData(res.data.data)
                setHeadingdata(res.data.HeaddingData)
                setSpinnerLoading(false)
             
            }
            else{
                setSpinnerLoading(false)
            }
           
        })
    }
    const openPdf=()=>{
        setSpinnerLoading(true)
        
        toPDF();
        setTimeout(() => {
            setSpinnerLoading(false)
        }, 4000);
    }
    return(
        <>
         <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
        <div ref={targetRef}> 
        <div className="bg-white" style={{padding:"20px"}}>
    <div className="text-center" style={{cursor:"pointer"}}><a onClick={()=>Navigate('/investment')} style={{cursor:"point"}}>Back | </a>
    <a  onClick={() => {openPdf()}}  disabled={spinnerLoading}>  {spinnerLoading ? (
                ''
            ) : (
                "Download PDF"
            )}</a>
    </div>


<div className="container">

    <table className="table-borderless">
        <thead>
         
            <tr>
                <th style={{width:"200px"}}>{Userdata.gs_fname} {Userdata.gs_lname} [ {Userdata.gs_pan_no} ]</th>
                <th className="text-center" style={{width:"700px"}}>AMFI-Registered Mutual Fund Distributor</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{width:"200px"}}>Mobile No : {Userdata.gs_mobile}</td>
                    <td style={{width:"700px"}} className="text-center">C-606, Unitech Business Zone, Sector 50,Gurugram-122018</td>
                    <td> 
                       
                                                    </td>
                  
                </tr>
                <tr>
                <td style={{width:"300px"}}>Email : {Userdata.gs_email}</td>
                <td className="text-center">Email : support@goalstox.com | 7667752467 | ARN-249633</td>
                  <td><img src="/img/logo.png" width="150px"></img></td>  
                   
                </tr>
                <tr>
                    <td>Address : {Userdata.gs_address1},{Userdata.gs_city}-{Userdata.gs_pin}</td>
                    <td className="text-center">Website : https://app.goalstox.com/</td>
                    <td></td>
                </tr>
       
            </tbody>
    
    </table>

</div>
</div>

<section className="sec-pad-sm bg-white">
<div className="container">
<table class="custom-table mb-5">
  <thead>
  <th scope="col"  style={{background:"rgb(247 155 177)"}}>Amount Invested</th>
  <th scope="col"  style={{background:"rgb(247 155 177)"}}>Amount Withdrawn</th>
  <th scope="col"  style={{background:"rgb(247 155 177)"}}>Current Market Value</th>
  <th scope="col"  style={{background:"rgb(247 155 177)"}}>Absolute Return</th>
  <th scope="col"  style={{background:"rgb(247 155 177)"}}>XIRR (%)</th>
    </thead>
    <tbody>
    <th>₹{Headingdata.totalAmountInvested}</th>
    <th>₹{Headingdata.totalAmountwITHDRAWAL}</th>
    <th>₹{Headingdata.totalMarketvALUE}</th>
    <th>{Headingdata.totalabsolutereturns}%</th>
    <th>{Headingdata.totalxirrValue}%</th>
    </tbody>
    </table>

    <table className="custom-table">
    <>
  <thead>
    <tr>
    <th scope="col"  style={{background:"rgb(243 225 171)",width:'400px'}}>Fund Name</th>
      <th scope="col"  style={{background:"rgb(243 225 171)"}}>Folio Number</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'150px'}}>Inv Since</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'50px'}}>Investments
      & Switch In</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'50px'}}>Withdrawls &
      Switch Out</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'90px'}}>Balance Units</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'90px'}}>Avg Cost</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'90px'}}>NAV</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'90px'}}>Present Market Value</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'90px'}}>Overall Gains/Loss</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'100px'}}>Abs.Ret.(%)</th>
      <th scope="col"  style={{background:"rgb(243 225 171)",width:'100px'}}>XIRR(%)</th>
      {/* <th scope="col"  style={{background:"rgb(243 225 171)"}}>XIRR (%)</th> */}
      {/* <th scope="col"  style={{background:"rgb(243 225 171)"}}>XIRR(%)</th> */}
    </tr>
  </thead>
  <tbody>
  {goalstoxHoldingsData.map((value) => (
    value.totalunits > 0 ?
    <tr>
      <td>{value.pg_schemename}</td>
      <td>{value.pg_folio_no}</td>
      <td>{value.similarfunds[0].pg_new_trans_date}<br />({value.investeddays} Days)</td>
      <td>{value.avginvestments}</td>
      <td>{value.avgwithdrawals}</td>
      <td>{value.totalunits}</td>
      <td>{value.avgcostprice}</td>
      <td>{value.pg_nav}</td>
      <td>{value.valueatnav.toFixed(2)}</td>
      <td>{value.unrealisedprofit}</td>
      {value.unrealisedprofitpercent !== "" ?<td>{Math.sign(value.unrealisedprofitpercent) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{fontSize:'11px'}}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{fontSize:'11px'}}></i>}{value.unrealisedprofitpercent}%</td>:<td>-</td>}
      <td>{value.xirrValue && value.xirrValue > 0 ? value.xirrValue : 0}%</td>
    </tr>:''))}
    </tbody>
    
</>
</table>
</div>

</section>

</div>
        </>
    )
}
export default PortfolioValuationReport