import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import Finance from 'financejs';
import moment from 'moment'
import { usePDF,Margin } from 'react-to-pdf';
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
let apiServices = new ApiService();
const finance = new Finance();

function InvestmentReport(){
    const [Userdata, setUserdata] = useState("")
    const [goalstoxHoldingsData, setGoalstoxHoldingsData] = useState([])
    const { toPDF, targetRef } = usePDF({filename: 'transaction-report.pdf',  page: { margin: Margin.MEDIUM },});
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            
            let data = JSON.parse(localStorage.getItem('user'));
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                if (result.data.status == 'success') {
                    setUserdata(result.data.data)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    // history("/")
                }
                else {
                    alert(result.data.message)
                }
            })
            getgoalstoxholdings()
        }
        didMountRef.current = false;
    })
    const getgoalstoxholdings = () => {
        setSpinnerLoading(true)
        const dataString = {
            id: Userdata?.gs_id
        }
        apiServices.getgoalstoxholdingsreportpostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                setGoalstoxHoldingsData(res.data.data)
                setSpinnerLoading(false)
             
            }
            else{
                setSpinnerLoading(false)
            }
           
        })
    }
    const openPdf=()=>{
        setSpinnerLoading(true)
        
        toPDF();
        setTimeout(() => {
            setSpinnerLoading(false)
        }, 4000);
    }
    return(
        <>
          <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
<div  ref={targetRef}>

 <div className="bg-white" style={{padding:"20px"}}>
    <div className="text-center" style={{cursor:"pointer"}}><a onClick={()=>Navigate('/investment')} style={{cursor:"point"}}>Back | </a>
    <a  onClick={() => {openPdf()}}  disabled={spinnerLoading}>  {spinnerLoading ? (
                                                        ''
                                                    ) : (
                                                        "Download PDF"
                                                    )}</a>
    </div>


<div className="container">

    <table className="table-borderless">
        <thead>
         
            <tr>
                <th style={{width:"200px"}}>{Userdata.gs_fname} {Userdata.gs_lname} [ {Userdata.gs_pan_no} ]</th>
                <th className="text-center" style={{width:"700px"}}>AMFI-Registered Mutual Fund Distributor</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{width:"200px"}}>Mobile No : {Userdata.gs_mobile}</td>
                    <td style={{width:"700px"}} className="text-center">C-606, Unitech Business Zone, Sector 50,Gurugram-122018</td>
                    <td> 
                       
                                                    </td>
                  
                </tr>
                <tr>
                <td style={{width:"300px"}}>Email : {Userdata.gs_email}</td>
                <td className="text-center">Email : support@goalstox.com | 7667752467 | ARN-249633</td>
                  <td><img src="/img/logo.png" width="150px"></img></td>  
                   
                </tr>
                <tr>
                    <td>Address : {Userdata.gs_address1},{Userdata.gs_city}-{Userdata.gs_pin}</td>
                    <td className="text-center">Website : https://app.goalstox.com/</td>
                    <td></td>
                </tr>
       
            </tbody>
    
    </table>

</div>
</div>
<section className="sec-pad-sm bg-white">
<div className="container">

        <table class="table table-bordered border-dark" >

  {goalstoxHoldingsData.map((value) => (
    <>
  <thead>
    <tr><th colSpan={7} style={{background:"#9baff9"}}>{value.pg_schemename}</th></tr>
    <tr>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>Trans Date</th>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>Trans Type</th>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>Folio Number</th>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>NAV value per Unit</th>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>Units</th>
      <th scope="col" style={{background:"rgb(243 225 171)"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  {value.similarfunds.map((subvalue) => (
    value.pg_trans_desc !== "TICOB" || subvalue.pg_trans_desc !== "TI"?
    <tr>
      <th scope="row">{subvalue.pg_trans_date}</th>
      <td>{subvalue.pg_trans_desc}</td>
      <td>{subvalue.pg_folio_no}</td>
      <td>{subvalue.pg_pur_price}</td>
      <td>{subvalue.pg_unitholding}</td>
      {subvalue.amount && subvalue.amount == 'negative' ?
                                                 <td className="fw600">-{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</td>
                                                :    <td className="fw600">{Math.round(subvalue.pg_pur_price * subvalue.pg_unitholding * 100) / 100}</td>
                                              }

    </tr>:""))}
    </tbody>
    
    </>
))}
</table>
</div>

</section>
</div>

        </>
    )
}
export default InvestmentReport