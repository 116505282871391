import React, { useEffect, useRef, useState } from "react";
import CustomProgressBar from "../../Components/Elements/progress_bar";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';
import { Hourglass } from "react-loader-spinner";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin } from "react-loader-spinner";
let apiServices = new ApiService();
function Bseonboardingsignupload() {
  const navigate = useNavigate();
  const signatureCanvasRef = useRef(null);
  const [show, setShow] = useState(false);
  const [aufData, setAufData] = useState()
  const [boardstate, setboardstate] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [proceedstep, setproceedstep] = useState(false)
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [signature, setSignature] = useState("")
  const [showlabel, setshowlabel] = useState(true)
  const didMountRef = useRef(true);
  const inputRef = useRef(null);
  const contentRef = useRef(null);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  useEffect(() => {
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
      };
      apiServices.bsegetuserdataPostRequest(dataString).then((result) => {
        if (result.data.status === "success") {
          setSpinnerLoading(false)
          setSignature(result.data.data.gs_signature)
          setAufData(result.data.data?.gs_signature)
          setshowlabel(false)
          if (aufData != null) {
            setboardstate(true)
          }
        }
        else if (result.data.status === 'error' && result.data.message === 'Session expired') {
          navigate('/')
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      });
    }
    didMountRef.current = false;
  }, []);
  const handleBackClick = () => {
    navigate("/bseonboarding/step4");
  };
  const handleUpload = async e => {
    e.current.click();
  };
  const removeImage = () => {
    setImage({ preview: "", raw: "" })
  }
  function saveSignature() {
    const canvas = signatureCanvasRef.current;
    if (canvas.isEmpty()) {
      toast.error("Signature is required ");
      return false
    }
    if (canvas) {
      setSubmitLoader(true)
      const signatureDataURL = canvas.toDataURL();
      setSignature(signatureDataURL)
      console.log("fgdfhf", signatureDataURL)
      setShow(false);
      setproceedstep(true)
      setshowlabel(false)
      const imageData = signatureDataURL
      const base64Image = btoa(imageData);
    }
  }
  function clearSignature() {
    const canvas = signatureCanvasRef.current;
    if (canvas) {
      canvas.clear();
    }
    setImage({ preview: "", raw: "" })
    setAufData("")
    setboardstate(false)
    setSignature("")
    setshowlabel(true)
  }
  const removeSignature = () => {
    setImage({ preview: "", raw: "" })
    setAufData("")
    setboardstate(false)
    setSignature("")
    setshowlabel(true)
  }
  const handleImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(/image.*/)) {
      alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
      return;
    }
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSignature(event.target.result)
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: event.target.result
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const saveImageData = () => {
    if (signature == "" && image == "") {
      toast.error("Please insert the signature")
      return true
    }
    html2canvas(contentRef.current).then(function (canvas) {
      console.log("step 1")
      const dataURL = canvas.toDataURL('image/png');
      console.log(dataURL)
      const blob = dataURLtoBlob(dataURL);
      console.log("step 3")
      const formData = new FormData();
      console.log(formData)
      formData.append('signature', signature);
      console.log(signature, "sign on")
      apiServices.getsignatureuploadrequest(formData).then((res) => {
        console.log(res)
        if (res?.data?.status == "success") {
          navigate("/bseaufform")
        }
        console.log("step 7")
      })
    });
  }
  if (proceedstep == true) {
    if (signature == "" && image == "") {
      toast.error("Please insert the signature")
      return true
    }
    html2canvas(contentRef.current).then(function (canvas) {
      const dataURL = canvas.toDataURL('image/png');
      const blob = dataURLtoBlob(dataURL);
      const formData = new FormData();
      formData.append('signature', signature);
      apiServices.getsignatureuploadrequest(formData).then((res) => {
        if (res?.data?.status == "success") {
          setSubmitLoader(false)
          navigate("/bseaufform")
        }
        console.log("step 7")
      })
    });
  }
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <ToastContainer position={toast.POSITION.TOP_CENTER} />
        {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>
              <Hourglass
                visible={submitLoader}
                ariaLabel="Loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#304AA7', '#304AA7']}
              />
            </div>
          </div>
        </> : ""}
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                    <CustomProgressBar percent={100} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 7 of 7</p>
                        <h6> Sign/Upload Signature</h6>
                      </div>
                      <div className="loginbox" ref={contentRef}>
                        <div className="mb-4">
                          {aufData !== null && boardstate ? <>
                            <div className="col-lg-12">
                              <div className="from-group mb-3">
                                <img src={`${aufData}`} alt="Base64 Image" />
                              </div>
                            </div>
                          </> : <>
                            {image.preview == ""
                              ? signature == "" || signature == null
                                ? <>
                                  <div className="col-lg-12">
                                    <div className="from-group mb-3">
                                      <div>
                                        <SignatureCanvas penColor='black'

                                          canvasProps={{ width: '535', height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} style={{ border: '1px solid #ddd' }} />
                                        <div className="dflex mt-4">
                                          <button onClick={saveSignature} className="btn btn-primary btn-full btn-md me-1">Submit</button>
                                          <button onClick={clearSignature} className="btn btn-danger btn-full btn-md ms-1">Clear Signature</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                <div className="col-lg-12">
                                  <div className="from-group mb-3">
                                    <div className="position-relative" style={{ zIndex: 99 }} >
                                      <img src={signature} style={{ width: "100%", height: "200px", border: '1px solid #ddd' }}></img></div> </div>
                                </div>

                              : ""}
                          </>}
                          {aufData == null || boardstate == false ?
                            <>
                              {signature != "" ? image.preview != '' ?
                                <>
                                  <div className="col-lg-12">
                                    <div className="from-group mb-3">
                                      <div className="position-relative">
                                        <img src={image.preview} style={{ width: "100%", height: "200px", border: '1px solid #ddd' }} ></img></div>
                                    </div>
                                  </div>
                                </>
                                :
                                signature == "" || signature == null ?
                                  <button className="btn btn-secondary btn-lg btn-full mb-2" onClick={(e) => handleUpload(inputRef)} >Upload Signature</button> : ""
                                : <button className="btn btn-secondary btn-lg btn-full mb-2" onClick={(e) => handleUpload(inputRef)} >Upload Signature</button>}
                              <input type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                onChange={handleImage}
                                accept="image/png, image/jpeg" />
                            </>
                            : ""}
                        </div>
                        {signature !== null && signature !== '' ? <>
                          <div className="dflex">
                            <button onClick={saveImageData} className="btn btn-primary btn-lg btn-full" >Save Signature</button>
                            <button onClick={clearSignature} className="btn btn-danger btn-full btn-md ms-1">Clear Signature</button>
                          </div>
                        </>
                          : ""}
                        <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={handleBackClick}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0">Signature Upload</h6>
                  <p className="tx-12 mb-0 line18">Step 7 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div ref={contentRef}>
          <div className="mobile-main">
            <div className="mobile-form">
              <CustomProgressBar percent={100} />
              <div className="p15 pb-5 mb-5">
                <div>
                  <div className="mb-2">
                    <div className="row g-2">
                      {aufData !== null && boardstate ? <>
                        <div className="col-lg-12">
                          <div className="from-group mb-3">
                            <img src={`${aufData}`} alt="Base64 Image" />
                          </div>
                        </div>
                      </> : <>
                        {image.preview == ""
                          ? signature == "" || signature == null
                            ? <>
                              <div className="col-lg-12">
                                <div className="from-group mb-3">
                                  <div>
                                    <SignatureCanvas penColor='black'
                                      canvasProps={{ width: '460', height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} style={{ border: '1px solid #ddd' }} />
                                    <div className="dflex">
                                      <button onClick={saveSignature} className="btn btn-primary btn-full btn-md me-1">Submit</button>
                                      <button onClick={clearSignature} className="btn btn-danger btn-full btn-md ms-1">Clear Signature</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <div className="col-lg-12">
                              <div className="from-group mb-3">
                                <div className="position-relative" style={{ zIndex: 99 }} >
                                  <img src={signature} style={{ width: "100%", height: "200px", border: '1px solid #ddd' }}></img></div> </div>
                            </div>
                          : ""}
                      </>}
                      {aufData == null || boardstate == false ?
                        <>
                          {signature != "" ? image.preview != '' ?
                            <>
                              <div className="col-lg-12">
                                <div className="from-group mb-3">
                                  <div className="position-relative">
                                    <img src={image.preview} style={{ width: "100%", height: "200px", border: '1px solid #ddd' }} ></img></div>
                                </div>
                              </div>
                            </>
                            :
                            signature == "" || signature == null ?
                              <button className="btn btn-secondary btn-lg btn-full mb-2" onClick={(e) => handleUpload(inputRef)} >Upload Signature</button> : ""
                            : <button className="btn btn-secondary btn-lg btn-full mb-2" onClick={(e) => handleUpload(inputRef)} >Upload Signature</button>}
                          <input type="file"
                            ref={inputRef}
                            style={{ display: "none" }}
                            onChange={handleImage}
                            accept="image/png, image/jpeg" />
                        </>
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {signature !== null && signature !== '' ?
            <div className="footer-button">
              <div className='d-flex'>
                <button className=" btn btn-danger btn-lg btn-full me-2 " onClick={clearSignature}>
                  Clear Signature
                </button>
                <button onClick={saveImageData} className="btn btn-primary btn-lg btn-full" >Save Signature</button>
              </div>
            </div>
            : ""}
        </div>
      </MobileView>
    </>
  );
}
export default Bseonboardingsignupload;
