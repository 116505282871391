import React,{useState,useEffect} from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";

function HelpSupport(){
  const apiServices = new ApiService()
  const [helpsupportdata,sethelpsupportdata]=useState("")

  useEffect(()=>{
    apiServices.themeGetRequest().then(result => {
      console.log(result)
      if (result.data.status == 'success') {
        sethelpsupportdata(result?.data?.data)
      }
    })
  },[])
    const navigate =useNavigate()
    const email = helpsupportdata?.gs_email;



  const redirectToEmail = (e) => {
    e.preventDefault()
    if(email!==""){
      window.location.href = `mailto:${email}`;
    }
  
  };
  const phoneNumber = helpsupportdata?.gs_mobile ? helpsupportdata?.gs_mobile:""

  const redirectToPhoneCall = (e) => {
    e.preventDefault()

    if(phoneNumber!==""){
      window.location.href = `tel:${phoneNumber}`;
    }
   
  };

  // const watsapphoneNumber = '+1234567890'
  const redirectToWhatsApp = (e) => {

    e.preventDefault()
if (phoneNumber !== "") {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
}
  };
    return(
        <>
        <BrowserView>
        <Header/>
        <section className="sec-pad-md">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <div className="helpbox">
                  <div className="helpbox-img">
                    <img src="/img/01.png"/>
                    <h6 className="mt-3">How can we help you today?</h6>
                    <p>Our customer service team will be able to assist you with any order or query</p>
                  </div>
                  <div className="macc-list">
                    <ul>
                      <li>
                        <a onClick={(e)=>{redirectToEmail(e)}}>
                          <div className="macc-list-icon"><img src="/img/02.png"/></div>
                          <div className="line18">
                            <p className="mb-0">Email Us</p>
                            <span className="tx-color-02 tx-11">Write to Goalstox directly for any query</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a onClick={(e)=>{redirectToPhoneCall(e)}}>
                          <div className="macc-list-icon"><img src="/img/03.png"/></div>
                          <div className="line18">
                            <p className="mb-0">Call Us</p>
                            <span className="tx-color-02 tx-11">Get in touch and we will be happy to help you</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li> */}  
                      <li>
                        <a onClick={(e)=>{redirectToWhatsApp(e)}}>
                          <div className="macc-list-icon"><img src="/img/04.png"/></div>
                          <div className="line18">
                            <p className="mb-0">Whatsapp</p>
                            <span className="tx-color-02 tx-11">Whatsapp to Goalstox directly for any query</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
        </BrowserView>
        <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" >
            <i class="d-icon-arrow-left tx-22" onClick={()=>{navigate("/my-account")}}></i>
            <div className="ms-3">
            <h6 className="mb-0">Help & Support</h6>
            </div>
            </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
        <div className="helpbox">
                  <div className="helpbox-img">
                    <img src="/img/01.png"/>
                    <h6 className="mt-3">How can we help you today?</h6>
                    <p>Our customer service team will be able to assist you with any order or query</p>
                  </div>
                  <div className="macc-list">
                    <ul>
                      <li>
                        <a href="#">
                          <div className="macc-list-icon"><img src="/img/02.png"/></div>
                          <div className="line18" onClick={(e)=>{redirectToEmail(e)}}>
                            <p className="mb-0">Email Us</p>
                            <span className="tx-color-02 tx-11">Write to Goalstox directly for any query</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <div className="macc-list-icon"><img src="/img/03.png"/></div>
                          <div className="line18" onClick={(e)=>{redirectToPhoneCall(e)}}>
                            <p className="mb-0">Call Us</p>
                            <span className="tx-color-02 tx-11">Get in touch and we will be happy to help you</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li> */}
                      <li>
                        <a href="#">
                          <div className="macc-list-icon"><img src="/img/04.png"/></div>
                          <div className="line18" onClick={(e)=>{redirectToWhatsApp(e)}}>
                            <p className="mb-0">Whatsapp</p>
                            <span className="tx-color-02 tx-11">Whatsapp to Goalstox directly for any query</span>
                            </div>
                            <i className="d-icon-angle-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
        </div>
        </MobileView>
        </>
    )
}

export default HelpSupport