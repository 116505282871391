import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Skeleton from "react-loading-skeleton";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';

function Multiplefundsippage() {
  const [sipDetail, setSipDetail] = useState([])
  const [mutualfundarray, setmutualfundarray] = useState([])
  const [showskeleton, setshowskeleton] = useState(false)
  const [imageUrl, setimageUrl] = useState([])
  const [step , setStep] = useState(1)
  const [mfSipDates, setMfSipDates] = useState([])
  const [frequencyData, setFrequencyData] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const [inputValue, setInputValue] = useState('');
  const [saveAllData , setSaveAllData] = useState({frequencySip : '' ,startdate:new Date() , sipduration:'',amount:'' })
  let apiServices = new ApiService();
  const navigate = useNavigate()
  const storedMergedArray = JSON.parse(sessionStorage.getItem('mergedequityanddebt'));
  console.log(storedMergedArray, "mergedequityanddebt");

  useEffect(() => {
    setshowskeleton(true)
    const dataString = {
      mf_id: storedMergedArray
    }
    apiServices.getmultiplefundsipostrequest(dataString).then((response) => {


      if (response?.data?.status == "success") {
        setshowskeleton(false)
        setmutualfundarray(response?.data?.data)
        setimageUrl(response?.data?.imgeURL)
      }
      else {
        setshowskeleton(false)
      }

    })
  }, [])

  const gotoStep2 = () => {
   setStep(step+1)
  };
    



const frequencyHandleChange = (id, date) => {
  setFrequencyData(id)
  setMfSipDates(date.split(','))
}

const currentDate = new Date();
const futureDate = new Date();
futureDate.setDate(currentDate.getDate() + 15);
const filterDates = (date) => {
return date >= currentDate && date <= futureDate;

};

const SIPDurationoptions = [];
    for (let i = 2; i <= 29; i++) {
        SIPDurationoptions.push(<option key={i} value={i} >{i} Years</option>);
    }
    function addNumberSuffix(number) {
      const lastDigit = number % 10;
      const lastTwoDigits = number % 100;

      // Special case for numbers ending in 11, 12, and 13
      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
          return number + 'th';
      }

      switch (lastDigit) {
          case 1:
              return number + 'st';
          case 2:
              return number + 'nd';
          case 3:
              return number + 'rd';
          default:
              return number + 'th';
      }
  }

  const handleValueClick = (amount) => {
    const newValue = parseFloat(saveAllData.amount || 0) + amount;
    setSaveAllData({amount:newValue});
    console.log(saveAllData) 
};

var Array = []
const handleChange=(e)=>{
  console.log(e)
  const key = e.target.name;
  const value = e.target.value;
 Array[key] = value
  console.log(Array)
setSaveAllData(Array)
}

  return (
    <>
      <BrowserView>
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>Multiple Funds</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active href="">Multiple funds</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
        <section className="sec-pad-sm">
          <div className="container">
         
              <div className="row justify-content-center">
              { mutualfundarray.length>0 && mutualfundarray?.map((items, index) => (
              <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header></Accordion.Header>
                                                <Accordion.Body className="sidebarfilter-fixheight">
                                             
                            <div className="col-lg-12" key={index}>
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        <div className="fundboxdetails">
                                            <div className="fundboxdetails-icon">
                                            <img src={items?.mf_image ? imageUrl + items?.mf_image : "/img/defaltimage.png"} /> 
                                            </div>
                                            <div className="fundboxdetails-name">
                                              <h6>{items?.mf_scheme_name}</h6>
                                            <div class="mfbox-details-meta"><ul><li>Very High Risk</li><li>dept</li><li><i class="icon-star-full2 me-1"></i>5</li></ul></div>   
                                            </div>
                                        </div>
                                        {/* {step== 4 ?<> <div class="fundboxClose"><i class="icon-cross2" onClick={()=>{clearLastStep()}}></i></div></>:""} */}
                                       
                                    </div>
                                    <div className="fundbox-body">
                                    {step == 1 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">

                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Frequency of SIP</label>
                                                                <select
                                                                    name="frequencySip"
                                                                    className="custom-select"
                                                                   
                                                                    onChange={handleChange}
                                                                  
                                                                >
                                                                    <option value={``}>SELECT FREQUENCY</option>
                                                                    {items.sipdetails.map((value) => (
                                                                        <option value={`${value.mfsip_frequency}-${value.mfsip_dates}`} >{value.mfsip_frequency}</option>
                                                                    ))}
                                                                </select>
                                                             
                                                                <label className="tx-gray">Date to deduct SIP amount</label>
                                                               <select name="startdate" onChange={handleChange}>
                                                             <option>Select</option>
                                                             {items.sipdetails.map((value, index) => (
    value.mfsip_dates.split(',').map((subvalue, subindex) => (
        <option key={`${index}-${subindex}`}>{subvalue}</option>
    ))
))}
                                                               </select>
                                                                 <label className="tx-gray">Duration Of SIP (In Years)</label>
                                                                <select name="sipduration" className="custom-select" value={saveAllData.sipduration}  onChange={handleChange}>
                                                                <option value="">Select SIP Duration</option>
                                                                <option value="1" >1 Year</option>
                                                                    {SIPDurationoptions}
                                                                </select>
                                                                <label className="tx-gray">Amount</label>
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="amount"
                                                                    placeholder="Please enter a number"
                                                                    // decimalsLimit={2}
                                                                    onChange={handleChange}
                                                                     prefix="₹"
                                                                  />
                                                                {/* <span onClick={() => { handleValueClick(500) }}>+500</span>
                                                                <span onClick={() => { handleValueClick(1000) }}>+1000</span>
                                                                <span onClick={() => { handleValueClick(2000) }}>+2000</span>
                                                                <span onClick={() => { handleValueClick(5000) }}>+5000</span> */}
                                                                
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* <button type="button" className="btn btn-primary btn-lg btn-full" onClick={()=>{gotoStep2(items.mf_id)}}>CONTINUE</button> */}
                                            </div> : ""}
                                           

                                        {step == 4 || items.step == 4 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="banklist" style={{ height: "auto" }}>
                                                                <div className="banklist-content">
                                                                    <ul>
                                                                        <li>Investment Amount:<span>{inputValue}</span></li>
                                                                        <li>Frequency of SIP<span>{frequencyData}</span></li>
                                                                        {/* {mutualFundDetail.sip_start_monthname?   <li>Monthly SIP deduction date <span>{addNumberSuffix(moment(startDate).format('DD'),)} {moment(startDate).format('MMM YYYY')} </span></li>:<Skeleton/> }        */}


                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                </div>

                                              
                                            </div> : ""}
                                    </div>
                                </div>
                            </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                          
                                        </Accordion>))}

                        </div>
          </div>
        </section>


        <Footer />
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" >
                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                <div className="ms-3">
                  <h6 className="mb-0">Multiple funds</h6>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="sec-pad-sm">
          <div className="container">
            <div className="row g-2">
              <div className="col-lg-12" >
                {showskeleton == false ? <>
                    { mutualfundarray.length>0 && mutualfundarray?.map((items, index) => {
                      return <>
                        <div className="p-4 panel holding-panel phbox active mb-2">

                          <p key={index}><img src={items?.mf_image ? imageUrl + items?.mf_image : "/img/defaltimage.png"} style={{ width: "55px", height: "55px" }}></img>{items?.mf_scheme_name}</p>

                        </div>

                      </>
                    })}
                </> :
                  <>

                    <div className="p-4 panel holding-panel phbox active mb-2">

                      <Skeleton  height="80px"/>
                    </div>
                    <div className="p-4 panel holding-panel phbox active mb-2">

                      <Skeleton height="80px"/>
                    </div>
                    <div className="p-4 panel holding-panel phbox active">

                      <Skeleton height="80px"/>
                    </div>
                  </>
                }

              </div>
            </div>
          </div>
        </section>
      </MobileView>
    </>
  )
}

export default Multiplefundsippage