import React, { Component, useEffect,useLayoutEffect ,useMemo, useState, useRef } from 'react'
import { ApiService } from "../../Components/Services/apiServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal"; 
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserView, MobileView } from 'react-device-detect';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

let apiServices = new ApiService();
function Header(){
const didMountRef = useRef(true);
const activeclassref=useRef()
const location = useLocation();
const navigate = useNavigate()
const [userData , setUserData] = useState("")
const [mutualfundsearch,setmutualfundsearch]=useState("")
const [searchdata,setsearchdata]=useState([])
const [show, setShow] = useState(false)
const [sendingId , setSendingId] = useState(0)
const [goalID , setGoalID] = useState("")
const Location=  window.location.pathname
const [showMenuCart, setShowMenuCart] = useState(false);
const handleShowMenuCart = () => setShowMenuCart(true);
const handleMenuClose = () => {
    setShowMenuCart(false);
  };
useMemo (() => {
if (didMountRef.current) {
    const pathParts = location.pathname.split('/');
    const dynamicId = isNaN(pathParts[pathParts.length - 1]) ? '' : pathParts[pathParts.length - 1];
    if (pathParts.includes("mutual-funds") && dynamicId) {
        setGoalID(dynamicId)
    }
   
    apiServices.bsegetuserdataPostRequest({}).then(result => {
        if (result.data.status == 'success') {
            setUserData(result.data.data)
        }
        else if (result.data.status == "error" && result.data.message == "Session expired") {
            localStorage.removeItem('AUTH_TOKEN');
            navigate("/")
        }
        else {
            alert(result.data.message)
        }
    })

    // if(mutualfundsearch=="" ||mutualfundsearch.length<1){
    //     setsearchdata("")
    // }
}
didMountRef.current = false;
}, [mutualfundsearch]);




const handlesearch=(e)=>{
    let value=e.target.value
    if(value=="" ||value.length<2 ){
        setsearchdata("")
        return
        
    }
    if(value.length>2 || value.length==2){
        let dataString={
            query:value
        }
        apiServices.getsearchcontentpostrequest(dataString).then(result=>{
            if(result.data.status=="success"){
                setsearchdata(result.data.data)
            }
        })
    }
   
}

const handleemptysearch=(e)=>{
    e.preventDefault()
    if(searchdata==""|| searchdata==null){
        toast.info("Please Enter atleast 2 character  before search ")
    }
    else{

    }
}

const logOut=()=>{
    setShow(true)
}
const  Confirm=()=>{
    localStorage.removeItem('AUTH_TOKEN');
     navigate("/")
    
}
const Cancelalert=()=>{
    setShow(false)
}
return(
<>
<ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
<BrowserView>
<header className="header">
<div className="container">
<div className="header-left">
<a href="/dashboard" className="logo"><img src="/img/logo.png"></img></a>
</div>
<div className="header-center">
<div className="header-search">
<form action="#" className="input-wrapper">
<input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search for top mutual funds..." onChange={(e)=>{setmutualfundsearch(e.target.value);handlesearch(e)}}  />
{searchdata && searchdata.length >0?<>
    <div className='header-search-list'  id="search_input">
    <ul className='' style={{listStyle:"none"}}>
        {searchdata?.map((items,index)=>{return(<>
        
            <li onClick={()=>{window.location.href = `/mutualfund-details/${items?.mf_id}${goalID ? '/'+goalID : ''}`; setsearchdata("")}} key={index}>{items?.mf_scheme_name}</li>
        
        </>)})}
    
        
    </ul>
</div>


</>:""}
    <button className="btn btn-search" type="submit" title="submit-button" onClick={(e)=>{handleemptysearch(e)}}><i className="icon-search4"></i></button>
</form>


</div>
<nav className="navbar navbar-expand-lg navbar-light me-5">
<ul className="navbar-nav me-auto mb-2 mb-lg-0">
<li className="nav-item">
<a  aria-current="page" href="/dashboard" className={Location==="/dashboard"? "nav-link active" : "nav-link"} id="dashboard" >Dashboard</a>
</li>
<li className="nav-item">
<a  href="/investment" id="portfolio" className={Location=="/investment"? "nav-link active" : "nav-link"} >Portfolio</a>
</li>
<li className="nav-item">
<a  href="/mutual-funds" id="mutualfunds"  className={Location=="/mutual-funds"? "nav-link active" : "nav-link"} >Mutual Funds</a>
</li>
</ul>
</nav>

{/*  */}
</div>
<div className="header-right justify-content-end">

<Dropdown className="topbar-user">
<Dropdown.Toggle  id="dropdown-basic" className="topbar-user-drop">
<div className="d-flex align-items-center">
<img src="/img/user-icon.png" className="header-profile-user"/>
<div className="ms-2 topbar-user-drop-cont">
{userData.gs_fname !== undefined ?
<h6 className="mb-0 tx-14">{userData.gs_fname}  </h6>:
<h6 className="mb-0 tx-14"><Skeleton width="150px">
</Skeleton></h6>}
{/* <p className="mb-0 tx-11 tx-gray">{userData.gs_registration_id}</p> */}
{userData.gs_bse_regid !== undefined ?
<p className="mb-0 tx-14 tx-gray"><b>UCC:</b>{userData.gs_bse_regid} </p>:
<p className="mb-0 tx-14 tx-gray"><b>UCC:</b>  <Skeleton width="150px">
</Skeleton></p>}
</div>
</div>
</Dropdown.Toggle>
<Dropdown.Menu>
<div className='dropheader'>
<h6 className='mb-0 tx-14'>Welcome to {userData.gs_fname}</h6>
</div>
<Dropdown.Item href="/bank"><i className="far fa-building me-2"></i>Bank & Autopay</Dropdown.Item>
<hr style={{margin:'0px', borderTop:'1px solid #ddd', opacity:'0.5'}}></hr>
<Dropdown.Item href="/order"><i className="icon-file-text2 me-2"></i>Orders</Dropdown.Item>
{/* <Dropdown.Item href="/my-SIPs"><i className=" icon-wallet me-2"></i>My SIPs</Dropdown.Item> */}
<hr style={{margin:'0px', borderTop:'1px solid #ddd', opacity:'0.5'}}></hr>
<Dropdown.Item href="/help-support"><i className="far fa-comment me-2"></i>Help & Support</Dropdown.Item>
<Dropdown.Item href="/setting"><i className="icon-cog3 me-2"></i>Settings</Dropdown.Item>
<Dropdown.Item href="#/action-3" onClick={logOut}><i class="icon-enter me-2"></i>Logout</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
</div>
</div>
</header>
</BrowserView>
<MobileView>
<header className='mheader'>
<div className="container">
<div className="header-left">
{/* <a href="javascript:void(0)" class="sidenav-trigger"><i class="d-icon-bars2"></i></a> */}
<Link to="/dashboard" className="mlogo"><img src="/img/logo.png"></img></Link>
</div>
<div className="header-right justify-content-end">
    
<div className='mheaderrightlist'>
<ul>
{/* <li><Link to="/notification"><i className="far fa-bell"></i></Link></li> */}
<li><Link to="/my-account" className='mheader-users'><img src="/img/user-icon.png" className="header-profile-user"/></Link></li>
</ul>

</div>
</div>
</div>
</header>

</MobileView>
{/* <Modal
        show={showMenuCart}
        onHide={handleMenuClose}
        className="left menu-modal"
      >
        {showMenuCart && (
          <MenuModal
            showMenucartmodal={showMenuCart}
            onChildCartData={handleChildMenuCartData}
          />
        )}
      </Modal> */}
<SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to logout `} confirmBtnText='Confirm' 
  cancelBtnText="Cancel"
 show={show} onConfirm={Confirm} onCancel={Cancelalert}  btnSize="md"   showCancel
 cancelBtnBsStyle="danger"
>
</SweetAlert>
</>
)
}
export default Header