import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import Dropdown from 'react-bootstrap/Dropdown';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ApiService } from "../../Components/Services/apiServices";
import BottomMenu from "../../Components/Footer-Bottom-menu";
import Finance from 'financejs';

const finance = new Finance();
let apiServices = new ApiService();
function Order() {
    const history = useNavigate()
    const didMountRef = useRef(true);
    const [Userdata, setUserdata] = useState("")
    const [GoalstoxHoldingsData, setGoalstoxHoldingsData] = useState([])
    const [skeletonShow, setSkeletonShow] = useState(true)
    const [goalstoxportfolio, setgoalstoxportfolio] = useState(0);
    const [portfolioshow, setPortfolioshow] = useState(1)
    const [totalHoldingsData, setTotalHoldingsData] = useState([])
    const [totalPortfolioAmount, setTotalPortfolioAmount] = useState(0)
    const [mfuStatus, setMfuStatus] = useState()
    const [mfuStatusMessage, setMfuStatusMessage] = useState("")



    const { id } = useParams()

    useEffect(() => {
        if (didMountRef.current) {
            let data = JSON.parse(localStorage.getItem('user'));
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                if (result.data.status == 'success') {
                    setUserdata(result.data.data)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    history("/")
                }
                else {
                    alert(result.data.message)
                }
            })
        

            apiServices.mfuaccountcheckpostrequest({}).then(result => {
                if (result.data.status == "success") {
                    setMfuStatus(result.data.mfustaus)
                    setMfuStatusMessage(result.data.message)

                }

            })
            getgoalstoxholdings()

        }
        didMountRef.current = false;
    })
    const getgoalstoxholdings = () => {

        const dataString = {
            id: Userdata?.gs_id
        }
        setSkeletonShow(true)
        apiServices.getgoalstoxnewholdingsdatapostrequest(dataString).then(res => {
            if (res.data.status == "success") {
                setGoalstoxHoldingsData(res.data.data)
                setSkeletonShow(false);
                setPortfolioshow(1)
                setgoalstoxportfolio(res.data.goalstoxportfolio)
                setTotalPortfolioAmount(res.data.totalportfolio)
            }

        })
    }



    return (
        <>
            <BrowserView>
                <Header />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>My Portfolio</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/investment">Portfolio</Breadcrumb.Item>
                                </Breadcrumb>
                                <div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row g-3">

                            <div className="col-lg-9">

                                <div className="row g-2">
                                    <div className="col-lg-12">
                                        <div className="portfoliotab">
                                            <span>Filter By</span>
                                            {/* <span onClick={totalPortfolio} className={portfolioshow == 0 ? "portfoliotabfilter active" : "portfoliotabfilter"}>Total Portfolio</span> */}
                                            <span onClick={getgoalstoxholdings} className={portfolioshow == 1 ? "portfoliotabfilter active" : "portfoliotabfilter"}>Portfolio With goalstox</span>
                                        </div>
                                    </div>

                                    {

                                        (() => {
                                            if (portfolioshow == 1) {
                                                return (
                                                    skeletonShow == false ?
                                                        GoalstoxHoldingsData.length > 0 ?
                                                            <>
                                                                <div className="col-lg-12">
                                                                    {GoalstoxHoldingsData.map((value) => {
                                                                        if (value.totalunits > 0) {
                                                                            return (
                                                                                <div className="invbox">
                                                                                    <div className="invboxfund">
                                                                                        <div className="invboxfund-details" style={{ marginLeft: '0' }}>
                                                                                            <a href={`/portfolio-details/${value.pg_id}/${value.valueatnav.toFixed(2)}`} className="tx-14">{value.pg_schemename}</a>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="invboxsec">
                                                                                        <span className="tx-gray tx-12">Unit Held</span>
                                                                                        <span className="fw600">{value.totalunits}</span>
                                                                                    </div>
                                                                                 
                                                                                    <div className="invboxsec">
                                                                                        <span className="tx-gray tx-12">Value At Cost</span>
                                                                                        <span className="fw600">₹{value.valueatcost}</span>
                                                                                    </div>
                                                                                  
                                                                                    <div className="invboxsec">
                                                                                        <span className="tx-gray tx-12">Latest NAV : <span className="fw600"> {value.pg_nav}</span></span>
                                                                                       
                                                                                        <span className="tx-gray tx-12">Value at NAV : <span className="fw600">{value.valueatnav.toFixed(2)}</span></span>
                                                                                      
                                                                                    </div>

                                                                                    <div className="invboxsec">
                                                                                        <span className="tx-gray tx-12"><span className="fw600" style={{ color: Math.sign(value.unrealisedprofitpercent) === -1 ? "red" : "green" }}>{Math.sign(value.unrealisedprofitpercent) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true" style={{ fontSize: '11px' }}></i> : <i className="fa fa-arrow-up" aria-hidden="true" style={{ fontSize: '11px' }}></i>}{value.unrealisedprofitpercent}%</span></span>
                                                                                      
                                                                                        <span className="tx-gray tx-12"> <span className="fw600"  >₹{value.unrealisedprofit}</span></span>
                                                                                    </div>
                                                                                    <div className="invboxsec">
                                                                                        <Dropdown className="actiondrop">
                                                                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="actiondrop-drop">
                                                                                                <i class="icon-more2"></i>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item href={"/investment-redeem/" + value.pg_id}>Redeem <span className="ms-3"></span></Dropdown.Item>
                                                                                                <Dropdown.Item href={"/investment-switch/" + value.pg_id}>Switch <span className="ms-3"></span></Dropdown.Item>

                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div>);
                                                                        }
                                                                    })}

                                                                </div>
                                                            </> : <div className="noimg">
                                                                <img src="/img/noitemavailable.webp" />
                                                                <h6>No Portfolio Found</h6>
                                                                <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                                            </div> : <>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                )
                                            }else if (portfolioshow == 0) {
                                                if (totalHoldingsData.length == 0 && skeletonShow == false && mfuStatus == 3) {
                                                    return (
    
                                                        <div className="noimg">
                                                            <img src="/img/noitemavailable.webp" />
                                                            <h6>No Portfolio Found</h6>
                                                            <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                                        </div>
                                                    )
                                                }
                                                else if (totalHoldingsData.length == 0 && skeletonShow == false && mfuStatus == 1) {
                                                    return (
    
                                                        <div className="noimg">
                                                            <img src="/img/nomfudata.png" />
                                                            <h6>{mfuStatusMessage}</h6>
                                                            <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                                        </div>
                                                    )
                                                }
                                                else if (totalHoldingsData.length == 0 && skeletonShow == false && mfuStatus == 2) {
                                                    return (
    
                                                        <div className="noimg">
                                                            <img src="/img/nomfudata.png" />
                                                            <h6>{mfuStatusMessage}</h6>
                                                            <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                                        </div>
                                                    )
                                                }
                                                else if (totalHoldingsData.length == 0 && skeletonShow == false && mfuStatus == 4) {
                                                    return (
    
                                                        <div className="noimg">
                                                            <img src="/img/nomfudata.png" />
                                                            <h6>{mfuStatusMessage}</h6>
                                                            <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                                        </div>
                                                    )
                                                }else{
    
                                                return (
                                                    skeletonShow == false ?
    
                                                        <>
    
    
                                                            <div className="col-lg-12">
                                                                {totalHoldingsData.map((value) => (
    
                                                                    <div className="invbox">
                                                                        <div className="invboxfund">
                                                                           
                                                                            <div className="invboxfund-details" style={{ marginLeft: '0' }}>
                                                                                <a href="#" className="tx-14">{value.ic_schemename}</a>
                                                                               
                                                                            </div>
                                                                        </div>
                                                                        <div className="invboxsec">
                                                                            <span className="tx-gray tx-12">Folio</span>
                                                                            <span className="fw600">{value.ic_foliono}</span>
                                                                        </div>
                                                                        <div className="invboxsec">
                                                                            <span className="tx-gray tx-12">Latest NAV</span>
                                                                            <span className="fw600">₹{value.ic_nav}</span>
                                                                        </div>
                                                                        <div className="invboxsec">
                                                                            <span className="tx-gray tx-12">Units Held</span>
                                                                            <span className="fw600">₹{value.ic_unitholding}</span>
                                                                        </div>
                                                                        <div className="invboxsec">
                                                                            <span className="tx-gray tx-12">Value at NAV</span>
                                                                            <span className="fw600">{value.ic_currentvalue}</span>
                                                                        </div>
                                                                    </div>))}
                                                            </div>
    
                                                        </>
                                                        : <>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">  <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="invbox">
                                                                    <div className="invboxfund">
                                                                        <div className="invboxfund-icon">
                                                                            <Skeleton width="55px" height="59px" /></div>
                                                                        <div className="invboxfund-details">
                                                                            <Skeleton width="100%" height="10px" />
                                                                            <ul>
                                                                                <Skeleton width="100%" height="10px" />
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                    <div className="invboxsec">
                                                                        <Skeleton width="55px" height="10px" />
                                                                        <Skeleton width="55px" height="10px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                )
                                            }
                                            }
                                        })()}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="portfolioBox mb-3">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="probsec">
                                                {/* <div className="probsecbox">
                                                    <h3 className="fw600">₹{totalPortfolioAmount ? totalPortfolioAmount : 0}</h3>
                                                    <p className="mb-0 tx-gray tx-12">Total Portfolio</p>
                                                </div> */}
                                                <div className="probsecbox">
                                                    <h3 className="fw600">₹{goalstoxportfolio ? goalstoxportfolio : 0}</h3>
                                                    <p className="mb-0 tx-gray tx-12">Portfolio With goalstox</p>
                                                </div>
                                                {GoalstoxHoldingsData.length >0 ? 
                                                <div className="probsecbox">
                                                    <div className="poranylis">
                                                        <p className="mb-1">Check your portfolio’s health</p>
                                                      <p className="tx-green mb-0" onClick={() => { window.open('/investment-report', '_blank') }} style={{ cursor: "pointer" }}>Transaction Report</p>
                                                        <p className="tx-green mb-0" onClick={() => { window.open('/portfolio-valuation-report', '_blank'); }} style={{ cursor: "pointer" }}>Portfolio Valuation Report</p>
                                                    </div>
                                                </div>:""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { history("/dashboard") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">My Investments</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="mobile-main">
                    <div className="siptabs">
                        <ul>
                    
                            <li className={portfolioshow == 1 ? "border-end active" : "border-end"} 
                            // onClick={getgoalstoxholdings}
                            >Portfolio With Goalstox(₹{goalstoxportfolio ? goalstoxportfolio : 0})</li>
                        </ul>
                    </div>
                    {(() => {
                        if (portfolioshow == 1) {
                            return (
                                skeletonShow == false ?
                                    GoalstoxHoldingsData.length > 0 ?
                                        GoalstoxHoldingsData.map((value) => {
                                            if (value.totalunits > 0) {
                                                return (
                                                    <div className="invboxmobile">
                                                        <div className="invboxmobile-left">
                                                            <div className="invboxfundm">

                                                                <div className="invboxfundm-details"  >
                                                                    <h6 className="tx-11 mb-0" onClick={() => history(`/portfolio-details/${value.pg_id}/${value.valueatnav.toFixed(2)}`)}>{value.pg_schemename}</h6>
                                                                    <ul>
                                                                        {/* <li>{value.totalunits}</li> */}
                                                                        <li><a href={"/investment-redeem/" + value.pg_id}>Redeem</a></li>
                                                                        <li><a href={"/investment-switch/" + value.pg_id}>Switch</a></li>
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="invboxmobile-right">
                                                            <div class="line20">
                                                                <div class="tx-green fw600" style={{ color: Math.sign(value.unrealisedprofitpercent) === -1 ? "red" : "green" }} >{Math.sign(value.unrealisedprofitpercent) === -1 ? <i className="fa fa-arrow-down" aria-hidden="true"></i> : <i className="fa fa-arrow-up" aria-hidden="true"></i>}{value.unrealisedprofitpercent}%</div>
                                                                <div class="tx-12">(₹{value.valueatnav.toFixed(2)})</div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                            }
                                        }) : <div className="noimg">
                                            <img src="/img/noitemavailable.webp" />
                                            <h6>No Portfolio Found</h6>
                                            <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                        </div>
                                    :
                                    <>
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">

                                                    <div className="invboxfundm-details">
                                                        <Skeleton width="355px" height="10px" />
                                                        <Skeleton width="55px" height="10px" />


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <Skeleton width="55px" height="10px" />
                                                    <Skeleton width="55px" height="10px" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">

                                                    <div className="invboxfundm-details">
                                                        <Skeleton width="355px" height="10px" />
                                                        <Skeleton width="55px" height="10px" />


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <Skeleton width="55px" height="10px" />
                                                    <Skeleton width="55px" height="10px" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">

                                                    <div className="invboxfundm-details">
                                                        <Skeleton width="355px" height="10px" />
                                                        <Skeleton width="55px" height="10px" />


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <Skeleton width="55px" height="10px" />
                                                    <Skeleton width="55px" height="10px" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">

                                                    <div className="invboxfundm-details">
                                                        <Skeleton width="355px" height="10px" />
                                                        <Skeleton width="55px" height="10px" />


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <Skeleton width="55px" height="10px" />
                                                    <Skeleton width="55px" height="10px" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">

                                                    <div className="invboxfundm-details">
                                                        <Skeleton width="355px" height="10px" />
                                                        <Skeleton width="55px" height="10px" />


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <Skeleton width="55px" height="10px" />
                                                    <Skeleton width="55px" height="10px" />

                                                </div>
                                            </div>
                                        </div>
                                    </>
                            )
                        }
                        else if (GoalstoxHoldingsData.length == 0 && mfuStatus == 3) {
                            return (<>
                                <div className="noimg">
                                    <img src="/img/noitemavailable.webp" />
                                    <h6>No Portfolio Found</h6>
                                    <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                </div>
                            </>
                            )
                        }
                        else if (GoalstoxHoldingsData.length == 0 && mfuStatus == 1) {
                            return (
                                <div className="noimg">
                                    <img src="/img/nomfudata.png" />
                                    <h6>{mfuStatusMessage}</h6>
                                    <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                </div>
                            )
                        }
                        else if (GoalstoxHoldingsData.length == 0 && mfuStatus == 2) {
                            return (
                                <div className="noimg">
                                    <img src="/img/nomfudata.png" />
                                    <h6>{mfuStatusMessage}</h6>
                                    <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                </div>
                            )
                        }
                        else if (GoalstoxHoldingsData.length == 0 && mfuStatus == 4) {
                            return (
                                <div className="noimg">
                                    <img src="/img/nomfudata.png" />
                                    <h6>{mfuStatusMessage}</h6>
                                    <a href="https://mfu.goalstox.com/" target="new" class="btn btn-outline-primary btn-md mt-3">Let's Go!</a>
                                </div>
                            )
                        }
                    })()}
                    {/* {(() => {
                        if (portfolioshow == 0) {
                            return (
                                <>
                                    {totalHoldingsData.map((value) => (
                                        <div className="invboxmobile">
                                            <div className="invboxmobile-left">
                                                <div className="invboxfundm">
                                                    <div className="invboxfundm-details">
                                                        <h6 className="tx-11 mb-0">{value.ic_schemename}</h6>
                                                        <ul>
                                                            <li>{value.ic_foliono}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invboxmobile-right">
                                                <div class="line20">
                                                    <div ><strong>₹{value.ic_nav}</strong></div>
                                                    <div class="tx-12">(₹{value.ic_currentvalue})</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </>)
                        }
                    })()} */}
                </div>
                <BottomMenu></BottomMenu>
            </MobileView>
        </>
    )
}

export default Order