import React,{useState,useMemo,useRef} from "react";

import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { TailSpin } from "react-loader-spinner";
function Myaccount(){
    const didMountRef = useRef(true);
    const [userData , setUserData] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false)
    let apiServices = new ApiService();

    useMemo (() => {
        if (didMountRef.current) {
            setSubmitLoader(true)
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                if (result.data.status == 'success') {
                    setUserData(result.data.data)
                    setSubmitLoader(false)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    setSubmitLoader(false)
                    navigate("/")
                }
                else {
                    alert(result.data.message)
                    setSubmitLoader(false)
                }
            })
        
           
        }
        didMountRef.current = false;
        }, []);
     
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const logOut=()=>{
        setShow(true)
    }
    const  Confirm=()=>{
        localStorage.removeItem('AUTH_TOKEN');
         navigate("/")
        
    }
    const Cancelalert=()=>{
        setShow(false)
    }
    return(
        <>

{submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
    
        <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" >
            <i class="d-icon-arrow-left tx-22" onClick={()=>{navigate("/dashboard")}}></i>
            <div className="ms-3">
            <h6 className="mb-0">My Account</h6>
            </div>
            </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
            <div>
            <div className="account-user">
                <div className="au-imag">
                <img src="/img/user-icon.png"/>
                </div>
                <div className="au-content">
                <h6 className="mb-0">Hi! {userData?.gs_fname}</h6>
                <p className="mb-0">{userData?.gs_email}</p>
                </div>
                </div>
                <div className="maccoutlist">
                    <ul>
                    <li onClick={()=>{navigate("/setting")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/profile.png"></img>
                           </div>
                          My Profile
                            <i class="d-icon-angle-right" ></i>
                            </li>
                        <li onClick={()=>{navigate("/bank")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/bank (2).png"></img>
                           </div>
                            Bank and Autopay
                            <i class="d-icon-angle-right" ></i>
                            </li>
                            <li onClick={()=>{navigate("/order")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/order (1).png"></img>
                           </div>
                            Orders
                            <i class="d-icon-angle-right"></i>
                            </li>
                            {/* <li onClick={()=>{navigate("/my-SIPs")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/myships (2).png"></img>
                           </div>
                            My SIPs
                            <i class="d-icon-angle-right"></i>
                            </li> */}
                            <li onClick={()=>{navigate("/nominee-details")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/nominee (1).png"></img>
                           </div>
                            Nominee Details
                            <i class="d-icon-angle-right"></i>
                            </li>
                            {/* <li onClick={()=>{navigate("/help-support")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/helpsupport (1).png"></img>
                           </div>
                           Help & Support
                            <i class="d-icon-angle-right"></i>
                            </li>
                            <li onClick={()=>{navigate("/setting")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/setting.png"></img>
                           </div>
                           Settings
                            <i class="d-icon-angle-right"></i>
                            </li> */}
                    </ul>
                    </div>
                <div className="maccoutlist">
                    <ul>
                            <li onClick={()=>{navigate("/change-password")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/changepassword (1).png"></img>
                           </div>
                          Change Password
                            <i class="d-icon-angle-right"></i>
                            </li>
                            <li onClick={()=>{navigate("/help-support")}}>
                            <div className="maccoutlist-icon" >
                            <img src="/img/helpsupport (1).png"></img>
                           </div>
                           Help & Support
                            <i class="d-icon-angle-right"></i>
                            </li>
                            
                    </ul>
                </div>
                
                <div className="p15 text-center">
                    <div className="logoutbutton mb-4" onClick={()=>{logOut()}}>Logout from Goalstox</div>
                    <img src="/img/logo.png" className="wd-150"></img>
                </div>
            </div>
        </div>
        <div>
            {/* <div className="container">
                <div className="mt-5 pt-5">
                    <ul style={{listStyle:"none"}} className="ms-1 ps-2">
                        <li onClick={()=>{navigate("/bank")}}><i class="far fa-building me-2"></i>Bank and Autopay</li>
                        <li onClick={()=>{navigate("/order")}}><i class="icon-file-text2 me-2"></i>Orders</li>
                        <li onClick={()=>{navigate("/my-SIPs")}}><i class=" icon-wallet me-2"></i>My SIPs</li>
                        <li onClick={()=>{navigate("/help-support")}}><i class="far fa-comment me-2"></i>Help & Support</li>
                        <li onClick={()=>{navigate("/setting")}}><i class="icon-cog3 me-2"></i>Settings</li>
                        <li onClick={()=>{logOut()}}><i class="icon-enter me-2"></i>Logout</li>
                    </ul>
                </div>
            </div> */}
        </div>
        </MobileView>
        <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to logout `} confirmBtnText='Confirm' 
  cancelBtnText="Cancel"
 show={show} onConfirm={Confirm} onCancel={Cancelalert}  btnSize="md"   showCancel
 cancelBtnBsStyle="danger"
>
</SweetAlert>
        </>
    )
}

export default Myaccount