import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { BrowserView, MobileView } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumb } from "react-bootstrap";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

let apiServices = new ApiService();

function PopularBasket(){
    const [popularBasketData , setPopularBasketData] = useState([])
    const[popularBasketBaseUrl , setPopularBasketBaseUrl] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false)
    const didMountRef = useRef(true)
    const {goalID} = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        if (didMountRef.current) {
          setSubmitLoader(true)
            apiServices.popularbasketlistingpostrequest().then(result => {
                if(result.data.status == "success"){
                    setPopularBasketData(result.data.data)
                    setPopularBasketBaseUrl(result.data.imgeURL)
                    setSubmitLoader(false)
                }
                else{
                  setSubmitLoader(false)
                }
                
            })
        }
        didMountRef.current = false;
    }, []);
    return(
        <>
   {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
        <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} autoClose={3000} />
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>Popular Basket</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Popular Basket</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>

        <section className="sec-pad-sm">
          <div className="container">
            <div className={'row g-3'}>

           {popularBasketData.map((value)=>( 
            <div className="col-lg-4" onClick={() => { navigate(`/popularbasketdetails/${value?.pbgs_id}${goalID ? '/'+goalID : ''}`) }} >
                        <div className="banklist">
                          <div className="banklist-header">
                            <div className="dflex align-items-center">
                              <div className="banklist-icon">
                                <img src= {value.pbgs_media != null ?popularBasketBaseUrl + value.pbgs_media:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>
                              </div>
                              <div className="banklist-name ms-3">
                                <h6 className="mb-0 tx-uppercase">{value.pbgs_basket_name}</h6>
                                {/* <p className="mb-0 tx-green">Primary Bank</p> */}
                              </div>
                            </div>
                          
                          </div>
                          {/* {value.ubd_api_mandateid == null ? <div className="ifsc-verify">Please do mandate </div> : ""} */}
                          <div className="banklist-content">
                            <ul>
                              <li><span>Risk Type</span>{value.pbgs_basket_risk_type}</li>
                              {/* <li><span>IFSC Code</span>{value?.ubd_ifsc}</li>
                              <li><span>Branch Name</span>{value?.ubd_bank_branch}</li> */}
                            </ul> 
                          </div>
                        </div>

                      </div>))}

            </div>
          
          </div>
        </section>
      </BrowserView>
      <MobileView>
      <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/dashboard") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">All Popular Basket</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
        <section className="sec-pad-lg">
          <div className="container">
            <div className={'row g-3'}>

           {popularBasketData.map((value)=>( 
            <div className="col-lg-4" onClick={() => { navigate(`/popularbasketdetails/${value?.pbgs_id}${goalID ? '/'+goalID : ''}`) }} >
                        <div className="banklist">
                          <div className="banklist-header">
                            <div className="dflex align-items-center">
                              <div className="banklist-icon">
                                <img src= {value.pbgs_media != null ?popularBasketBaseUrl + value.pbgs_media:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img>
                              </div>
                              <div className="banklist-name ms-3">
                                <h6 className="mb-0 tx-uppercase">{value.pbgs_basket_name}</h6>
                                {/* <p className="mb-0 tx-green">Primary Bank</p> */}
                              </div>
                            </div>
                          
                          </div>
                          {/* {value.ubd_api_mandateid == null ? <div className="ifsc-verify">Please do mandate </div> : ""} */}
                          <div className="banklist-content">
                            <ul>
                              <li><span>Risk Type</span>{value.pbgs_basket_risk_type}</li>
                              {/* <li><span>IFSC Code</span>{value?.ubd_ifsc}</li>
                              <li><span>Branch Name</span>{value?.ubd_bank_branch}</li> */}
                            </ul> 
                          </div>
                        </div>

                      </div>))}

            </div>
          
          </div>
        </section>
      </MobileView>
        
    
        </>
    )
}
export default PopularBasket