import React from 'react'
// import { CircularProgressbar } from 'react-circular-progressbar';
import RadialSeparators from "./RadialSeprators";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
function CustomcircularProgressBar({ percent,color,width,height }) {
   
  return (
    <div style={{ width: width, height: height,margin:"0 auto 20px" }}>
  {/* <CircularProgressbar 
    percent={percent} 
    filledBackground="linear-gradient(to right, #304AA6)"
  >
  </CircularProgressbar > */}


  <CircularProgressbarWithChildren
        value={percent?percent:0}
        // text={`${percent?percent:0}%`}
        strokeWidth={10}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: color,

        })}
      >
        <RadialSeparators
          count={12}
          style={{
            background: "#fff",
            width: "2px",
            // This needs to be equal to props.strokeWidth
            height: `${10}%`
          }}
        />
          <div style={{ color: "#324dad",fontSize: "20px" }}>
    {`${percent ? percent : 0}%`}
  </div>
      </CircularProgressbarWithChildren>
    </div>
  
  )
}

export default CustomcircularProgressBar


