import React, { useEffect , useRef , useState} from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserView, MobileView } from "react-device-detect";
import Skeleton from 'react-loading-skeleton';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import SweetAlert from 'react-bootstrap-sweetalert';
let apiServices = new ApiService();
function Investmentredeem(){
    const navigate = useNavigate()
    const { id } = useParams()
    const didMountRef = useRef(true)
    const [switchData, setSwitchData] = useState({})
    const [showSkeleton, setShowSkeleton] = useState(false)
    const [step, setStep] = useState(1)
    const [folioNumber, setFolioNumber] = useState([])
    const [FolioNumberSelect, setFolioNumberSelect] = useState("");
    const [redeemFoiloData, SetRedeemFoiloData] = useState({})
    const [RedeemType, SetRedeemType] = useState('A');
    const [RedeemVolume, SetRedeemVolume] = useState("");
    const [amcData , setAmcData] = useState({})
    const [bankData , setbankData] = useState([])
    const [imageUrl , setImageUrl] = useState("")
    const [bankSelect , setBankSelect] =useState("")
    const [amount , setAmount] = useState("")
    const [otp ,setOtp] = useState("")
    const [counter, setCounter] = React.useState(0);
    const [showOtpScreen , setshowOtpScreen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [folioMobileNumber , setFolioMobileNumber] = useState("")
    const [folioEmail , setFolioEmail] = useState("")
    const [show, setShow] = useState(false)
    const [warningShow, setWarningShow] = useState(false)
    const[matchedOTPMessage , setMatchedOTPMessage] = useState("")
    const [tempOtpId , setTempOtpId] = useState("")
    useEffect(()=>{
        if (didMountRef.current) {
            userBankData()
         
            setShowSkeleton(true)
            const dataString = {
                id: id
            }
            apiServices.getredeemfoliospostrequest(dataString).then(res => {
                if (res.data.status == "success") {
                    setSwitchData(res.data.redeemfunddata)
                    setFolioNumber(res.data.redeemfolio)
                    if(res.data.mfdata){
                    setAmcData(res.data.mfdata)
                    }
                    setImageUrl(res.data.imgeURL)
                    setShowSkeleton(false)
                }
                else{
                    setShowSkeleton(false)  
                    toast.error(res.data.message)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const saveRedeemData=()=>{
        if(otp == ""){
            toast.error("OTP is Required")
            return false;
        }
        // setIsLoading(true)
        var redeemtype = ""
        if(RedeemType == 'A'){
            redeemtype = "ALL UNITS"
        }
        else if(RedeemType == 'U'){
            redeemtype = "UNITS"
        }
        else if(RedeemType == 'AM'){
            redeemtype = "AMOUNT"

        }
        setIsLoading(true)
      const dataString1={
        otp : otp,
        temp_otp_id : tempOtpId
      }
      apiServices.verifyotpredeemtransactionpostrequest(dataString1).then(res=>{
        if(res.data.status == "success"){
        const dataString={
            mf_id : id,
            folio_number : FolioNumberSelect ,
            bank_id : bankSelect,
            trans_all_unit : RedeemVolume,
            trans_switch_unit:RedeemType,
            trans_amount: amount,
            switchredeemtype: redeemtype,
            temp_otp_id : tempOtpId
        }
        
        apiServices.createbseRedeemorderpostrequest(dataString).then(res=>{
            if(res.data.status == "success"){
                setMatchedOTPMessage(res.data.message)
                setIsLoading(false)
                setShow(true)

            }
            else if(res.data.status == "error"){
                setMatchedOTPMessage(res.data.message)
                setIsLoading(false)
                setWarningShow(true)
            }
        })
    }
    else{
        toast.error(res.data.message)
        setIsLoading(false)
    }
})
    }
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const userBankData =()=>{
        apiServices.userBankDatapostrequest({}).then(res=>{
            if(res.data.status == "success"){
                setbankData(res.data.userBankData)
                if(res.data.userBankData.length > 0){
                setBankSelect(res.data.userBankData[0].ubd_id)
            }
            }

        })
    }
    const gotostep5=()=>{
       
        if(FolioNumberSelect == ""){
            toast.error("Folio Number is Required")
            return false
          }

       if(redeemFoiloData.totalunits <0){
        toast.error("Units are not available")
        return false
       }   
       
          setStep(2)
    }

    const redeemfundData = (foliono) => {       
        setFolioNumberSelect(foliono);
        const dataString = {
            "folio": foliono,
            "id":id
        }
        apiServices.redeemfundDatapostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                SetRedeemFoiloData(result.data.data)
            } else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
            }
            else {
                alert(result.data.message)
            }
        })
    }
const sendOTP=()=>{    
    if(RedeemType == 'AM' && amount == ""){
        toast.error("Amount is Required")
        return false
    }
    if(RedeemType == 'U' && RedeemVolume == ""){
        toast.error("Unit is Required")
        return false
    }
   
    setIsLoading(true)
    const dataString={
        folionumber : FolioNumberSelect,
        type : "Redeem"
    }
    apiServices.sendotpredeemtransactionpostrequest(dataString).then(res=>{
        if(res.data.status == "success"){
            setshowOtpScreen(true)
            if(res.data.mobilenumber !== "" && res.data.mobilenumber !== null){
            const maskedNumber = "X".repeat(res.data.mobilenumber.length - 4) + res.data.mobilenumber.slice(-4);         
            setFolioMobileNumber(maskedNumber)
            }
            else{
                setFolioMobileNumber("") 
            }
        
            if(res.data.email !== "" && res.data.email !== null){
            const maskedEmail = "X".repeat(res.data.email.length - 15) + res.data.email.slice(-15);
           
            setFolioEmail(maskedEmail)
           
        }
        else{
              setFolioEmail("")
        }
            setCounter(30);
            setIsLoading(false)
            setTempOtpId(res.data.temp_id)
        }
        else{
            toast.error(res.data.message)
            setIsLoading(false)
        }

    })
}
const handleOkClick = () => {
    setShow(false);
    navigate("/order")

}

const handlewarningOkClick = () => {
    setWarningShow(false);
        window.location.reload()
};

const resendOTP=()=>{
    setIsLoading(true)
    const dataString={
        folionumber : FolioNumberSelect,
         type : "Redeem"
    }
    apiServices.resendredeemotppostrequest(dataString).then(res=>{
        if(res.data.status == "success"){
            setTempOtpId(res.data.temp_id)
            setCounter(30);
            setIsLoading(false)
        }
        else{
            toast.error(res.data.message)
            setIsLoading(false)
        }

    })
}
    return(
        <>
          <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <BrowserView>
        <Header/>
        <div className="subheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <h1>Investment Redeem</h1>
                    <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active href="">Investment Redeem</Breadcrumb.Item>
              </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
        <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        {showSkeleton == false ?
                                            <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <img src={amcData.amcdata != null && amcData.amcdata.amc_image !== "" && amcData.amcdata.amc_image !== null ? imageUrl + amcData.amcdata.amc_image : "/img/defaltimage.png"} /> 
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{switchData.pg_schemename}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{switchData.pg_type}</li></ul></div>
                                                </div>
                                            </div>
                                            : <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <Skeleton width="100%" height="59px" />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <Skeleton width="300px" height="10px" />
                                                    <Skeleton width="150px" height="10px" />
                                                </div>
                                            </div>}
                                         {step==2  ?<> <div class="fundboxClose"><i class="icon-cross2"   onClick={() => {
          setStep(1);
          SetRedeemType("");
          SetRedeemVolume("");
          setBankSelect("");
          SetRedeemFoiloData("")
      
        }}></i></div></>:""}
                                       {/* {step== 4 ?<> <div class="fundboxClose"><i class="icon-cross2" onClick={()=>{clearLastStep()}}></i></div></>:""} */}
                                    </div>
                                    <div className="fundbox-body">
                                      
                                        {step == 1 ?
                                          amcData.mf_sip_flg == 'Y' ?
                                          <div className="funform">
                                              <div className="funform-inner">
                                                  <div className="funform-group">
                                                      <div className='col-lg-12'>
                                                          <div className="from-group mb-4">
                                                              <label className="tx-gray">Select Folio Number</label>
                                                              <select onChange={(e) => redeemfundData(e.target.value)}
                                                                  name="goal_type"
                                                                  className="custom-select">
                                                                  <option value={``}>SELECT FOLIO</option>
                                                                
                                                                  {folioNumber.map((value) => (
                                                                      <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                  ))}
                                                              </select>
                                                          </div>
                                                      </div>
                                                      {redeemFoiloData.valueatnav && redeemFoiloData.totalunits?
                                                      <div className="col-lg-12">
                                                          <table className="table table-bordered">
                                                              <tr>
                                                                  <th>Current Holdings</th>
                                                                  <td className='tx-center'>{redeemFoiloData.valueatnav}</td>
                                                              </tr>
                                                              <tr>
                                                                  <th>Units Available</th>
                                                                  <td className='tx-center'>{redeemFoiloData.totalunits}</td>
                                                              </tr>
                                                          </table>
                                                      </div>:""}
                                                  </div>
                                              </div>
                                             
                                              <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep5}>CONTINUE</button>
                                          </div>
                                          :<div className="d-flex justify-content-center">
                                          <img src="/img/notallowed.webp" style={{width:"80px"}} height="100px"></img>
                                        </div>
                                          
                                            : ""}
                                             {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Redeem</label>
                                                                <select onChange={(e) => SetRedeemType(e.target.value)}
                                                                    className="custom-select">
                                                                    <option value='U'>Unit</option>
                                                                    <option value='A' selected>All Units</option>
                                                                    <option value='AM'>Amount</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {RedeemType == 'U' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Unit</label>
                                                                    <input type={'number'} onChange={(e) => SetRedeemVolume(e.target.value)} className="custom-select" />
                                                                </div>
                                                            </div> : ""}
                                                        {RedeemType == 'AM' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <input type={'number'} onChange={(e) => setAmount(e.target.value)} className="custom-select" />
                                                                </div>
                                                               
                                                            </div> : ""}
                                                            <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Mandate Banks</label>
                                                                <select  
                                                                    className="custom-select" onChange={(e)=>setBankSelect(e.target.value)} style={{marginRight :"12px" , width:"80%"}} >
                                                                     {bankData.map((value)=>(   
                                                                    <option value={value.ubd_id }>{value.bank.bse_bank_name + value.ubd_acc_no}</option>))}
                                                                </select>
                                                                
                                                                    <img src="/img/upi.png" width="37px" onClick={(e)=>navigate('/bank')}></img>
                                                                  
                                                                  
                                                            </div>
                                                        </div>
                                                         {showOtpScreen == true ?
                                                      
                                                            <div className="col-lg-12">
                                                                <div className="from-group mb-2">
                                                                    <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                    <input type="number" onChange={(e) => { setOtp(e.target.value) }} />
                                                                    {folioMobileNumber !== ""?
                                                                    <p style={{marginBottom:"0px"}}>OTP has been sent on mobile number: {folioMobileNumber}</p>:""}
                                                                    {folioEmail !== "" ?
                                                                    <p>OTP has been sent on email: {folioEmail}</p>:''}
                                                                    {counter === 0 ? (
                                                                        <p className="tx-12 mt-2">
                                                                            Didn't receive OTP? Resend{" "}
                                                                            <span
                                                                                className="tx-theme"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={resendOTP}
                                                                            >
                                                                                (Click Here)
                                                                            </span>
                                                                        </p>
                                                                    ) : (
                                                                        <p className="tx-12 mt-2">
                                                                            Resend OTP in {counter} seconds
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                     : ""}
                                                    </div>
                                                </div>
                                              
                                                        {showOtpScreen == true ?
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={saveRedeemData} disabled={isLoading}>  {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Verify OTP"
                                                )}</button>
                                                : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Send OTP"
                                                )}</button>}
                                            </div>

                                            : ""}
                                              
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        <Footer/>
        </BrowserView>
        <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" >
            <i class="d-icon-arrow-left tx-22" onClick={()=>{navigate("/investment")}}></i>
            <div className="ms-3">
            <h6 className="mb-0">Investment Redeem</h6>
            </div>
            </div>
            </div>
          </div>
        </header>
        <section className="sec-pad-md">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="fundbox">
                                    <div className="fundbox-header">
                                        {showSkeleton == false ?
                                            <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <img src={amcData.amcdata != null && amcData.amcdata.amc_image !== "" && amcData.amcdata.amc_image !== null ? imageUrl + amcData.amcdata.amc_image : "/img/defaltimage.png"} /> 
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <h6>{switchData.pg_schemename}</h6>
                                                    <div class="mfbox-details-meta"><ul><li>{switchData.pg_type}</li></ul></div>
                                                </div>
                                            </div>
                                            : <div className="fundboxdetails">
                                                <div className="fundboxdetails-icon">
                                                    <Skeleton width="100%" height="59px" />
                                                </div>
                                                <div className="fundboxdetails-name">
                                                    <Skeleton width="300px" height="10px" />
                                                    <Skeleton width="150px" height="10px" />
                                                </div>
                                            </div>}
                                         {step==2  ?<> <div class="fundboxClose"><i class="icon-cross2"   onClick={() => {
          setStep(1);
          SetRedeemType("");
          SetRedeemVolume("");
          setBankSelect("");
          SetRedeemFoiloData("")
      
        }}></i></div></>:""}
                                       {/* {step== 4 ?<> <div class="fundboxClose"><i class="icon-cross2" onClick={()=>{clearLastStep()}}></i></div></>:""} */}
                                    </div>
                                    <div className="fundbox-body">
                                      
                                        {step == 1 ?
                                          amcData.mf_sip_flg == 'Y' ?
                                          <div className="funform">
                                              <div className="funform-inner">
                                                  <div className="funform-group">
                                                      <div className='col-lg-12'>
                                                          <div className="from-group mb-4">
                                                              <label className="tx-gray">Select Folio Number</label>
                                                              <select onChange={(e) => redeemfundData(e.target.value)}
                                                                  name="goal_type"
                                                                  className="custom-select">
                                                                  <option value={``}>SELECT FOLIO</option>
                                                                
                                                                  {folioNumber.map((value) => (
                                                                      <option value={value.pg_folio_no}>{value.pg_folio_no}</option>
                                                                  ))}
                                                              </select>
                                                          </div>
                                                      </div>
                                                      {redeemFoiloData.valueatnav && redeemFoiloData.totalunits?
                                                      <div className="col-lg-12">
                                                          <table className="table table-bordered">
                                                              <tr>
                                                                  <th>Current Holdings</th>
                                                                  <td className='tx-center'>{redeemFoiloData.valueatnav}</td>
                                                              </tr>
                                                              <tr>
                                                                  <th>Units Available</th>
                                                                  <td className='tx-center'>{redeemFoiloData.totalunits}</td>
                                                              </tr>
                                                          </table>
                                                      </div>:""}
                                                  </div>
                                              </div>
                                                  
                                              <button type="button" className="btn btn-primary btn-lg btn-full" onClick={gotostep5}>CONTINUE</button>
                                          </div>
                                          :<div className="d-flex justify-content-center">
                                          <img src="/img/notallowed.webp" style={{width:"80px"}} height="100px"></img>
                                        </div>
                                          
                                            : ""}
                                             {step == 2 ?
                                            <div className="funform">
                                                <div className="funform-inner">
                                                    <div className="funform-group">
                                                        <div className='col-lg-12'>
                                                            <div className="from-group mb-4">
                                                                <label className="tx-gray">Redeem</label>
                                                                <select onChange={(e) => SetRedeemType(e.target.value)}
                                                                    className="custom-select">
                                                                    <option value='U'>Unit</option>
                                                                    <option value='A' selected>All Units</option>
                                                                    <option value='AM'>Amount</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {RedeemType == 'U' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Unit</label>
                                                                    <input type={'number'} onChange={(e) => SetRedeemVolume(e.target.value)} className="custom-select" />
                                                                </div>
                                                            </div> : ""}
                                                        {RedeemType == 'AM' ?
                                                            <div className='col-lg-12'>
                                                                <div className="from-group mb-4">
                                                                    <label className="tx-gray">Amount</label>
                                                                    <input type={'number'} onChange={(e) => setAmount(e.target.value)} className="custom-select" />
                                                                </div>
                                                               
                                                            </div> : ""}
                                                            <div className='col-lg-12'>
                                                            <div className="from-group mb-1">
                                                                <label className="tx-gray">Mandate Banks</label>
                                                                <select  
                                                                    className="custom-select" onChange={(e)=>setBankSelect(e.target.value)} style={{marginRight :"12px" , width:"80%"}} >
                                                                     {bankData.map((value)=>(   
                                                                    <option value={value.ubd_id }>{value.bank.bse_bank_name + value.ubd_acc_no}</option>))}
                                                                </select>
                                                                
                                                                    <img src="/img/upi.png" width="37px" onClick={(e)=>navigate('/bank')}></img>
                                                                  
                                                                  
                                                            </div>
                                                        </div>
                                                         {showOtpScreen == true ?
                                                      
                                                            <div className="col-lg-12">
                                                                <div className="from-group mb-2">
                                                                    <label htmlFor="fname">OTP<span className="tx-red">*</span></label>
                                                                    <input type="number" onChange={(e) => { setOtp(e.target.value) }} />
                                                                    {folioMobileNumber !== ""?
                                                                    <p style={{marginBottom:"0px"}}>OTP has been sent on mobile number: {folioMobileNumber}</p>:""}
                                                                    {folioEmail !== "" ?
                                                                    <p>OTP has been sent on email: {folioEmail}</p>:''}
                                                                    {counter === 0 ? (
                                                                        <p className="tx-12 mt-2">
                                                                            Didn't receive OTP? Resend{" "}
                                                                            <span
                                                                                className="tx-theme"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={resendOTP}
                                                                            >
                                                                                (Click Here)
                                                                            </span>
                                                                        </p>
                                                                    ) : (
                                                                        <p className="tx-12 mt-2">
                                                                            Resend OTP in {counter} seconds
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                     : ""}
                                                    </div>
                                                </div>
                                              
                                                        {showOtpScreen == true ?
                                                <button type="button" className="btn btn-primary btn-lg btn-full" onClick={saveRedeemData} disabled={isLoading}>  {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Verify OTP"
                                                )}</button>
                                                : <button type="button" className="btn btn-primary btn-lg btn-full" onClick={sendOTP} disabled={isLoading}> {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Send OTP"
                                                )}</button>}
                                            </div>

                                            : ""}
                                              
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </MobileView>

        <SweetAlert success confirmBtnCssClass='alertpop' confirmBtnText='OK' title={matchedOTPMessage} show={show} onConfirm={handleOkClick}>
            </SweetAlert>
            <SweetAlert danger confirmBtnCssClass='alertpop' confirmBtnText='Ok' title={matchedOTPMessage} show={warningShow} onConfirm={handlewarningOkClick}>
            </SweetAlert>
        </>
    )
}

export default Investmentredeem