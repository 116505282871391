import { useNavigate } from "react-router-dom"

const BottomMenu=()=>{
    const navigate=useNavigate()
    return<>
    
    <div className="bottom-menu">
        <ul>
            <li className={Location == "/dashboard" ? "active" : ""} onClick={() => { navigate("/dashboard") }}>
                <div className="menu-icon"><i className="d-icon-home"></i></div>
                <span>home</span>
            </li>
            <li className={Location == "/investment" ? "active" : ""} onClick={() => { navigate("/investment") }}>
                <div className="menu-icon"><i className="d-icon-database "></i></div>
                <span>Portfolio</span>
            </li>
            <li className={Location == "/mutual-funds" ? "active" : ""} onClick={() => { navigate("/mutual-funds") }}>
                <div className="menu-icon"><i className="d-icon-layer"></i></div>
                <span>Mutual Funds</span>
            </li>
            {/* <li className={Location == "/my-SIPs" ? "active" : ""} onClick={() => { navigate("/my-SIPs") }}>
                <div className="menu-icon"><i className="d-icon-shoppingbag"></i></div>
                <span>My SIPs</span>
            </li> */} 
            <li className={Location == "/my-account" ? "active" : ""} onClick={() => { navigate("/my-account") }}>
                <div className="menu-icon"><i className="d-icon-user"></i></div>
                <span>Account</span>
            </li>
        </ul>
    </div>
    
    
    
    </>
       
    
}

export default BottomMenu