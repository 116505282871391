import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ApiService } from "../../Components/Services/apiServices";
import Chart from 'react-apexcharts'
function Goaldetails(){
    const {id}=useParams()
    const [graphData, setgraphData] = useState([]);
    const [showgraph, Setshowgraph] = useState(false);
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    let apiServices = new ApiService();


    useEffect(() => {
        if (didMountRef.current) {
            showusergraphdata()

        }
        didMountRef.current = false;
    }, []);

    const showusergraphdata = (e) => {

        const usergraphdatastring = {
            "goalid": id,
          }
          apiServices.getgoalgraphdata(usergraphdatastring).then(result => {
            if (result.data.status == 'success') {
              var graphdata = result.data.graphdata[0];
    
              if (graphdata != null) {
                setgraphData(graphdata);
                Setshowgraph(true)
              }
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
              localStorage.removeItem('AUTH_TOKEN');
           
             navigate("/")
            }
            else {
              toast.error(result.data.message)
            
            }
          })
    };

    return(
        <>
        <Header/>
        <div className="subheader">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <h1>Goal details</h1>
                    <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Data</Breadcrumb.Item>
              </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>

        <section className="sec-pad-sm">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="fundbox">
                                <div className="fundbox-header">
                                {graphData.map((key, index) => {
                                const guestSeries = [
                                    {
                                        name: 'Invested',
                                        type: 'bar',
                                        data: key.invested
                                    }, {
                                        name: 'Planned',
                                        type: 'line',
                                        data: key.planned
                                    }, {
                                        name: 'Actual',
                                        type: 'line',
                                        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
                                    }
                                ];
                                const guestOption = {
                                    chart: {
                                        toolbar: {
                                            show: false
                                        },
                                        height: 200,
                                        width: 400,
                                        type: 'bar',
                                        stacked: false,
                                    },
                                    stroke: {
                                        width: [0, 2, 2],
                                        curve: 'smooth'
                                    },
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '50%'
                                        }
                                    },
                                    fill: {
                                        opacity: [0.85, 0.25, 1],
                                        gradient: {
                                            inverseColors: false,
                                            shade: 'light',
                                            type: "vertical",
                                            opacityFrom: 0.85,
                                            opacityTo: 0.55,
                                            stops: [0, 100, 100, 100]
                                        }
                                    },

                                    labels: key.years,
                                    markers: {
                                        size: [0, 2, 0]
                                    },
                                    xaxis: {
                                        tickAmount: 6,
                                    },
                                    yaxis: {
                                        title: {
                                            text: '',
                                        },
                                        tickAmount: 7,
                                        min: 0,
                                    },
                                    tooltip: {
                                        shared: true,
                                        intersect: false,
                                        y: {
                                            formatter: function (y) {
                                                if (typeof y !== "undefined") {
                                                    return y.toFixed(0) + " Rs";
                                                }
                                                return y;
                                            }
                                        }
                                    }
                                };





                                return (<>
                                    <div className="panel mt-3">
                                        <div className="panel-body">
                                            <div className="row g-2">

                                                <div class="col-lg-5 border-right"><h4 class="tx-18">{key.goaldetails.name}</h4>
                                                    <h6 class="tx-13 mg-b-5">{key.goaldetails.type}</h6>
                                                    <div class="goalsetting-list">
                                                        <ul>
                                                            <li>Amount :<span>₹{key.goaldetails.amount}</span></li>
                                                            <li>Current Investment : <span><a href={`/goaldetails/${key.goaldetails.gs_id}`}>₹{key.goaldetails.current}</a></span></li>
                                                            <li>Maturity Year :<span>{key.goaldetails.maturity}</span></li>
                                                            <li>Goal Start Date :<span>{key.goaldetails.datetime}</span></li>
                                                            <li>Folio No :<span>-</span></li>
                                                        </ul>
                                                    </div>
                                                    <a class="mg-t-20 buttonab-line-3" href="">Start Investment  <i class="icon-arrow-right8"></i></a>
                                                </div>
                                                <div className="col-lg-7">
                                                    {/* <ReactApexChart options={guestSeries} series={this.state.series} type="bar" height={350} /> */}
                                                    <Chart type="line" series={guestSeries} options={guestOption} width='100%' />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                                    <div class="fundboxClose" onClick={()=>{navigate("/dashboard")}}><i class="icon-cross2"></i></div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
        
        </>
    )
}

export default Goaldetails