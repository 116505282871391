import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { TailSpin } from "react-loader-spinner";

let apiServices = new ApiService();
function Bseonboardingstep3contForg() {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [burTaxStatus, setBurTaxStatus] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false)

  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      getCountryData();
      getstateData();
setSpinnerLoading(true)
      const dataString = {
      }
      apiServices.bsegetuserdataPostRequest(dataString).then(result => {
        if (result.data.status == 'success') {
          setSpinnerLoading(false)
          setBurTaxStatus(result.data.bse_register_data.bur_tax_status)
          setFormData({
            ...formData,
            email: result.data.data.gs_email,
            mobile: result.data.data.gs_mobile,
            address1: result.data.data.gs_address1,
            city: result.data.data.gs_city,
            pinCode: result.data.data.gs_pin,
            state: result.data.data.gs_state,
          });

        } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
          navigate("/");
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      })
    }
    didMountRef.current = false;
  }, []);


  const handleNextClick = () => {

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const mobileRegex = /^[0-9]{10}$/;
    if (
      !formData.address1 ||
      !formData.email ||
      !formData.mobile ||
      !formData.state ||
      !formData.country ||
      !formData.pinCode ||
      !formData.city
    ) {
      if (!formData.address1) {
        toast.error('Please enter your address.');
      } else if (!formData.city) {
        toast.error('Please enter your city.');
      } else if (!formData.pinCode) {
        toast.error('Please enter your pincode.');
      }
      else if (!formData.state) {
        toast.error('Please enter your state.');
      }
      else if (!formData.country) {
        toast.error('Please enter your country.');
      }
      return;
    } else if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address.');
      return;
    } 
    // else if (!mobileRegex.test(formData.mobile)) {
    //   toast.error('Please enter a valid 10-digit mobile number.');
    //   return;
    // }

    const dataString = {
      addr1: formData.address1,
      city: formData.city,
      pin: formData.pinCode,
      state: formData.state,
      country: formData.country,
      email: formData.email,
      mobile: formData.mobile,
      communicationMode: formData.communicationMode,
      emaildeclarationflag: formData.emailDeclarationFlag,
      mobiledeclarationflag: formData.mobileDeclarationFlag,

    };


    apiServices.bseuseraddressPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        navigate('/bseonboarding/step4');
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
      }
    })

      .catch(error => {
        console.error("API Request Error:", error);
      })

    console.log("Form Data:", formData);

  };


  const getCountryData = (e) => {
    apiServices.getbsecountryGetRequest().then(result => {
      if (result.data.status == "success") {
        setCountryData(result.data.data);
      }
    })
  }

  const getstateData = (e) => {
    apiServices.getbsestatesGetRequest().then(result => {
  
      if (result.data.status == "success") {
        setStatesData(result.data.data);
      }
    })
  }

  const handleBackClick = () => {
    navigate("/bseonboarding/step3forg");
  };


  const [formData, setFormData] = useState({
    address1: '',
    address2: '',
    city: '',
    pinCode: '',
    state: '',
    country: 'IN',
    communicationMode: 'E',
    email: '',
    mobile: '',
    emailDeclarationFlag: 'SE',
    mobileDeclarationFlag: 'SE',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'mobile') {
      if (value.length <= 10) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === 'address1' || name === 'address2' || name === 'city' || name === 'pinCode') {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  return (
    <>
 <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div className="lrsection">

          <div className="lrsection-content">
            <div>
              <img src="/img/logo.png" className="wd-150 mb-4"></img>
              <h2>Goal based Investment Platform</h2>
              <h4 className="tx-theme-secondary">Intelligent. Disciplined. Smart</h4>
              <p>Every financial goal can be achieved through disciplined approach and smart technology. You really don’t need fancy offerings or jargons.</p>
              <ul className="features-list mt-4"><li>Minimum Documents</li><li>Goal based Investment </li><li>Quick Onboarding</li></ul>
            </div>
          </div>

          <div className="lrsection-form">
          <div className="backbutton-desktop" onClick={handleBackClick} ><i class="icon-arrow-left8 wd-20 "></i></div>
            <CustomProgressBar percent={56} />
            <div className="loginbox">

              <div className='mb-4'>
                <p>Step 4 of 7</p>

              </div>
              <div className="mb-4">
                <h5>Communication Details(Foreign) cont..</h5>
              </div>


              <div className="row g-2">

                <div className="col-lg-12">
                  <div className="from-group mb-3">
                    <label>Communication Mode<span className="tx-red">*</span></label>
                    <select
                      name="communicationMode"
                      value={formData.communicationMode}
                      className="custom-select"
                      onChange={handleInputChange}
                    >
                      <option value="E">EMAIL</option>
                      <option value="P">PHYSICAL</option>
                      <option value="M">MOBILE</option>

                    </select>
                  </div>
                </div>

              </div>

              <div className="row g-2">

                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Email ID<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="email"
                      placeholder=""
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Mobile<span className="tx-red">*</span></label>
                    <input
                      type="number"
                      name="mobile"
                      placeholder=""
                      value={formData.mobile}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row g-2">
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Email Belongs To</label>
                    <select
                      name="emailDeclarationFlag"
                      value={formData.emailDeclarationFlag}
                      className="custom-select"
                      onChange={handleInputChange}
                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>

                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Mobile Belongs To</label>

                    <select
                      name="mobileDeclarationFlag"
                      className="custom-select"
                      value={formData.mobileDeclarationFlag}
                      onChange={handleInputChange}
                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>

                    </select>
                  </div>
                </div>
              </div>
                <button className="btn btn-primary btn-lg btn-full mt-3 " onClick={handleNextClick}>
                  Next
                </button>
            </div>
          </div>
        </div>
      </BrowserView>


      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" onClick={handleBackClick}>
            <i class="icon-arrow-left8"></i>
            <div className="ms-3">
            <h6 className="mb-0">  Communication Details (India) cont..</h6>
            <p className="tx-12 mb-0 line18">Step 4 of 7</p>
            </div>
            </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={56} />
            <div className="p15 mt-3 mb-5 pb-5">
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="from-group mb-3">
                    <label>Communication Mode<span className="tx-red">*</span></label>
                    <select
                      name="communicationMode"
                      className="custom-select"
                      value={formData.communicationMode}
                      onChange={handleInputChange}
                    >
                      <option value="E">EMAIL</option>
                      <option value="P">PHYSICAL</option>
                      <option value="M">MOBILE</option>

                    </select>
                  </div>
                </div>

              </div>

              <div className="row g-2">

                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Email ID<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="email"
                      placeholder=""
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Mobile<span className="tx-red">*</span></label>
                    <input
                      type="number"
                      name="mobile"
                      placeholder=""
                      value={formData.mobile}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row g-2">
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Email Belongs To</label>
                    <select
                      name="emailDeclarationFlag"
                      className="custom-select"
                      value={formData.emailDeclarationFlag}
                      onChange={handleInputChange}

                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>

                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Mobile Belongs To</label>

                    <select
                      name="mobileDeclarationFlag"
                      className="custom-select"
                      value={formData.mobileDeclarationFlag}
                      onChange={handleInputChange}
                    >
                      <option value="SE">Self</option>
                      <option value="SP">Spouse</option>
                      <option value="DC"> Dependent Children </option>
                      <option value="DS"> Dependent Siblings</option>
                      <option value="DP">Dependent Parents</option>
                      <option value="GD"> Guardian</option>
                      <option value="PM">PMS</option>
                      <option value="CD">Custodian</option>
                      <option value="PO">POA</option>
                    </select>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="footer-button">
          
           
            <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick}>
              Next
            </button>
          
        </div>
      </MobileView>

    </>

  )
}

export default Bseonboardingstep3contForg
