import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { TailSpin } from "react-loader-spinner";
let apiServices = new ApiService();
function Bseonboardingstep1minor() {
  const navigate = useNavigate();
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    pan: "",

  });

  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {

    setSpinnerLoading(true)
      const dataString = {
      }
      apiServices.bsegetuserdataPostRequest(dataString).then(result => {
        if (result.data && result.data.status === 'success') {
          const bseMinorData = result.data.bse_minor_data;
          setSpinnerLoading(false)
          if (bseMinorData) {
            setFormData({
              ...formData,
              firstname: bseMinorData.gs_minor_first_name || '',
              middlename: bseMinorData.gs_minor_middle_name || '',
              lastname: bseMinorData.gs_minor_last_name || '',
              pan: bseMinorData.gs_minor_guardian_pan || '',
            });
          }
        } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
          navigate("/");
          setSpinnerLoading(false)
          localStorage.removeItem('AUTH_TOKEN');
        }
      })

    }
    didMountRef.current = false;
  }, []);


  const handleNextClick = () => {
    if (
      !formData.firstname

    ) {
      if (!formData.firstname) {
        toast.error('Please enter your name.');
        return;
      }

    }

    const dataString = {
      first_name: formData.firstname,
      middle_name: formData.middlename,
      last_name: formData.lastname,
      guardian_pan: formData.pan,



    };

    apiServices.bseregistrationminorPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        navigate('/bseonboarding/step2');
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
      }
    })

      .catch(error => {
        console.error("API Request Error:", error);
      })

    console.log("Form Data:", formData);

  };


  const handleBackClick = () => {
    window.location.href = "/bseonboarding/0";
  };





  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
        <div className="lrsection">

          <div className="lrsection-content">
            <div>
              <img src="/img/logo.png" className="wd-150 mb-4"></img>
              <h2>Goal based Investment Platform</h2>
              <h4 className="tx-theme-secondary">Intelligent. Disciplined. Smart</h4>
              <p>Every financial goal can be achieved through disciplined approach and smart technology. You really don’t need fancy offerings or jargons.</p>
              <ul className="features-list mt-4"><li>Minimum Documents</li><li>Goal based Investment </li><li>Quick Onboarding</li></ul>
            </div>
          </div>

          <div className="lrsection-form">
            <CustomProgressBar percent={50} />
            <div className="loginbox">

              <div className='mb-4'>
                <p>Step 2 of 7</p>

              </div>
              <div className="mb-4">
                <h5>Minor Details</h5>
              </div>

              <div className="row g-2">
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Guardian First Name<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Guardian Middle Name	</label>
                    <input
                      type="text"
                      name="middlename"
                      value={formData.middlename}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row g-2">

                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Guardian Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Guardian PAN</label>
                    <input
                      type="text"
                      name="pan"
                      value={formData.pan}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <button className="btn btn-primary btn-lg btn-full me-2 " onClick={handleBackClick}>
                  Back
                </button>
                <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick}>
                  Next
                </button>
              </div>

            </div>
          </div>
        </div>
      </BrowserView>


      <MobileView>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
       
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" onClick={handleBackClick}>
            <i class="icon-arrow-left8"></i>
            <div className="ms-3">
            <h6 className="mb-0">Minor Details</h6>
            <p className="tx-12 mb-0 line18">Step 2 of 7</p>
            </div>
            </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
        <div className="mobile-form">
          <CustomProgressBar percent={50} />
          <div className="p15 mt-3 mb-5 pb-5">
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Guardian First Name<span className="tx-red">*</span></label>
                  <input
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Guardian Middle Name	</label>
                  <input
                    type="text"
                    name="middlename"
                    value={formData.middlename}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="row g-2">

              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Guardian Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Guardian PAN</label>
                  <input
                    type="text"
                    name="pan"
                    value={formData.pan}
                    onChange={handleInputChange}
                  />
                </div>
              </div>


            </div>



        
          </div>
        </div>
        </div>
        <div className="footer-button">
        <div className='d-flex'>
              {/* <button className="btn btn-primary btn-lg btn-full me-2 " onClick={handleBackClick}>
                Back
              </button> */}
              <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick}>
                Next
              </button>
            </div>

        </div>
       
      </MobileView>

    </>

  )
}

export default Bseonboardingstep1minor
