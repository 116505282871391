import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { PostData } from "../../Components/Services/PostData";
import { ApiService } from "../../Components/Services/apiServices";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constant from "../../Components/Services/constant";
let apiServices = new ApiService();

function Register() {
  const history = useNavigate()
  const [counter, setCounter] = React.useState(0);
  const [countermob, setCountermob] = React.useState(0);
  const [registerData, setRegisterData] = useState({
    user_email: "",
    email_otp: "",
    mobile_no: "",
    mobile_otp: "",
    cnfpassword: "",
    cnfpassword: "",
    agentcode: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [salcode, setsalcode] = useState(0);
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {};
      apiServices.getUserDataPostRequest(dataString).then((res) => {
        if (res.data.status == "success") {
          if (res.data.data.gs_status == 0) {
            setIsLoading(false);
            navigate("/completeprofile");
          } else if (res.data.data.gs_can_register == 0) {
            setIsLoading(false);
            navigate("/canregister");
          } else if (res.data.data.gs_can_register == 5) {
            setIsLoading(false);
            navigate("/canregister/5");
          } else if (res.data.data.gs_investor_concent_status == 0) {
            setIsLoading(false);
            navigate("/investorconcent");
          } else {
            setIsLoading(false);
            navigate("/dashboard");
          }
        }
      });
    }
    didMountRef.current = false;
  });
  React.useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setseconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
        } else {
          setseconds(59);
          setminutes(minutes - 1);
        }
      }
    }, 1000);
  });
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  React.useEffect(() => {
    countermob > 0 && setTimeout(() => setCountermob(countermob - 1), 1000);
  }, [countermob]);
  function checkminchar(e) {
    var a = document.getElementById("minchar");
    if (e.length > 8) {
      a.setAttribute("class", "mb-0 tx-11 text-success");
      return true;
    } else {
      a.setAttribute("class", "mb-0 tx-11 text-danger");
      return false;
    }
  }
  function checkcapsmall(e) {
    var cap = document.getElementById("capsmall");
    var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$");
    if (e.match(pattern)) {
      cap.setAttribute("class", "mb-0 tx-11 text-success");
      return true;
    } else {
      cap.setAttribute("class", "mb-0 tx-11 text-danger");
      return false;
    }
  }
  function checkspecialchar(e) {
    var special = document.getElementById("specialchar");
    var pattern2 = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
    if (e.match(pattern2)) {
      special.setAttribute("class", "mb-0 tx-11 text-success");
      return true;
    } else {
      special.setAttribute("class", "mb-0 tx-11 text-danger");
      return false;
    }
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const firstStep = (e) => {
    e.preventDefault();
    if (registerData.user_email == "") {
      toast("Email Address cannot Be Empty");
      return false;
    } else if (!isValidEmail(registerData.user_email)) {
      toast("Please Enter Valid Email Address");
      return false;
    } else {
      setErrorMessage("");
      setIsLoading(true);
      const dataString = {
        user_email: registerData.user_email,
        step: 1,
      };
      apiServices.bseregisterPostRequest(dataString)
        .then((result) => {
          console.log(result);
          if (result.data.status == "success") {
            // localStorage.setItem('user', JSON.stringify(res.data.data));
            setIsLoading(false);
            setStep1(false);
            setStep2(true);
            setStep3(false);
            setStep4(false);
            setStep5(false);
            setUserId(result.data.auth);
            toast(result.data.message);
            setCounter(30);
          } else {
            toast(result.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => { });
    }
  };
  const resendotp = (e) => {
    e.preventDefault();
    const dataString = {
      id: userId,
      type: "email",
    };
    apiServices
      .bseresendotpPostRequest(dataString)
      .then((result) => {
        if (result.data.status == "success") {
          setCounter(30);
          setminutes(10);
          setseconds(0);
          return false;
        } else {
          toast(result.data.message);
        }
        setShowLoading(false);
      })
      .catch((error) => { });
  };
  const resendotpmobile = (e) => {
    e.preventDefault();
    const dataString = {
      id: userId,
      type: "mobile",
    };
    apiServices
      .bseresendotpPostRequest(dataString)
      .then((result) => {
        if (result.data.status == "success") {
          setCountermob(30);
          setminutes(10);
          setseconds(0);
          return false;
        } else {
          toast(result.data.message);
        }
        setShowLoading(false);
      })
      .catch((error) => { });
  };
  const backToEmail = (e) => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setCounter(0);
  };
  const backToMobile = (e) => {
    setStep1(false);
    setStep2(false);
    setStep3(true);
    setStep4(false);
    setStep5(false);
    setCountermob(0);
  };
  const secondStep = (e) => {
    e.preventDefault();
    if (registerData.email_otp == "") {
      toast("Pleast Enter OTP");
    } else {
      setErrorMessage("");
      setIsLoading(true);
      const dataString = {
        otp: registerData.email_otp,
        id: userId,
        step: 2,
      };
      apiServices
        .bseregisterPostRequest(dataString)
        .then((result) => {
          if (result.data.status == "success") {
            // localStorage.setItem('user', JSON.stringify(res.data.data));
            setIsLoading(false);
            setStep1(false);
            setStep2(false);
            setStep3(true);
            setStep4(false);
            setStep5(false);
          } else {
            toast(result.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => { });
    }
  };
  const thirdStep = (e) => {
    e.preventDefault();

    if (registerData.mobile_no == "") {
      toast("Pleast Enter Mobile Number");

      return false;
    } else if (registerData.mobile_no.length != 10) {
      toast("Pleast Enter 10 Digit Mobile Number");
      return false;
    } else {
      setIsLoading(true);
      setErrorMessage("");
      const dataString = {
        mobile_no: registerData.mobile_no,
        id: userId,
        step: 3,
      };
      apiServices
        .bseregisterPostRequest(dataString)
        .then((result) => {
          if (result.data.status == "success") {
            setIsLoading(false);
            setStep1(false);
            setStep2(false);
            setStep3(false);
            setStep4(true);
            setStep5(false);
            toast(result.data.message);
            setCountermob(30);
          } else {
            toast(result.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => { });
    }
  };
  const forthStep = (e) => {
    e.preventDefault();
    if (registerData.mobile_otp == "") {
      setErrorMessage("Pleast Enter Mobile OTP");
      toast("Pleast Enter Mobile OTP");

      return false;
    } else {
      setErrorMessage("");
      setIsLoading(true);
      const dataString = {
        mobile_otp: registerData.mobile_otp,
        id: userId,
        step: 4,
      };
      PostData("bse/register", dataString).then((result) => {
        if (result.status == "success") {
          setIsLoading(false);
          setStep1(false);
          setStep2(false);
          setStep3(false);
          setStep4(false);
          setStep5(true);
        } else {
          toast(result.message);
          setIsLoading(false);
        }
      });
    }
  };
  const setsalescode = (e) => {
    if (salcode == 0) {
      setsalcode(1);
    } else {
      setsalcode(0);
    }
  };
  const fifthStep = (e) => {
    e.preventDefault();

    if (registerData.password == "" || registerData.cnfpassword == "") {
      toast("Please Enter The Credentials");

      return false;
    } else if (registerData.password != registerData.cnfpassword) {
      toast("Password and Confirm Password is incorrect");
      return false;
    } else {
      setIsLoading(true);
      if (
        checkspecialchar(registerData.password) &&
        checkcapsmall(registerData.password) &&
        checkminchar(registerData.password)
      ) {
        setErrorMessage("");
        const dataString = {
          id: userId,
          confirm_password: registerData.cnfpassword,
          password: registerData.password,
          agentcode: registerData.agentcode,
          step: 5,
        };
        apiServices
          .bseregisterPostRequest(dataString)
          .then((result) => {
            if (result.data.status == "success") {
              localStorage.setItem("AUTH_TOKEN", result.data.auth_token);
              setIsLoading(false);
              setStep1(false);
              setStep2(false);
              setStep3(false);
              setStep4(false);
              setStep5(true);
              toast(result.data.message);
              setTimeout(() => {
                navigate("/completeprofile");
              }, 2000);
            } else {
              toast(result.data.message);
              setIsLoading(false);
            }
          })
          .catch((error) => { });
      } else {
        toast("Please Fullfill All Password Conditions");
        setIsLoading(false);
      }
    }
  };
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setRegisterData({ ...registerData, [key]: value });
    if (key == "password") {
      checkspecialchar(value);
      checkcapsmall(value);
      checkminchar(value);
    }
  };
  return (
    <>
      <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center">
                {step1 == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Register With Goalstox</h5>
                          <p>or check your application status</p>
                        </div>
                        <div className="from-group mb-20">

                          <label>Email Address<span className="text-danger fs-5">*</span></label>
                          <input
                            type="email"
                            name="user_email"
                            onChange={handleInput}

                          ></input>
                          <p className="tx-12 mt-2">
                            We will send you OTP on your Email Id.
                          </p>
                        </div>
                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={firstStep}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Send OTP"
                          )}
                        </button>
                        <p className="tx-12 tx-gray text-center">
                          By clicking Create an account below, you agree to our{" "}
                          <span className="tx-theme">
                            <a href={constant.FRONT_URL + "terms-conditions"} target="_blank">
                              terms of service
                            </a>
                          </span>{" "}
                          and{" "}
                          <span className="tx-theme">
                            <a href={constant.FRONT_URL + "privacy-policy"} target="_blank">
                              privacy statement
                            </a>
                          </span>
                        </p>
                        <p className="mb-0 text-center">
                          Already have an account?{" "}
                          <span
                            className="tx-theme"
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                          >
                            Login
                          </span>
                        </p>

                      </div>
                    </div>
                  </div>
                </> : ""}
                {step2 == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Verify Email Address</h5>
                          <p className="tx-gray tx-12 ms-2">
                            Otp has been sent to your email id. {registerData.user_email}
                            <span
                              className="tx-theme ms-1"
                              style={{ cursor: "pointer", marginLeft: '3px' }}
                              onClick={backToEmail}
                            >
                              (Change)
                            </span>
                          </p>
                        </div>
                        <div className="from-group mb-20">

                          <label>
                            Enter OTP <span className="text-danger fs-5">*</span>
                          </label>
                          <input
                            type="number"
                            name="email_otp"
                            onChange={handleInput}
                          ></input>
                          {counter === 0 ? (
                            <p className="tx-12 mt-2">
                              Didn't receive OTP? Resend{" "}
                              <span
                                className="tx-theme"
                                style={{ cursor: "pointer" }}
                                onClick={resendotp}
                              >
                                (Click Here)
                              </span>
                            </p>
                          ) : (
                            <p className="tx-12 mt-2">
                              Resend OTP in {counter} seconds
                            </p>
                          )}
                        </div>
                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={secondStep}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Continue"
                          )}
                        </button>

                      </div>
                    </div>
                  </div>
                </> : ""}
                {step3 == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">

                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>What's your Mobile Number?</h5>
                        </div>
                        <div className="from-group country-input mb-20">

                          <label> Mobile Number<span className="text-danger fs-5">*</span></label>
                          <span className="country-code">+91</span>
                          <input
                            type="number"
                            name="mobile_no"
                            onChange={handleInput}
                          ></input>
                          <p className="tx-12 mt-2">
                            We will send you OTP on your Mobile.
                          </p>
                        </div>
                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={thirdStep}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </> : ""}
                {step4 == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Verify Mobile Number</h5>
                          <p className="tx-gray tx-12 ">
                            Otp has been sent to your mobile number {registerData.mobile_no}
                            <span
                              onClick={backToMobile}
                              className="tx-theme ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              (Change)
                            </span>
                          </p>
                        </div>
                        <div className="from-group  mb-20">
                          <label>
                          Verify OTP <span className="text-danger fs-5">*</span>
                          </label>
                          <input
                            type="number"
                            name="mobile_otp"
                            onChange={handleInput}
                          ></input>
                        </div>
                        {countermob === 0 ? (
                          <p onClick={resendotpmobile} className="tx-12 mt-2">
                            Didn't receive OTP? Resend{" "}
                            <span className="tx-theme" style={{ cursor: "pointer" }}>
                              (Click Here)
                            </span>
                          </p>
                        ) : (
                          <p className="tx-12 mt-2">
                            Resend OTP in {countermob} seconds
                          </p>
                        )}
                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={forthStep}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </> : ""}
                {step5 == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Create Your Unique Password</h5>
                          <p className="tx-gray">Please enter your password</p>
                        </div>
                        <div className="from-group  mb-20">

                          <label>Password<span className="text-danger fs-5">*</span></label>
                          <input
                            type="password"
                            name="password"
                            onChange={handleInput}
                          ></input>
                        </div>
                        <div className="from-group mb-20">
                          <label>Confirm Password<span className="text-danger fs-5">*</span></label>
                          <input
                            type="password"
                            name="cnfpassword"
                            onChange={handleInput}
                          ></input>
                        </div>

                        <p id="minchar" className="tx-11 tx-gray mb-0">
                          Minimum 8 characters
                        </p>
                        <p id="capsmall" className="tx-11 tx-gray mb-0">
                          Have atleast 1 capital and 1 small alphabet
                        </p>
                        <p id="specialchar" className="tx-11 tx-gray mb-0">
                          Have one of the following special characters @,#,$,-,_,%,&,!,?
                        </p>

                        <button
                          className="btn btn-primary btn-lg btn-full mb-4 mt-3"
                          onClick={fifthStep}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </> : ""}
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        {step1 == true ? (
          <div className="p25 mt-5">
            <div className="backbutton" onClick={() => { history("/") }} ><img src="img/back.png" className="wd-20" /></div>
            <div className="mb-4 text-center">
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <h6>Register With Goalstox</h6>
              <p className="tx-gray tx-12">or check your application status</p>
            </div>
            <div className="from-group mb-3">
              <label>Email Address<span className="text-danger fs-5">*</span></label>
              <input
                type="email"
                name="user_email"
                onChange={handleInput}
                placeholder="yourname@yourmail.com"
              ></input>
              <p className="tx-12 mt-2">We will send you OTP on your Email Id.</p>
            </div>
            <button
              className="btn btn-primary btn-lg btn-full mb-4"
              onClick={firstStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Send OTP"
              )}
            </button>
            <p className="tx-12 tx-gray text-center">
              By clicking Create an account below, you agree to our{" "}
              <span className="tx-theme">
                <a href={constant.FRONT_URL + "terms-conditions"} target="_blank">
                  terms of service
                </a>
              </span>{" "}
              and{" "}
              <span className="tx-theme">
                <a href={constant.FRONT_URL + "privacy-policy"} target="_blank">
                  privacy statement
                </a>
              </span>
            </p>
            <p className="mb-0 text-center">
              Already have an account?{" "}
              <span
                className="tx-theme"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                Login
              </span>
            </p>
          </div>
        ) : (
          ""
        )}
        {step2 == true ? (
          <div className="p25 mt-5">
            <div className="backbutton" onClick={() => { setStep2(false); setStep1(true) }} ><img src="img/back.png" className="wd-20" /></div>
            <div className="mb-4 text-center">
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <div className="mb-4">
                <h6>Verify Email Address</h6>
                <p className="tx-gray tx-12 ms-2">
                  Otp has been sent to your  email id {registerData.user_email}
                  <span
                    className="tx-theme ms-1"
                    style={{ cursor: "pointer", marginLeft: '3px' }}
                    onClick={backToEmail}
                  >
                    (Change)
                  </span>
                </p>
              </div>
            </div>
            <div className="from-group mb-3">
              <label>
                Enter OTP <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="number"
                name="email_otp"
                onChange={handleInput}
              ></input>
              {counter === 0 ? (
                <p className="tx-12 mt-2">
                  Didn't receive OTP? Resend{" "}
                  <span
                    className="tx-theme"
                    style={{ cursor: "pointer" }}
                    onClick={resendotp}
                  >
                    (Click Here)
                  </span>
                </p>
              ) : (
                <p className="tx-12 mt-2">Resend OTP in {counter} seconds</p>
              )}
            </div>
            <button
              className="btn btn-primary btn-lg btn-full mb-4"
              onClick={secondStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        ) : (
          ""
        )}
        {step3 == true ? (
          <div className="p25 mt-5">
            <div className="backbutton" onClick={() => { setStep3(false); setStep2(true) }} ><img src="img/back.png" className="wd-20" /></div>
            <div className="mb-4 text-center">
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <h6>What's your Mobile Number?</h6>
            </div>
            <div className="from-group country-input mb-3">
              <label>Enter Mobile Number<span className="text-danger fs-5">*</span></label>
              <span className="country-code">+91</span>
              <input
                type="number"
                name="mobile_no"
                onChange={handleInput}
                placeholder="Mobile Number"
              ></input>
              <p className="tx-12 mt-2">We will send you OTP on your Mobile.</p>
            </div>
            <button
              className="btn btn-primary btn-lg btn-full mb-4"
              onClick={thirdStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        ) : (
          ""
        )}
        {step4 == true ? (
          <div className="p25 mt-5">
            <div className="backbutton" onClick={() => { setStep4(false); setStep3(true) }} ><img src="img/back.png" className="wd-20" /></div>
            <div className="mb-4 text-center">
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <h6>Verify OTP</h6>
              <p className="tx-gray tx-12 ">
                Otp has been sent to your  mobile number {registerData.mobile_no}
                <span
                  onClick={backToMobile}
                  className="tx-theme ms-1"
                  style={{ cursor: "pointer" }}
                >
                  (Change)
                </span>
              </p>
            </div>
            <div className="from-group mb-3">
              <label>
                Enter OTP <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="number"
                name="mobile_otp"
                onChange={handleInput}
              ></input>
            </div>
            {countermob === 0 ? (
              <p onClick={resendotpmobile} className="tx-12 mt-2">
                Didn't receive OTP? Resend{" "}
                <span className="tx-theme" style={{ cursor: "pointer" }}>
                  (Click Here)
                </span>
              </p>
            ) : (
              <p className="tx-12 mt-2">Resend OTP in {countermob} seconds</p>
            )}
            <button
              className="btn btn-primary btn-lg btn-full mb-4"
              onClick={forthStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        ) : (
          ""
        )}
        {step5 == true ? (
          <div className="p25 mt-5">
            <div className="backbutton" onClick={() => { setStep5(false); setStep4(true) }} ><img src="img/back.png" className="wd-20" /></div>
            <div className="mb-4 text-center">
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <h6>Create Your Unique Password</h6>
              <p className="tx-gray tx-12">Please enter your password</p>
            </div>
            <div className="from-group mb-3">
              <label>Password<span className="text-danger fs-5">*</span></label>
              <input
                type="password"
                name="password"
                onChange={handleInput}
              ></input>
            </div>
            <div className="from-group mb-3">
              <label>Confirm Password<span className="text-danger fs-5">*</span></label>
              <input
                type="password"
                name="cnfpassword"
                onChange={handleInput}
              ></input>
            </div>
            <p id="minchar" className="tx-11 tx-gray mb-0">
              Minimum 8 characters
            </p>
            <p id="capsmall" className="tx-11 tx-gray mb-0 ">
              Have atleast 1 capital and 1 small alphabet
            </p>

            <p id="specialchar" className="tx-11 tx-gray mb-0 ">
              Have one of the following special characters @,#,$,-,_,%,&,!,?
            </p>

            <button
              className="btn btn-primary btn-lg btn-full mb-4 mt-3"
              onClick={fifthStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        ) : (
          ""
        )}
      </MobileView>
    </>
  );
}
export default Register;
