import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { BrowserView, MobileView } from "react-device-detect";
import Dropdown from 'react-bootstrap/Dropdown';
import Skeleton from 'react-loading-skeleton';
import Pagination from "react-js-pagination";
import { ApiService } from "../../Components/Services/apiServices";
import BottomMenu from "../../Components/Footer-Bottom-menu";
let apiServices = new ApiService();

function MutualFunds() {
    const history = useNavigate()
    const [mutualFundList, setMutualFundList] = useState([])
    const [filterMutualFunfAmcData, setFilterMutualFundAmcData] = useState([])
    const [filterCatgoryData, setFilterCategoryData] = useState([])
    const [filteredarrayamc, setfilteredarrayamc] = useState([])
    const [Category, setCategory] = useState([])
    const [amcCode, setAmcCode] = useState([])
    const {goalID} = useParams();
    const [SipAllowed, setSipAllowed] = useState("")
    const [resultmessage, setresultmessage] = useState("")
    const [currentpage, setcurrentpage] = useState(1)
    const [amcCodeName, setAmcCodeName] = useState([])
    const [totalRecords, setTotalRecords] = useState(10);
    const [perPageRecords, setperPagerecords] = useState();
    const [sorting, setsorting] = useState("")
    const [skeletonShow, setSkeletonShow] = useState(false)
    const [activePage, setActivePage] = useState(1);
    const [pagePagination, setPagePagination] = useState("")
    // const [SipAllowed, setSipAllowed] = useState("")
    const [PurchaseAllowed, setPurchaseAllowed] = useState([])
    const [imgUrl, setimgUrl] = useState("")
    const [searchbarShow , setSearchBarShow] = useState(false)
    const didMountRef = useRef(true)
    useEffect(() => {
        // if (didMountRef.current) {
        getMutualFundData(1)

        apiServices.getbsemutualfundamcGetRequest().then(res => {
            if (res.data.status == "success") {
                setFilterMutualFundAmcData(res.data.data)
            }
        })
        apiServices.getfiltercategorydatapostrequest().then(res => {
            if (res.data.status == "success") {
                setFilterCategoryData(res.data.data)
            }

        })
        getFilterData("", "", "", sorting)
        // }
        // didMountRef.current = false;
    }, [activePage])

    const clickonamcfilter = (amc_code, amc_name) => {
        var amcArray = amcCode
        var amcArrayName = amcCodeName

        const amcCodeExists = amcArray.includes(amc_code);
        if (amcCodeExists) {

            amcArray = amcArray.filter(item => item !== amc_code);
            amcArrayName = amcArrayName.filter(item => item !== amc_name);
        } else {

            amcArray = [...amcArray, amc_code];
            amcArrayName = [...amcArrayName, amc_name];
        }
        setAmcCode(amcArray)
        setAmcCodeName(amcArrayName)
        getFilterData(amcArrayName, Category, SipAllowed, sorting)
    }
    const Searchamcfunddata = (e) => {
        var searchvalue = e.target.value
        if (searchvalue.length > 2 || searchvalue.length == 2) {
            var filterarray = filterMutualFunfAmcData.filter(function (item) {
                return item.amc_name.toUpperCase().match(searchvalue.toUpperCase())

            })
            setfilteredarrayamc(filterarray)
            setresultmessage("")
        }
        else if (searchvalue.length < 2) {
            setfilteredarrayamc(filterMutualFunfAmcData)
        }
        else {
            setfilteredarrayamc()
            setresultmessage("No Result Found")
        }


    }
    const clickoncategoryfilter = (category) => {

        var categoryArray = Category

        const categoryExists = categoryArray.includes(category);

        if (categoryExists) {
            categoryArray = categoryArray.filter(item => item !== category);
        } else {
            categoryArray = [...categoryArray, category];
        }


        setCategory(categoryArray)
        getFilterData(amcCodeName, categoryArray, SipAllowed, sorting)
    }

    // const clickonpopularityfilter =()=>{
    //     if(popularity==0){
    //         setpopularity(1)
    //         // getFilterData(amcCodeName, , SipAllowed,popularity)
    //     }
    //     else if (popularity==1){
    //         setpopularity(0)
    //     }
    // }
    const cliclonsipallowed = (sipallowed) => {
        setSipAllowed(sipallowed)
        getFilterData(amcCodeName, Category, sipallowed, sorting)
    }
    const getFilterData = (amc_code, category, sipallowed, sorteddata) => {
        // console.log(sorteddata, "sortingggg")
        const dataString = {
            amc_code: amc_code ? amc_code : amcCode,
            category: category ? category : Category,
            allow: sipallowed,
            sort: sorteddata
        }
        console.log('datastring',dataString)
        apiServices.getfiltermutualfunddatapostrequest(dataString, 1).then(res => {
            if (res.data?.status == 'success') {

                setMutualFundList(res.data.data?.data)
                setimgUrl(res.data.imgeURL)
                setcurrentpage(res?.data?.data?.current_page)
                setTotalRecords(res?.data?.data?.total)
                setperPagerecords(res?.data?.data?.per_page)
                setPagePagination(res?.data?.data?.last_page)
            }
        })
    }
    const getMutualFundData = (pagenumber) => {
        console.log(pagenumber)
        setSkeletonShow(true)
        console.log(pagenumber, "pagenumber")
        const dataString = {
            amc_code: "",
            category: "",
            allow: "",
        }
        apiServices.getfiltermutualfunddatapostrequest(dataString, pagenumber).then(res => {
            if (res.data?.status == 'success') {
                setSkeletonShow(false)
                setMutualFundList(res.data.data?.data)
                setimgUrl(res.data.imgeURL)
                setcurrentpage(res?.data?.data?.current_page)
                setTotalRecords(res?.data?.data?.total)
                setperPagerecords(res?.data?.data?.per_page)
                setPagePagination(res?.data?.data?.last_page)
                console.log(res?.data?.data?.last_page)
            }
        })
    }
    const clearAllFilter = () => {
        console.log("sdfd", Category)
        setCategory([])
        setSipAllowed([])
        setAmcCode([])
        setAmcCodeName([])
        getMutualFundData(1)
    }
    const clearamcfilter = (elementToDelete) => {
        let indextoremove = amcCodeName.indexOf(elementToDelete); // Finds the index of 'orange'
        const removeElement = amcCodeName.filter((_, index) => index !== indextoremove);
        setAmcCodeName((prevItems) => {
            // Create a new array by filtering out the item at the specified index
            return prevItems.filter((_, index) => index !== indextoremove);
        });
        getFilterData(removeElement, Category, SipAllowed, sorting)
    }
    const clearCategoryfilter = (elementToDelete) => {
        let indextoremove = Category.indexOf(elementToDelete);
        const removeElement = Category.filter((_, index) => index !== indextoremove);
        setCategory((prevItems) => {
            // Create a new array by filtering out the item at the specified index
            return prevItems.filter((_, index) => index !== indextoremove);
        });
        getFilterData(amcCodeName, removeElement, SipAllowed, sorting)
    }
    const clearallowfilter = (elementToDelete) => {
        setSipAllowed("");
        var radioButtons = document.getElementsByName('sipPurRadio');
        for (var i = 0; i < radioButtons.length; i++) {
            radioButtons[i].checked = false;
        }
        const removeElement = ""
        getFilterData(amcCodeName, Category, removeElement, sorting)
        }
    const clearsortfilter=()=>{
            setsorting('');
            var selectbox = document.getElementsByName('sortingdata');
        for (var i = 0; i < selectbox.length; i++) {
            selectbox[i].value = ''; 
        }
        const removeElement = ""
            getFilterData(amcCodeName, Category, SipAllowed, removeElement)
    }
    const handleSortingchange = (e) => {
        let sortedvalue = e.target.value
        // setpriceRange()
        
        setsorting(sortedvalue)
        getFilterData(amcCodeName, Category, SipAllowed, sortedvalue)
    }

    const searchmutualfund=(e)=>{
        let value=e.target.value
     
        if(value.length>2 || value.length==2){
            let dataString={
                query:value
            }
            apiServices.getsearchcontentpostrequest(dataString).then(result=>{
                if(result.data.status=="success"){
                    setMutualFundList(result.data.data)
                    setimgUrl(result.data.imgeURL)
                   
                }
            })
        }
    }
    return (
        <>
            <BrowserView>
                <Header />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Mutual Funds</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/mutual-funds">Mutual Funds</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-sm">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <div className="sidebarfilter">
                                    <div className="sidebarfilter-header">
                                        <h6 className="mb-0">FILTERS</h6>
                                        <div className="ml-auto tx-theme tx-13" style={{ cursor: 'pointer' }} onClick={clearAllFilter}>Clear All</div>
                                    </div>
                                    <div className="sidebarfilter-body">
                                        <Accordion  defaultActiveKey={['0',"1","2"]} alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>AMC</Accordion.Header>
                                                <Accordion.Body className="sidebarfilter-fixheight">
                                                    <div className="sfsearch-header"><input placeholder="Search Mutual Fund Companies" onChange={(e) => { Searchamcfunddata(e) }} /></div>
                                                    <div className="sflist">
                                                        {filteredarrayamc && filteredarrayamc.length > 0 ? <>
                                                            <ul>
                                                                {filteredarrayamc.map((value) => (
                                                                    <li ><label class="container-checkbox">{value.amc_name}
                                                                        <input type="checkbox" value={value.amc_name}
                                                                            checked={amcCodeName.includes(value.amc_name) ? true : false} onChange={(e) => { clickonamcfilter(value.amc_code, value.amc_name) }}
                                                                        // ref={(el) => (amcRefs[value.amc_name] = el)}

                                                                        /><span class="checkmark"></span></label></li>))}
                                                            </ul>
                                                        </> :
                                                            resultmessage && resultmessage == "No Result Found" ? "" : <>
                                                                <ul>
                                                                    {filterMutualFunfAmcData.map((value) => (
                                                                        <li ><label class="container-checkbox">{value.amc_name}<input type="checkbox" onChange={(e) => { clickonamcfilter(value.amc_code, value.amc_name); }}
                                                                            value={value.amc_name}
                                                                            checked={amcCodeName.includes(value.amc_name) ? true : false}
                                                                        /><span class="checkmark"></span></label></li>))}
                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header style={{ borderBottom: '1px solid #e9e9eb' }}>Category</Accordion.Header>
                                                <Accordion.Body className="sidebarfilter" style={{ padding: '10px 0px', border: '0px' }}>
                                                    <div className="sflist">
                                                        <ul>
                                                            {filterCatgoryData.map((value) => (
                                                                <li ><label class="container-checkbox">{value}<input type="checkbox" value={value}
                                                                    checked={Category.includes(value) ? true : false} onChange={(e) => { clickoncategoryfilter(value); }} /><span class="checkmark"></span></label></li>))}
                                                        </ul>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header style={{ borderBottom: '1px solid #e9e9eb' }}>Allow</Accordion.Header>
                                                <Accordion.Body className="sidebarfilter" style={{ padding: '10px 0px', border: '0px' }}>
                                                    <div className="sflist">
                                                        <ul>

                                                            <li ><label class="container-checkbox" name>SIP Allowed<input type="radio" value="sip" name="sipPurRadio" onChange={(e) => { cliclonsipallowed("SIP") }} /><span class="checkmark"></span></label></li>
                                                            <li ><label class="container-checkbox">Purchase Allowed<input type="radio" value="pur" name="sipPurRadio" onChange={(e) => { cliclonsipallowed("PUR") }} /><span class="checkmark"></span></label></li>
                                                        </ul>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="mb-0">Search Results : <span className="fw-bold">{totalRecords} Mutual Funds</span></p>
                                    <div >
                                        <select
                                            name="sortingdata"
                                            id="sortingdata"
                                            onChange={(e) => { handleSortingchange(e) }}
                                            className="custom-select"
                                        >
                                            <option value="">
                                                Select
                                            </option>
                                            <option value="Popularity">
                                                Popularity
                                            </option>
                                            {/* <option value="Rating">
                                                Rating-High to Low
                                            </option>
                                            <option value="Return">Return-High to Low</option> */}
                                        </select>
                                    </div>
                                </div>
                                {amcCodeName.length > 0 ? <>
                                    <div className="fundclip">
                                        <ul>
                                            {amcCodeName != null ?
                                                amcCodeName.map((value) => (<li><div>{value}   <i class="icon-cross2" onClick={(e) => { clearamcfilter(value) }}></i></div></li>)) : ""}

                                        </ul>

                                    </div>

                                </> : ""}
                                {Category.length > 0 ? <>
                                    <div className="fundclip">
                                        <ul>

                                            {Category != null ?
                                                Category.map((value) => (<li><div>{value}   <i class="icon-cross2" onClick={(e) => { clearCategoryfilter(value) }}></i></div></li>)) : ""}


                                        </ul>

                                    </div>

                                </> : ""}
                                {/* {Sortedarray.length > 0 ? <>
                                    <div className="fundclip">
                                        <ul>

                                            {Sortedarray != null ?
                                                Sortedarray.map((value) => (<li><div>{value}   <i class="icon-cross2" ></i></div></li>)) : ""}
                                        </ul>
                                    </div>
                                </> : ""} */}
                                {SipAllowed != "" ? <div className="fundclip">
                                    <ul>
                                        <li >
                                            {SipAllowed}
                                            <i class="icon-cross2" onClick={(e) => { clearallowfilter(SipAllowed) }}></i>
                                        </li>
                                    </ul>
                                </div> : ""}
                                {sorting != "" ? <div className="fundclip">
                                    <ul>
                                        <li>
                                            {sorting}
                                            <i class="icon-cross2" onClick={(e) => { clearsortfilter(sorting) }}></i>
                                        </li>
                                    </ul>
                                </div> : ""}
                                {skeletonShow == false ? <>
                                    {/* <i class="icon-cross2" onClick={(e) => { setSipAllowed("") }}></i> */}
                                    {mutualFundList.map((value) => (
                                        <div className="mfbox" onClick={() => { history(`/mutualfund-details/${value?.mf_id}${goalID ? '/'+goalID : ''}`) }}>
                                            {value ? <>
                                                <div className="mfbox-left">
                                                    <div className="mfboxcontent">
                                                        <div className="mfbox-icon">
                                                            <img src={value?.mf_image ? imgUrl + value?.mf_image : "/img/defaltimage.png"} />
                                                        </div>
                                                        <div className="mfbox-details">
                                                            <h6 >{value.mf_scheme_name}</h6>
                                                            <div className="mfbox-details-meta">
                                                                <ul>
                                                                    <li>{value.mf_scheme_type}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="mfbox-right ml-auto">
                                                    <div className="mfboxreturn">
                                                        <ul>
                                                            <li>
                                                                <h6 className="mb-0 tx-14">32.33%</h6>
                                                                <div className="tx-11">1Y</div>
                                                            </li>
                                                            <li>
                                                                <h6 className="mb-0 tx-14">32.33%</h6>
                                                                <div className="tx-11">1Y</div>
                                                            </li>
                                                            <li>
                                                                <h6 className="mb-0 tx-14">32.33%</h6>
                                                                <div className="tx-11">1Y</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                            </> : <Skeleton />}
                                        </div>))}
                                </> :
                                    <>
                                        <div className="mfbox" >
                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton width="100%" height="12px" />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mfbox" >

                                            <div className="mfbox-left">
                                                <div className="mfboxcontent">
                                                    <div className="mfbox-icon">
                                                        <Skeleton width="55px" height="59px" />
                                                    </div>
                                                    <div className="mfbox-details">
                                                        <Skeleton />
                                                        <div className="mfbox-details-meta ms-3">
                                                            <ul>
                                                                <Skeleton width="800px" />

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>}
                                {mutualFundList.length == 0 && skeletonShow == false ? <>

                                    <div className="noimg">
                                        <img src="/img/noitemavailable.webp" />
                                        <h6>No Mutual Fund Found</h6>
                                        {/* <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a> */}
                                    </div>
                                </> : ""}
                                {mutualFundList.length > 0 && pagePagination !== 1 ? <>
                                    <div className="d-flex justify-content-end">
                                    <Pagination
                                activePage={currentpage}
                                itemsCountPerPage={perPageRecords}
                                totalItemsCount={totalRecords}
                                onChange={(pageNumber) => getMutualFundData(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                                    </div>
                                </> : ""}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                    {searchbarShow == false ?
                    <>
                      <div className="mheader-left">
                      <div className="dflex align-items-center" >
                  
                          <i class="d-icon-arrow-left tx-22" onClick={() => { history("/dashboard") }}></i>
                          <div className="ms-2">
                              <h6 className="mb-0">All Mutual Funds</h6>
                          </div>

                          </div>
                       
                          </div>
                    <div className="header-right justify-content-end" onClick={()=>setSearchBarShow(true)}>
                    <i class="icon-search4"></i>
                    </div>
                    </>
                          :
                 
                 <div className="header-search" style={{width:"100%"}}>
                    
                 <form action="#" className="input-wrapper">
                 <input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search for top mutual funds..."  onChange={(e)=>searchmutualfund(e)}/>
              
                     <button className="btn btn-search" type="submit" title="submit-button" onClick={()=>{setSearchBarShow(false);getMutualFundData(1)}} ><i class="icon-cross2"></i></button>
                 </form>
                 
                 </div>
                            }
                    </div>
                </header>
                <div className="mobile-main">
                    <div className="fundtype-inner">
                        {amcCodeName.map((value) => (
                            <>
                                <div>{value}</div>
                            </>
                        ))}
                        {Category.map((value) => (<div>{value}</div>))}
                        <div>{SipAllowed}</div>
                    </div>
          
                     {skeletonShow == false ?  <>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="p-3 pb-0">
                            <p>Search Results : <span className="fw-bold">{totalRecords} Mutual Funds</span></p>
                        </div>

                        <div className="me-3" >
                                        <select
                                            name="sortingdata"
                                            id="sortingdata"
                                            onChange={(e) => { handleSortingchange(e) }}
                                            className="custom-select"
                                        >
                                            <option value="">
                                                Select
                                            </option>
                                            <option value="Popularity">
                                                Popularity
                                            </option>
                                            {/* <option value="Rating">
                                                Rating-High to Low
                                            </option>
                                            <option value="Return">Return-High to Low</option> */}
                                        </select>
                                    </div>
                                    </div>

                        
                        {mutualFundList.map((value) => (
                            <div className="mfbox" onClick={() => { history(`/mutualfund-details/${value?.mf_id}`) }}>
                                <div className="mfbox-left">
                                    <div className="mfboxcontent">
                                        <div className="mfbox-icon">
                                            <img src={value?.mf_image ? imgUrl + value?.mf_image : "/img/defaltimage.png"} />
                                        </div>
                                        <div className="mfbox-details">
                                            <h6 >{value.mf_scheme_name}</h6>
                                            <div className="mfbox-details-meta">
                                                <ul>
                                                    <li>{value.mf_scheme_type}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                    </> : <>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfbox" >
                            <div className="mfbox-left">
                                <div className="mfboxcontent">
                                    <div className="mfbox-icon">
                                        <Skeleton width="55px" height="59px" />
                                    </div>
                                    <div className="mfbox-details ms-4">
                                        <Skeleton />
                                        <div className="mfbox-details-meta ">
                                            <ul>
                                                <Skeleton width="300px" />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </>}


                    {searchbarShow == false &&  mutualFundList.length > 0 ? <>
                        <div className="d-flex justify-content-end"  style={{marginTop:"20px",marginRight:"10px",marginBottom:"10px",paddingBottom:"40px"}}>

                            <Pagination
                                activePage={currentpage}
                                itemsCountPerPage={perPageRecords}
                                totalItemsCount={totalRecords}
                                onChange={(pageNumber) => getMutualFundData(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>


                    </> : ""}
                    {mutualFundList.length == 0 && skeletonShow == false ? 
                    <div className="noimg">
                                            <img src="/img/noitemavailable.webp" />
                                            <h6>No Mutual fund Found</h6>
                                           
                                        </div>:""}

                </div>

                <BottomMenu></BottomMenu>
            </MobileView>
        </>
    )
}
export default MutualFunds