import React from "react";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin, InfinitySpin } from "react-loader-spinner";
function Setting() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    let apiServices = new ApiService();
    const [UserData, setUserData] = useState("")
    const [Imgurl, setimgUrl] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false)
    const Location = window.location.pathname
    useEffect(() => {
        if (didMountRef.current) {
            setSubmitLoader(true)
            apiServices.bsegetuserdataPostRequest({}).then(result => {
                console.log(result)
                if (result.data.status == 'success') {
                    setUserData(result.data.data)
                    setSubmitLoader(false)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    navigate("/")            
                    setSubmitLoader(false)
                }
                else {
                    alert(result.data.message)
                    setSubmitLoader(false)
                }
            })
        }
        didMountRef.current = false;
    }, []);
    console.log(UserData?.gs_pan_no, "pannumber")
    return (
        <>
           {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
            <BrowserView>
                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Setting</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-md">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <div className="account-user mb-3">
                                        <div className="au-imag">
                                            <img src={Imgurl && UserData?.gs_userimg ? Imgurl + UserData?.gs_userimg : "/img/avatar-1.jpg"} /></div>
                                        <div class="au-content"><h6 class="mb-0">Hi! {UserData?.gs_fname ? UserData.gs_fname : ""}</h6><p class="mb-0">{UserData?.gs_email}</p></div>
                                    </div>
                                    <div className="aclist mb-3">
                                        <ul>
                                            <li className={Location == "/setting" ? "active" : ""}><a href="/setting">Basic Details<i class="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/change-password" ? "active" : ""}><a href="/change-password">Change Password<i class="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/nominee-details" ? "active" : ""}><a href="/nominee-details">Nominee Details<i class="icon-arrow-right13"></i></a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="acpanel">
                                    <div className="acpanel-header"><h4>Basic Details</h4></div>

                                    <div className="acpanel-body">
                                        <div className="row g-2">
                                            <div className="col-lg-12">
                                                <div className="from-group mb-2">
                                                    <label>Name (as on PAN Card)</label>
                                                    <input type="text" disabled readOnly value={UserData?.gs_fname ? UserData.gs_fname + (UserData?.gs_lname ? UserData?.gs_lname : "") : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="from-group mb-2">
                                                    <label>Email Id</label>
                                                    <input type="email" disabled readOnly value={UserData?.gs_email ? UserData.gs_email : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="from-group mb-2">
                                                    <label>Phone Number</label>
                                                    <input type="number" disabled readOnly value={UserData?.gs_mobile ? UserData.gs_mobile : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="from-group mb-2">
                                                    <label>Date of Birth</label>
                                                    <input type="date" disabled readOnly value={UserData?.gs_dob ? UserData.gs_dob : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="from-group mb-2">
                                                    <label>Pan Number</label>
                                                    <input type="text" disabled readOnly value={UserData?.gs_pan_no ? UserData.gs_pan_no : "-"} ></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="from-group mb-2">
                                                    <label>Address</label>
                                                    <input type="text" disabled readOnly value={UserData?.gs_address1 ? UserData.gs_address1 + (UserData?.gs_address2 ? UserData?.gs_address2 : "") : ""}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="from-group mb-2">
                                                    <label>Pincode</label>
                                                    <input type="number" disabled readOnly value={UserData?.gs_pin ? UserData.gs_pin : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="from-group mb-2">
                                                    <label>State</label>
                                                    <input type="text" disabled readOnly value={UserData?.gs_state ? UserData.gs_state : "-"}></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="from-group mb-2">
                                                    <label>City</label>
                                                    <input type="text" disabled readOnly value={UserData?.gs_city ? UserData.gs_city : "-"}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">My Profile</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-main">
                    <div className="p15">
                        <div className="row g-2">

                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Name (as on PAN Card)</label>
                                    <input type="text" disabled readOnly value={UserData?.gs_fname ? UserData.gs_fname + (UserData?.gs_lname ? UserData?.gs_lname : "") : "-"}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Email Id</label>
                                    <input type="email" disabled readOnly value={UserData?.gs_email ? UserData.gs_email : "-"}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Phone Number</label>
                                    <input type="number" disabled readOnly value={UserData?.gs_mobile ? UserData.gs_mobile : "-"}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Date of Birth</label>
                                    <input type="date" disabled readOnly value={UserData?.gs_dob ? UserData.gs_dob : "-"}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Pan Number</label>
                                    <input type="text" disabled readOnly value={UserData?.gs_pan_no ? UserData.gs_pan_no : "-"} ></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Address</label>
                                    <input type="text" disabled readOnly value={UserData?.gs_address1 ? UserData.gs_address1 + (UserData?.gs_address2 ? UserData?.gs_address2 : "") : ""}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Pincode</label>
                                    <input type="number" disabled readOnly value={UserData?.gs_pin ? UserData.gs_pin : "-"}></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="from-group mb-2">
                                    <label>State</label>
                                    <input type="text" disabled readOnly value={UserData?.gs_state ? UserData.gs_state : "-"}></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="from-group mb-2">
                                    <label>City</label>
                                    <input type="text" disabled readOnly value={UserData?.gs_city ? UserData.gs_city : "-"}></input>
                                </div>
                            </div>


                        </div>

                    </div>





                </div>

            </MobileView>
        </>
    )
}

export default Setting