
import 'bootstrap/dist/css/bootstrap.min.css'; 
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";

let apiServices = new ApiService();
const OrderAccepted = () => {
  // Inline styles
  const styles = {
    container: {
      maxWidth: '600px',
      margin: '50px auto',
      backgroundColor: '#fff',
      padding: '20px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
    successIcon: {
      width: '60px', // Adjust size based on the gif's resolution
      height: '60px',
      marginBottom: '20px',
    },
    orderSummary: {

      padding: '15px',
      marginBottom: '20px',
      borderRadius: '8px',
      textAlign: 'left',
    },
  };

  const [orderDetail, setOrderdetail] = useState("")
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const didMountRef = useRef(true)
  const navigate = useNavigate()
  const {id} = useParams()
  useEffect(() => {
      if (didMountRef.current) {
        //  checkupistatus("direct")
          let dataString = {
              txn_transaction_id: id
          }
          setSpinnerLoading(true)
          apiServices.getuserOrderdetailgetrequest(dataString).then((response) => {
              console.log(response, "responsehjk")
              if (response?.data?.status == "success") {
                  setOrderdetail(response?.data?.data) 
                  setSpinnerLoading(false)   
              }
              else if (response.data.status === "error" && response.data.message === "Session expired") {
                  navigate("/");
                  localStorage.removeItem('AUTH_TOKEN');
                  setSpinnerLoading(false)
              }
          })
              .catch((error) => {
                  setSpinnerLoading(false)
          })
         

    
             
          }
                  didMountRef.current = false;

  }, []);

  return (
    <>
        {spinnerLoading ? <>
          <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
       
            </div>
          </div>
        </> : ""}

    <div className="container" style={styles.container}>
      {/* Header */}
      <header className="text-center mb-4">
        <div className="logo h4"><img src="/img/logo.png" style={{width:"200px"}}></img></div>
      </header>

      {/* Main Section */}
      <main className="text-center">
        {/* Using GIF for success icon */}
        <h1>Thank You for Your Order!</h1>
        <p className="lead">Your order has been accepted.</p>
        <img src="/img/siporder.png" alt="Success Tick" style={styles.successIcon} />
        <p className="h5">Order Number: <strong>{orderDetail.trans_api_order_no}</strong></p>

        {/* Order Summary */}
        <div style={styles.orderSummary}>
          <h2 className="h6" style={{textAlign:"center"}}>Order Summary</h2>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Detail</th>
                <th scope="col">Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Transaction ID:</strong></td>
                <td>{orderDetail.trans_regid}</td>
              </tr>
              <tr>
                <td><strong>Transaction Type:</strong></td>
                <td>{orderDetail.trans_type}</td>
              </tr>
              <tr>
                <td><strong>Amount Paid:</strong></td>
                <td>₹{orderDetail.trans_amount}</td>
              </tr>
              <tr>
                <td><strong>Date:</strong></td>
                <td>{moment(orderDetail.created_at).format('DD-MMM-YYYY')}</td>
              </tr>
              <tr>
                <td><strong>Payment Method:</strong></td>
                <td>{orderDetail.trans_payment_mode}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Next Steps */}
        <div className="d-flex justify-content-around mb-4">
          <button className="btn btn-primary" onClick={()=>{navigate('/dashboard')}}>Continue</button>
        </div>
      </main>

      {/* Footer */}
      <footer className="text-center">
        <p>  <a href="/help-support">Need help? Contact us</a> for any assistance</p>

      </footer>
    </div>
    </>
  );
};

export default OrderAccepted;
