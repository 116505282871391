import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment';
import BottomMenu from "../../Components/Footer-Bottom-menu";

const Mysips = () => {
    const didMountRef = useRef(true)
    const [Sipdata, setSipdata] = useState([])
    const [sipshow, setsipshow] = useState(true)
    const [onetimeshow, setonetimeshow] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState()
    const [sipData, setSipData] = useState([])
    let apiServices = new ApiService();
    const navigate = useNavigate()

    useEffect(() => {
        if (didMountRef.current) {
            setShowSkeleton(true)
            apiServices.getOrdertransactionlistgetrequest().then(res => {
                if (res.data.status == "success") {
                    setSipdata(res?.data?.data)
                    setShowSkeleton(false)
                }
            })
           apiServices.getusersipdatapostrequest({}).then(res => {
                if (res.data.status == "success") {
                    setSipData(res.data.data)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const getIndianTime = (createdat) => {
        const timestamp = createdat;
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        let formattedHours = hours % 12;
        formattedHours = formattedHours === 0 ? 12 : formattedHours;
        const period = hours < 12 ? 'AM' : 'PM';
        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${period}`;
        return formattedTime
    }
    return (<>
        <BrowserView>
            <Header />
            <div className="subheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>My SIPs</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>My SIPs</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </div>
            <section className="sec-pad-sm">
                <div className="container">
                    <div className="row">
                        {showSkeleton == false ?
                            sipData.length > 0 ? <>
                                <div className="col-lg-6 border-end">
                                    <div className="section-title mb-3"><h5>Monthly SIPs</h5></div>
                                    {sipData.map((value) => (
                                        <div className="mysipbox">
                                            <div className="mysipbox-details">
                                                <h6 className="mb-0 tx-14">{value.us_scheme_name}</h6>
                                            </div>
                                            <div className="mysipbox-footer border-top">
                                                <div>Next SIP on {value.us_period_day} of every month.</div>
                                                <div>₹{value.us_amount}</div>
                                            </div>
                                        </div>))}
                                </div>
                                <div className="col-lg-6">
                                    <div className="section-title"><h5>One Time SIPs</h5></div>
                                    <div className="noimg">
                                        <img src="/img/notransactions.png" />
                                        <h6>No One Time Transaction Found</h6>
                                        <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </div>
                            </> :
                                <div className="col-12">
                                    <div className="noimg">
                                        <img src="/img/noitemavailable.webp" />
                                        <h6>No SIP Found</h6>
                                        <a href="/mutual-funds" className="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </div> : ""}
                        {showSkeleton == true ?
                            <>
                                <div className="col-lg-6 border-end">
                                    <div className="section-title mb-3"><h5>Monthly SIPs</h5></div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="100px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                    <div className="mysipbox">
                                        <Skeleton width="140px" height="10px" />
                                        <div class="mysipbox-footer border-top">
                                            <Skeleton width="140px" height="10px" />
                                            <div><Skeleton width="30px" height="10px" /> </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ""}
                    </div>
                </div>
            </section>
            <Footer />
        </BrowserView>
        <MobileView>
            <header className="mheader">
                <div className="container">
                    <div className="mheader-left">
                        <div className="dflex align-items-center" >
                            <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                            <div className="ms-3">
                                <h6 className="mb-0">My SIPs</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-main">
                <div className="siptabs">
                    <ul>
                        <li className={`border-end ${sipshow ? " active" : ""}`} onClick={() => { setsipshow(true); setonetimeshow(false) }}>Monthly SIPs</li>
                        <li className={onetimeshow ? "border-end active" : ""} onClick={() => { setsipshow(false); setonetimeshow(true) }}>One Time SIPs</li>
                    </ul>
                </div>
                <div className="p15">
                    {
                        sipshow ?
                            showSkeleton == false ?
                                sipData?.length > 0 ? <>
                                    {sipData.map((value) => (
                                        <div className="mysipbox">
                                            <div className="mysipbox-details">
                                                <h6 className="mb-0 tx-14">{value.us_scheme_name}</h6>
                                            </div>
                                            <div className="mysipbox-footer border-top">
                                                <div>Next SIP on {value.us_period_day} of every month.</div>
                                                <div>₹{value.us_amount}</div>
                                            </div>
                                        </div>))}
                                </> : <>
                                    <div className="noimg">
                                        <img src="/img/notransactions.png" />
                                        <h6>No Monthly SIPs Transaction Found</h6>
                                        <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                                    </div>
                                </> : "" : ""}
                    {showSkeleton == true ?
                        <>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="100px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="140px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="140px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="140px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="140px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                            <div className="mysipbox">
                                <Skeleton width="140px" height="10px" />
                                <div class="mysipbox-footer border-top">
                                    <Skeleton width="140px" height="10px" />
                                    <div><Skeleton width="30px" height="10px" /> </div>
                                </div>
                            </div>
                        </>
                        : ""}
                    {onetimeshow ? <>
                        <div className="noimg">
                            <img src="/img/notransactions.png" />
                            <h6>No One Time Transaction Found</h6>
                            <a href="/mutual-funds" class="btn btn-outline-primary btn-md mt-3">Start Investment</a>
                        </div>
                    </> : ""}
                </div>
            </div>
            <BottomMenu></BottomMenu>
        </MobileView>
    </>)
}
export default Mysips