
import { useRef, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileOnlyView, MobileView } from "react-device-detect";
let apiServices = new ApiService();

function BankMandate() {
  const didMountRef = useRef(true)
  const [userBankData, setUserBankData] = useState()
  const [userprimarybank, setUserPrimaryBankData] = useState(" ")
  const [imgurl, setimgurl] = useState("")
  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("")
  const [mandateStatus, setMandateStatus] = useState("")
  const [bankMandateData, setBankMandateData] = useState({
    bankdetail: {
      accType: userBankData?.ubd_acc_type,
      accNo: userBankData?.ubd_acc_no,
      neftIfscCode: userBankData?.ubd_ifsc,
      micrNo: userBankData?.ubd_micr,
      bankName: userBankData?.bank?.bse_bank_name,
      bankBranch: userBankData?.ubd_bank_branch
    }, authenticationmode: 'N', maximumamount: '500000'
  })
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    if(didMountRef.current == true){

    apiServices.getuserbankdataPostRequest({}).then((result) => {

      if (result.data.status === 'success') {
  
        setimgurl(result?.data?.imgeURL)
        if (id == result?.data?.userPrimaryBankData?.ubd_id) {
          setUserPrimaryBankData(result?.data?.userPrimaryBankData)
        }
        else if (id == result.data.bse_bank_data[0]?.ubd_id) {

          setUserBankData(result.data.bse_bank_data[0])
        }
        else if (id == result.data.bse_bank_data[1]?.ubd_id) {
          setUserBankData(result.data.bse_bank_data[1])
        }
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
      }
    })
      .catch((error) => {
      });
   
    }
    didMountRef.current = false
  }, []);


  const handleChange = (e) => {


    const { name, value } = e.target;
    setBankMandateData({ ...bankMandateData, [name]: value });
  }
  const submitBamkMandateData = () => {
    if (!bankMandateData.bankdetail) {
      toast.error('Please select the bank detail', { position: 'top-right' });
      return;
    }
    if (!bankMandateData.authenticationmode) {
      toast.error('Please select the authentication mode ', { position: 'top-right' });
      return;
    }
    if (!bankMandateData.maximumamount) {
      toast.error('Please enter the maximum amount ', { position: 'top-right' });
      return;
    }
    if (bankMandateData.maximumamount < 100000) {
      toast.error('Amount should be more then 100000', { position: 'top-right' });
      return;
    }
    if (bankMandateData.maximumamount > 10000000) {
      toast.error('Amount should be less then 1Cr', { position: 'top-right' });
      return;
    }
    setIsLoading(true)
    const dataString = {
      ubd_id: id,
      authentication_mode: bankMandateData.authenticationmode,
      maximum_amount: bankMandateData.maximumamount
    }
    apiServices.bsemandateregistrationrequestPostRequest(dataString)
      .then(result => {
        setMandateStatus(result.data.status)
        if (result.data.status == "success") {
          // window.open(result.data.link)
          setResponse(result.data.message)
          setShowSweetAlert(true)
          setIsLoading(false)


        }
        else {
          toast.error(result?.data?.message)
          setIsLoading(false)
        }


      })
  }

  const onConfirmSA = () => {

    const dataString = {
      ubd_id: id,
    }
    apiServices.createmandateauthlinkpostrequest(dataString)
      .then(result => {
        console.log('result.data',result.data);
        if (result.data.status == "success") {
          window.open(result.data.link)
          setResponse(result.data.message)
          setTimeout(() => {
            navigate('/bank')
          }, 5000);
        }
        else {
          toast.error(result?.data?.message)
          setIsLoading(false)
        }


      })

  }
  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} autoClose={3000} />
      <BrowserView>
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>Let’s Register your Auto Debit (eNach)</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Auto Debit (eNach)</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
        <section className="sec-pad-sm">
          <div className="container">
            <div className={`row g-3 d-flex align-items-center justify-content-center`}>


              <div className="col-lg-6">
                <div className="bankdropdownbx ">
                  <div>
                    {/* <div className="bankdropdownbxClose" onClick={()=>{Closeaddbank()}}>
                  <i class="icon-cross2"></i>
                  </div> */}

                  </div>
                  <div className="banklist" style={{ height: "225px" }}>
                    <div className="banklist-header justify-content-between align-items-start">
                      <div className="dflex align-items-center">
                        <div className="banklist-icon">
                          {userBankData?.bank?.bse_bank_img ? <><img src={userBankData?.bank?.bse_bank_img ? imgurl + userBankData?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img></> : <Skeleton />}
                          {userprimarybank?.bank?.bse_bank_img ? <><img src={userprimarybank?.bank?.bse_bank_img ? imgurl + userprimarybank?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img></> : <Skeleton />}
                        </div>
                        <div className="banklist-name ms-3">
                          <h6 className="mb-0 tx-uppercase">{userBankData?.bank?.bse_bank_name ? userBankData?.bank?.bse_bank_name : userprimarybank?.bank?.bse_bank_name ? userprimarybank?.bank?.bse_bank_name : ""}</h6>

                        </div>


                      </div>
                      <p onClick={() => { navigate("/bank") }}><i class="icon-cross2"></i></p>
                    </div>
                    <div className="banklist-content">
                      <ul>
                        <li><span>Bank Name</span>{userBankData?.bank?.bse_bank_name ? userBankData?.bank?.bse_bank_name : userprimarybank?.bank?.bse_bank_name ? userprimarybank?.bank?.bse_bank_name : <Skeleton width="" />}</li>
                        <li><span>IFSC Code</span> {userBankData?.ubd_ifsc ? userBankData?.ubd_ifsc : userprimarybank?.ubd_ifsc ? userprimarybank?.ubd_ifsc : <Skeleton width="" />}</li>
                        <li><span>Bank Branch</span> {userBankData?.ubd_bank_branch ? userBankData?.ubd_bank_branch : userprimarybank?.ubd_bank_branch ? userprimarybank?.ubd_bank_branch : <Skeleton width="" />}</li>
                        <li><span>Bank Acc no</span> {userBankData?.ubd_acc_no ? userBankData?.ubd_acc_no : userprimarybank?.ubd_acc_no ? userprimarybank?.ubd_acc_no : <Skeleton width="" />}</li>
                        {/* <li><span>Bank Type</span> {userBankData?.ubd_acc_type ? userBankData?.ubd_acc_type : <Skeleton width="" />}</li> */}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="mt-4 from-group">
                  <label className=" tx-gray">Enter Bank Account Number </label>
                  <input className="w-100 mb-3" disabled readOnly value={userBankData?.ubd_acc_no} name="accNo" type="number" minLength="12"
                    maxLength="12" ></input>
                  <label className=" tx-gray">Select Bank Type </label>
                  <select
                    name="accType"
                    id="accType"
                    disabled
                    value={userBankData?.ubd_acc_type ?userBankData?.ubd_acc_type:userprimarybank?.ubd_acc_type}
                    className="custom-select"
                  >
                    <option value="">Select bank type</option>
                    <option value="SB" >Savings Bank</option>
                    <option value="CB" >Current Bank</option>
                    <option value="NE" >NRE</option>
                    <option value="NO" >NRO</option>
                  </select>

                </div> */}
                  {/* <div className="from-group mt-4 mb-3">
                    <label className=" tx-gray">Select Authentication Mode<span className="tx-red">*</span></label>
                    <select
                      name="authenticationmode"
                      id="authenticationmode"
                      className="custom-select"
                      onChange={handleChange}
                    >
                      <option value="N">Net Banking</option>
                      <option value="X">XSIP</option>
                      <option value="I">ISIP</option>
                    </select>
                  </div> */}

                  <div className="from-group mb-3">
                    <label className=" tx-gray">Maximum Amount<span className="tx-red">*</span></label>
                    <input
                      onChange={handleChange}
                      type="number"
                      name="maximumamount"
                      id="maximumamount"
                      value={bankMandateData.maximumamount}
                    />
                    <p className="mb-0 text-center mt-2">Min Amount: 100000 </p> <p className="mb-0 text-center">Max Amount : 1Cr</p>
                  </div>
                  <button className="btn btn-primary btn-lg btn-full" onClick={submitBamkMandateData} disabled={isLoading}>
                    {isLoading ? (
                      <img src="/img/loder01.gif" width="60px" height="11px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </BrowserView>

      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" >
                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/bank") }}></i>
                <div className="ms-3">
                  <h6 className="mb-0">Let’s Register your eNACH</h6>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">

          <div className="bankdropdownbx ">
            <div>
              {/* <div className="bankdropdownbxClose" onClick={()=>{Closeaddbank()}}>
                  <i class="icon-cross2"></i>
                  </div> */}

            </div>
            <div className="banklist" style={{ height: "225px" }}>
              <div className="banklist-header justify-content-between align-items-start">
                <div className="dflex align-items-center">
                  <div className="banklist-icon">
                    {userBankData?.bank?.bse_bank_img ? <>     <img src={userBankData?.bank?.bse_bank_img ? imgurl + userBankData?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img></> : <Skeleton />}
                    {userprimarybank?.bank?.bse_bank_img ? <>     <img src={userprimarybank?.bank?.bse_bank_img ? imgurl + userprimarybank?.bank?.bse_bank_img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4bZWRAQW68fi5U7P6YTKYicX4smRbw0mRuw&usqp=CAU"}></img></> : <Skeleton />}
                  </div>
                  <div className="banklist-name ms-3">
                    <h6 className="mb-0 tx-uppercase">{userBankData?.bank?.bse_bank_name ? userBankData?.bank?.bse_bank_name : userprimarybank?.bank?.bse_bank_name ? userprimarybank?.bank?.bse_bank_name : <Skeleton width="" />}</h6>

                  </div>
                </div>
                {/* <p className="tx-green">Edit</p> */}
              </div>
              <div className="banklist-content">
                <ul>
                  <li><span>Bank Name</span>{userBankData?.bank?.bse_bank_name ? userBankData?.bank?.bse_bank_name : userprimarybank?.bank?.bse_bank_name ? userprimarybank?.bank?.bse_bank_name : <Skeleton width="" />}</li>
                  <li><span>IFSC Code</span> {userBankData?.ubd_ifsc ? userBankData?.ubd_ifsc : userprimarybank?.ubd_ifsc ? userprimarybank?.ubd_ifsc : <Skeleton width="" />}</li>
                  <li><span>Bank Branch</span> {userBankData?.ubd_bank_branch ? userBankData?.ubd_bank_branch : userprimarybank?.ubd_bank_branch ? userprimarybank?.ubd_bank_branch : <Skeleton width="" />}</li>
                  <li><span>Bank Acc no</span> {userBankData?.ubd_acc_no ? userBankData?.ubd_acc_no : userprimarybank?.ubd_acc_no ? userprimarybank?.ubd_acc_no : <Skeleton width="" />}</li>
                  {/* <li><span>Bank Type</span> {userBankData?.ubd_acc_type ? userBankData?.ubd_acc_type : <Skeleton width="" />}</li> */}
                </ul>
              </div>
            </div>
            {/* <div className="mt-4 from-group">
                  <label className=" tx-gray">Enter Bank Account Number </label>
                  <input className="w-100 mb-3" disabled readOnly value={userBankData?.ubd_acc_no} name="accNo" type="number" minLength="12"
                    maxLength="12" ></input>
                  <label className=" tx-gray">Select Bank Type </label>
                  <select
                    name="accType"
                    id="accType"
                    disabled
                    value={userBankData?.ubd_acc_type ?userBankData?.ubd_acc_type:userprimarybank?.ubd_acc_type}
                    className="custom-select"
                  >
                    <option value="">Select bank type</option>
                    <option value="SB" >Savings Bank</option>
                    <option value="CB" >Current Bank</option>
                    <option value="NE" >NRE</option>
                    <option value="NO" >NRO</option>
                  </select>

                </div> */}
            <div className="from-group mt-4 mb-3">
              <label className=" tx-gray">Select Authentication Mode<span className="tx-red">*</span></label>
              <select
                name="authenticationmode"
                id="authenticationmode"
                className="custom-select"
                onChange={handleChange}
              >
                <option value="N">Net Banking</option>
                <option value="X">XSIP</option>
                <option value="I">ISIP</option>
              </select>
            </div>

            <div className="from-group mb-3">
              <label className=" tx-gray">Maximum Amount<span className="tx-red">*</span></label>
              <input
                onChange={handleChange}
                type="number"
                name="maximumamount"
                id="maximumamount"
                value={bankMandateData.maximumamount}
              />
              <p className="mb-0 text-center mt-2">Min Amount: 100000 </p> <p className="mb-0 text-center">Max Amount : 1Cr</p>
            </div>
            <button className="btn btn-primary btn-lg btn-full" onClick={submitBamkMandateData} disabled={isLoading}>
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>



      </MobileView>

      <SweetAlert
        // type={mandateStatus === "success" ? 'success' : 'danger'}
        confirmBtnCssClass='alertpop'
        
        
        showConfirm={false} 
      
        title={response}
        show={showSweetAlert}
        >
        <button className="btn btn-primary btn-md me-2" onClick={onConfirmSA}>Approve Now</button>
        <button className="btn btn-danger btn-md me-2" onClick={()=>navigate('/bank')}>Do It Later</button>

      </SweetAlert>
    </>
  )
}
export default BankMandate