// const API_URL = 'https://goalstox.codexo.in/csadmin/api/'
// const FRONT_URL = 'http://192.168.1.12:3020/'
// const API_URL2="https://goalstox.com/admin/api/"
const API_URL = 'https://app.goalstox.com/csadmin/api/'
const FRONT_URL = 'https://app.goalstox.com/'
const API_URL2="https://goalstox.com/admin/api/"


const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting ,FRONT_URL,API_URL2 };