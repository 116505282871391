import React, { useEffect } from 'react';

const HtmlResponseRenderer = ({ htmlContent }) => {
  useEffect(() => {
    document.open();
    document.write(htmlContent);
    document.close();
    const scripts = document.getElementsByTagName('script');
    for (let script of scripts) {
      eval(script.innerText);
    }
  }, [htmlContent]);

  return null;
};

export default HtmlResponseRenderer;

