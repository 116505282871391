
import React, { Component, useState, useRef, useEffect } from 'react';
import Header from "../../Components/Header"
import Footer from '../../Components/Footer';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { ApiService } from "../../Components/Services/apiServices";
import CustomcircularProgressBar from '../../Components/Elements/circular_process_bar';
import { BrowserView, MobileView } from 'react-device-detect';
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

// import { Watch } from "react-loader-spinner";
let apiServices = new ApiService();
let counter = 0;
const RiskManagement = () => {
    let history = useNavigate();
    const [questionsData, setQuestionsData] = React.useState([]);
    const [selectedOptionsList, setselectedOptionList] = useState();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [UserData, setUserData] = useState("")
    const [selectedOptionsData, setselectedOptionData] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isPrevLoading, setPrevIsLoading] = useState(false);
    const [totalScoreData, setTotalScoreData] = useState(0);
    const [showScoreData, setShowScoreData] = useState(false);
    const [hideprev, sethidePrevData] = useState(true);
    const [riskprofile, setRiskProfile] = useState('');
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            setSpinnerLoading(true);
            apiServices.getquestiondata().then(result => {
                if (result.data.status == 'success') {
                    setQuestionsData(result.data.data)
                    if (result.data.optionsel != null) {
                        if (result.data.optionsel !== false) {
                            setselectedOptionList(result.data.optionsel)
                            setselectedOptionData(result.data.data.options[result.data.optionsel].rpqo_id);
                        } else {
                            setselectedOptionList()
                            setselectedOptionData(0)
                        }

                    } else {
                        setselectedOptionList()
                        setselectedOptionData(0)
                    }
                    setSpinnerLoading(false);
                }
            })

        }
        didMountRef.current = false;
    }, []);
    const skipriskassetment = (e) => {
        history("/create-goals");
    };

    const backbutton = () => {
        history("/dashboard")
    }


    
    const nextStep = (question) => {
    
        if (selectedOptionsData === 0) {
            toast.error('Please select option');
            return false;
        }
        counter++;
        setIsLoading(true)
        const dataString = {
            "question_id": question.rpq_id,
            "option_id": selectedOptionsData,
            "count": counter,
            "totalscore": totalScoreData
        }
        apiServices.saveQuestionData(dataString).then(result => {
            if (result.data.status == 'success') {
                setTotalScoreData(Number(Number(totalScoreData) + Number(result.data.saveans.rpqa_score)).toFixed(1))
                setIsLoading(false)
                if (result.data.data == 0) {
                    setIsLoading(true)
                    const dataStringnew = {
                        "totalscore": Number(Number(totalScoreData) + Number(result.data.saveans.rpqa_score)).toFixed(1)
                    }
                    apiServices.saveriskscore(dataStringnew).then(res => {
                        if (res.data.status == 'success') {
                            setIsLoading(false)
                            if (res.data.riskprofile) {
                                setRiskProfile(res.data.riskprofile)
                                setIsLoading(false)
                            }
                            setShowScoreData(true)
                        }
                        else if (result.data.status == "error" && result.data.message == "Session expired") {
                            localStorage.removeItem('AUTH_TOKEN');
                            // alert(result.data.message)
                            setSpinnerLoading(false)
                            setIsLoading(false)
                            history.push("/login")
                        }
                        else {
                            alert(result.data.message)
                            setSpinnerLoading(false)
                            setIsLoading(false)
                            return false
                        }
                    })
                } else {
                    setIsLoading(false)
                    sethidePrevData(false)
                    setQuestionsData(result.data.data)
                    if (result.data.optionsel != null) {
                        if (result.data.optionsel !== false) {
                            setselectedOptionList(result.data.optionsel)
                            setselectedOptionData(result.data.data.options[result.data.optionsel].rpqo_id);
                        } else {
                            setselectedOptionList()
                            setselectedOptionData(0)
                        }

                    } else {
                        setselectedOptionList()
                        setselectedOptionData(0)
                    }
                }
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                setSpinnerLoading(false)
                setIsLoading(false)
                history.push("/login")
            }
            else {
                setIsLoading(false)
                alert(result.data.message)
                setSpinnerLoading(false)
                return false
            }
        })
    }
    const previousStep = (question) => {
        // if(selectedOptionsData===0){
        //     alert('Please select option');
        //     return false;
        // }
        setPrevIsLoading(true)
        const dataString = {
            "question_id": question.rpq_id,
        }
        apiServices.getprevquestiondata(dataString).then(result => {
            if (result.data.status == 'success') {
                setPrevIsLoading(false)
                if (result.data.data == 0) {
                    setShowScoreData(true)
                } else {
                    if (result.data.ques == 1) {
                        sethidePrevData(true)
                    }
                    setTotalScoreData(Number(Number(totalScoreData) - Number(result.data.saveans.rpqa_score)).toFixed(1))
                    setQuestionsData(result.data.data)
                    if (result.data.optionsel != null) {
                        if (result.data.optionsel !== false) {
                            setselectedOptionList(result.data.optionsel)
                            setselectedOptionData(result.data.data.options[result.data.optionsel].rpqo_id);
                        } else {
                            setselectedOptionList()
                            setselectedOptionData(0)
                        }
                    } else {
                        setselectedOptionList()
                        setselectedOptionData(0)
                    }
                }
            }
            else if (result.data.status == "error" && result.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(result.data.message)
                setSpinnerLoading(false)
                setIsLoading(false)
                history("/")
            }
            else {
                alert(result.data.message)
                setSpinnerLoading(false)
                setIsLoading(false)
                return false
            }
        })
    }
    const gotoriskprofile = () => {
        history("/risk-assestment");
    }
    const answer = (option, index) => {
        // if(prevsel != null )
        // {
        //     setselectedOptionList(prevsel);
        // }
        // else{       
        setselectedOptionList(index);
        // }
        // if(prevdata != null)
        // {
        // setselectedOptionData(prevdata)
        // }
        // else{
        setselectedOptionData(option.rpqo_id)
        // }
        // setTotalScoreData(Number(totalScoreData)+Number(option.rpqo_option_weightage_score))
    }

    return (<>
        <BrowserView>
            <div>
                <Header />
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                <section className='sec-pad-md'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-8'>
                                <div className="risksec">
                                    <div className="risksecClose"><i className="icon-cross2" onClick={() => { backbutton() }}></i></div>
                                    {totalScoreData == "" ? <> <div className='risksec-header text-center'>
                                        <h4 className='mb-0'>Behavioural Risk Profile Survey</h4>
                                        {/* {totalScoreData == " " ?<> <h4 className='mb-0'>Behavioural Risk Profile Survey</h4></>:""}   */}
                                    </div></> : ""}

                                    <div className='risksec-body'>
                                        {
                                            showScoreData == false ?
                                                <div>
                                                    <h5 className='tx-theme text-center'>How much risk can you take?</h5>
                                                    <p className='text-center tx-14 tx-gray'>Some questions that can help you know your risk appetite and demographic score</p>
                                                    <div className='riskbox'>
                                                        <div className='bg-light tx-theme p-3 mt-2 text-center fw-bold fs-6'>Q{questionsData.rpq_question}</div>
                                                        <div className='riskbox-body'>
                                                            {
                                                                questionsData.rpq_question_image != null ? <img className='' src={questionsData.rpq_question_image} /> : ''
                                                            }
                                                            {
                                                                questionsData.options ? questionsData.options.map((option, index) => <div style={{ cursor: "pointer" }} className={selectedOptionsList === index ? "riskbox-question active" : "riskbox-question"} key={index} onClick={() => { answer(option, index); }}>
                                                                    <span class="circle" style={{ cursor: "pointer" }}></span>
                                                                    <span className='text-center'></span><span className='text-center'>{option.rpqo_option}</span></div>) : null
                                                            }
                                                        </div>
                                                        <div className='riskbox-footer'>
                                                            {hideprev == false ?
                                                                <button className='btn btn-primary btn-md me-3' onClick={() => { previousStep(questionsData); }} disabled={isPrevLoading}> {isPrevLoading ? (
                                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                                  ) : (
                                                                    "Previous"
                                                                  )}</button> : ''
                                                            }
                                                            <button className='btn btn-primary btn-md' onClick={() => { nextStep(questionsData); }} disabled={isLoading}>  {isLoading ? (
                  <img src="/img/loder01.gif" width="60px" height="11px" />
                ) : (
                  "Next"
                )}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-lg-12 ">

                                                    <h4 className='mg-b-10 text-center'>Your Risk Profile</h4>
                                                    <p className='text-center'> <span > {riskprofile}({totalScoreData})</span></p>

                                                    {/* <span> {riskprofile}({totalScoreData.toFixed(2)}) </span>    */}
                                                    <div className="m-auto" style={{ width: 150 }}>

                                                        <CustomcircularProgressBar percent={totalScoreData} styles={"m-0"} />
                                                        {/* <h6>Your Risk Profile</h6>
                                    <h6 className="tx-theme"><span>{UserData?.gs_risk_assestment_profile}</span> ({UserData?.gs_risk_assestment_score}) </h6> */}
                                                        <button onClick={skipriskassetment} className="btn btn-primary btn-md btn-lg btn-full me-3 mb-3">Set Your Goal<i class="fa fa-long-arrow-right mg-l-10" aria-hidden="true"></i></button>
                                                    </div>

                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <Footer />
            </div>
        </BrowserView>
        <MobileView>
            <header className="mheader">
                <div className="container">
                    <div className="mheader-left">
                        <div className="dflex align-items-center" >
                            <i class="d-icon-arrow-left tx-22" onClick={() => { history("/dashboard") }}></i>
                            <div className="ms-3">
                                <h6 className="mb-0">Risk assessment</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='mobile-main'>
                <div className="risksec">
                    {totalScoreData == "" ? <div className='risksec-header text-center'>
                        {/* {totalScoreData == " " ?<> <h4 className='mb-0'>Behavioural Risk Profile Survey</h4></>:""}   */}
                        <h6 className='mb-0'>Behavioural Risk Profile Survey</h6>
                    </div> : ""}

                    <div className='risksec-body pt-0'>
                        {
                            showScoreData == false ?
                                <div>
                                    <div className='p15'>
                                        <h6 className='tx-theme text-center'>How much risk can you take?</h6>
                                        <p className='text-center tx-14 tx-gray mb-0'>Some questions that can help you know your risk appetite and demographic score</p>
                                    </div>
                                    <div className='riskbox'>
                                        <div className='bg-light tx-theme p-3 mt-2 text-center fw-bold fs-6'>Q{questionsData.rpq_question}</div>
                                        <div className='riskbox-body'>
                                            {
                                                questionsData.rpq_question_image != null ? <img className='' src={questionsData.rpq_question_image} /> : ''
                                            }
                                            {
                                                questionsData.options ? questionsData.options.map((option, index) => <div style={{ cursor: "pointer" }} className={selectedOptionsList === index ? "riskbox-question active" : "riskbox-question"} key={index} onClick={() => { answer(option, index); }}>
                                                    <span class="circle" style={{ cursor: "pointer" }}></span>
                                                    <span className='reskq'>{option.rpqo_option}</span></div>) : null
                                            }
                                        </div>
                                        <div className='riskbox-footer'>
                                            {hideprev == false ?
                                                <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { previousStep(questionsData); }} >Previous</button> : ''
                                            }
                                            <button className='btn btn-primary btn-lg btn-full' onClick={() => { nextStep(questionsData); }}>Next</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-12">
                                    <div className="mt-5 pt-5">
                                        <h4 className='text-center'>Your Risk Profile</h4>
                                        <p className='text-center'> <span > {riskprofile}({totalScoreData})</span></p>
                                        <CustomcircularProgressBar percent={totalScoreData} styles={"m-0"} />
                                    </div>



                                    {/* <span> {riskprofile}({totalScoreData.toFixed(2)}) </span>    */}
                                    <div className="footer-button">
                                        <button className="btn btn-primary mt-1 btn-lg btn-full" onClick={skipriskassetment}>Set Your Goal</button>
                                    </div>


                                </div>
                        }
                    </div>
                </div>

            </div>

        </MobileView>

    </>

    );
}
export default RiskManagement;