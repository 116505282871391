import React from "react";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin, InfinitySpin } from "react-loader-spinner";

function Changepassword() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    let apiServices = new ApiService();
    const [UserData, setUserData] = useState("")
    const [userEmail, setuserEmail] = useState("")
    const [submitLoader, setSubmitLoader] = useState(false)
    const[isLoading , setIsLoading] = useState(false)
    const Location = window.location.pathname
    useEffect(() => {
        if (didMountRef.current) {
            // let dataString={}
setSubmitLoader(true)
            apiServices.bsegetuserdataPostRequest({}).then(result => {

                if (result.data.status == 'success') {
                    setUserData(result.data.data)
                    setSubmitLoader(false)
                }
                else if (result.data.status == "error" && result.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    setSubmitLoader(false)
                    navigate("/")
                }
                else {
                    alert(result.data.message)
                    setSubmitLoader(false)
                }
            })

        }
        didMountRef.current = false;
    }, []);

    const ResetPassword = (e) => {
        e.preventDefault();
        if (userEmail == '' && UserData.gs_email == " ") {
            toast("Email Address Cannot Be Empty");
            return false;
        }
        setIsLoading(true)
        const dataString = {
            "user_email": UserData.gs_email ? UserData.gs_email : userEmail,
        }
        apiServices.resetpasswordPostRequest(dataString).then(result => {
            if (result.data.status == 'success') {
                toast.success(result.data.message + ". Please visit your registered mail id")
                setIsLoading(false)
            }
            else {
                toast(result.data.message)
                setIsLoading(false)
            }
        })
            .catch(error => {
                setIsLoading(false)
            })
    }
    return (
        <>
            {submitLoader ? <>
          <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>

            <TailSpin
              color="#ffffff"
              visible={submitLoader}
            />
       
            </div>
          </div>
        </> : ""}
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <BrowserView>

                <Header />
                <div className="subheader subheader-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <Breadcrumb>
                                    <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active href="/change-password">Change password</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sec-pad-md">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <div className="account-user mb-3">
                                        <div className="au-imag"><img src="/img/user.png" /></div>
                                        <div class="au-content"><h6 class="mb-0">Hi! {UserData?.gs_fname ? UserData.gs_fname : ""}</h6><p class="mb-0">{UserData?.gs_email}</p></div>
                                    </div>
                                    <div className="aclist mb-3">
                                        <ul>
                                            <li className={Location == "/setting" ? "active" : ""}><a href="/setting">Basic Details<i class="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/change-password" ? "active" : ""}><a href="/change-password">Change Password<i className="icon-arrow-right13"></i></a></li>
                                            <li className={Location == "/nominee-details" ? "active" : ""}><a href="/nominee-details">Nominee Details<i className="icon-arrow-right13"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="acpanel">
                                    <div className="acpanel-header"><h4>Change password</h4></div>
                                    <div className="acpanel-body">
                                    <div className="row g-2">
                                            <div className="col-lg-12">
                                                <div className="from-group ">
                                                    <label>Enter Email Address<span className="text-danger fs-5">*</span></label>
                                                    <input type="email" name="user_email" placeholder="yourname@yourmail.com" disabled={UserData.gs_email ? true : false} value={UserData.gs_email} onChange={(e) => { setuserEmail(e.target.value) }}></input>
                                                    <p className="tx-12 mt-2">We will send you Reset link on your Email.</p>
                                                </div>
                                               
                                                <button
                                                className="btn btn-primary"
                                                onClick={ResetPassword}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Submit"
                                                )}
                                            </button>
                                            </div>
                                        </div>
                                  
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <header className="mheader">
                    <div className="container">
                        <div className="mheader-left">
                            <div className="dflex align-items-center" >
                                <i class="d-icon-arrow-left tx-22" onClick={() => { navigate("/my-account") }}></i>
                                <div className="ms-3">
                                    <h6 className="mb-0">Change Password</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-main">
                    <div className="p15">
                        <div className="row g-2">

                            <div className="col-12">
                                <div className="from-group mb-2">
                                    <label>Email Address<span className="text-danger fs-5">*</span></label>
                                    <input type="email" name="user_email" placeholder="yourname@yourmail.com" disabled={UserData.gs_email ? true : false} value={UserData.gs_email} onChange={(e) => { setuserEmail(e.target.value) }}></input>
                                    <p className="tx-12 mt-2">We will send you Reset link on your Email.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-button">
                    
                    <button
                                                className="btn btn-primary mt-1 btn-lg btn-full"
                                                onClick={ResetPassword}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <img src="/img/loder01.gif" width="60px" height="11px" />
                                                ) : (
                                                    "Submit"
                                                )}
                                            </button>
                </div>

            </MobileView>

        </>
    )
}

export default Changepassword