import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiService } from "../../Components/Services/apiServices";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
let apiServices = new ApiService();

function Home() {
  const [usermobile, setUsermobile] = useState("")
  const [userOtp, setUserotp] = useState("")
  const [counter, setCounter] = React.useState(0);
  const [countermob, setCountermob] = React.useState(0);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [userauthid, setuserauthid] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [verifyotp, setverifyotp] = useState(false)
  const [mobileScreen, setMobileScreen] = useState(true)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
      }

      apiServices.bsegetuserdataPostRequest(dataString).then(res => {
        if (res.data.status == 'success') {
          if (res.data.data.gs_status == 0) {

            setIsLoading(false)
            navigate("/completeprofile");
          }
          else if (res.data.data.gs_bse_onboard_status == 0) {
            setIsLoading(false)
            window.location.href = "/bseonboarding/" + res.data.data.gs_bse_onboard_step
          }
        }
      })
    }
    didMountRef.current = false;
  })
  const loginprocess = (e) => {
    e.preventDefault();
    if (usermobile == '') {
      toast("Please Enter your registered mobile number");
      return;
    } else if (usermobile.length !== 10) {
      toast("Mobile number should be of 10 digits");
      return;
    }

    else {
      setErrorMessage('');
    }
    setIsLoading(true)
    const dataString = {
      "mobilenumber": usermobile,

    }
    apiServices.sendloginotpPostRequest(dataString).then(res => {

      if (res.data.status == 'success') {
        setCounter(30);
        setCountermob(30)
        setIsLoading(false)
        setMobileScreen(false)
        setverifyotp(true)
        setuserauthid(res?.data?.auth)
      }
      else {
        setIsLoading(false)
        toast(res.data.message);
        return false;
      }
    })
      .catch(error => {
      })
  };
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  React.useEffect(() => {
    countermob > 0 && setTimeout(() => setCountermob(countermob - 1), 1000);
  }, [countermob]);


  React.useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setseconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
        } else {
          setseconds(59);
          setminutes(minutes - 1);
        }
      }
    }, 1000);
  });

  const VerifyOtp = (e) => {
    e.preventDefault();
    if (userOtp == '') {
      toast("Please Enter OTP send on number");
      return;
    }
   

    else {
      setErrorMessage('');
    }
    setIsLoading(true)
    const dataString = {
      "otp": userOtp,
      "id": userauthid
    }
    apiServices.verifyloginotpPostRequest(dataString).then(res => {
      console.log("response", res)
      if (res.data.status == 'success') {
        localStorage.setItem('AUTH_TOKEN', res.data.token);
        setIsLoading(false)
        if (res.data.loginsendData.gs_status == 0) {

          setIsLoading(false)
          navigate("/completeprofile");
        }
        else if (res.data.loginsendData.gs_bse_onboard_status == 0) {
          setIsLoading(false)
          console.log("atob", res.data.data.gs_bse_onboard_step)
          window.location.href = "/bseonboarding/" + res.data.loginsendData.gs_bse_onboard_step
        }
        else if (res.data.loginsendData.gs_bse_onboard_status == 1) {
          setIsLoading(false)
          console.log("atob", res.data.loginsendData.gs_bse_onboard_step)
          window.location.href = "/bseaufform";
        }
        else if (res.data.loginsendData.gs_bse_onboard_status == 2) {
          setIsLoading(false)
          console.log("atob", res.data.loginsendData.gs_bse_onboard_step)
          window.location.href = "/dashboard";
        }
      }
      else {
        setIsLoading(false)
        toast(res.data.message);
        return false;
      }
    })
      .catch(error => {
      })
  };

  const resendOtpbr = () => {
    const dataString = {
      "mobilenumber": usermobile,

    }
    apiServices.sendloginotpPostRequest(dataString).then(res => {

      if (res.data.status == 'success') {
        setCounter(30);
        setCountermob(30)
        setminutes(10);
        setseconds(0);

        setIsLoading(false)
        setMobileScreen(false)
        setverifyotp(true)
        setuserauthid(res?.data?.auth)
        return false;
      }
      else {
        setIsLoading(false)
        toast(res.data.message);
        return false;
      }
    })
  }



  return (
    <>
      <BrowserView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} autoClose={3000} />
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center">
                {mobileScreen == true ? <>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Login to Goalstox</h5>
                          <p>Manage your portfolio, transactions and more!</p>
                        </div>
                        <div className="from-group mb-20">

                          <label> Mobile number</label>
                          <input type="number" name="mobilenumber" onChange={(e) => setUsermobile(e.target.value)} ></input>
                          <p className="tx-12 mt-2"> We will send you OTP on your mobile number.</p>
                        </div>

                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={loginprocess}
                          disabled={isLoading} // Disable the button when loading
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Send OTP"
                          )}
                        </button>

                        <p className="mb-0 text-center ">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>

                      </div>
                    </div>
                  </div>
                </> : ""}
                {verifyotp == true ? <>
                  <div className="backbutton-desktop" onClick={() => { setverifyotp(false); setMobileScreen(true) }} ><i class="icon-arrow-left8 wd-20 "></i></div>
                  <div className="col-lg-5">
                    <div className="crds">
                      <div className="crds-body p-50">
                        <div className="text-center mb-30">
                          <img src="/img/logo.png" className="wd-150 mb-4"></img>
                          <h5>Verify Mobile Number</h5>
                          <p className="tx-gray tx-12 ">
                            Otp has been sent to your mobile number {usermobile}
                            <span
                              onClick={() => { setverifyotp(false); setMobileScreen(true) }}
                              className="tx-theme ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              (Change)
                            </span>
                          </p>
                        </div>
                        <div className="from-group mb-20">

                          <label>Verify OTP</label>
                          <input type="number" name="otp" onChange={(e) => setUserotp(e.target.value)} ></input>
                          {counter === 0 ? (
                            <p className="tx-12 mt-2">
                              Didn't receive OTP? Resend{" "}
                              <span
                                className="tx-theme"
                                style={{ cursor: "pointer" }}
                                onClick={resendOtpbr}
                              >
                                (Click Here)
                              </span>
                            </p>
                          ) : (
                            <p className="tx-12 mt-2">
                              Resend OTP in {counter} seconds
                            </p>
                          )}
                        </div>

                        <button
                          className="btn btn-primary btn-lg btn-full mb-4"
                          onClick={VerifyOtp}
                          disabled={isLoading} // Disable the button when loading
                        >
                          {isLoading ? (
                            <img src="/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Verify OTP"
                          )}
                        </button>

                        <p className="mb-0 text-center ">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>
                      </div>
                    </div>
                  </div>                
                </> : ""}
              </div>
            </div>
          </div>

        </div>

      </BrowserView>
      <MobileView>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <div className="p25 mt-5">
          <Link to="/" className="backbutton"><img src="img/back.png" className="wd-20" /></Link>

          <div className="mb-4 text-center">
            <img src="/img/logo.png" className="wd-150 mb-4"></img>
            <h5>Login to Goalstox</h5>
            <p className="tx-gray tx-12">Manage your portfolio, transactions and more!</p>
          </div>
          {mobileScreen == true ? <>

            <div className="from-group mb-3">
              <label>Enter your mobile number</label>
              <input type="number" name="mobilenumber" onChange={(e) => setUsermobile(e.target.value)} ></input>
              <p className="tx-12 mt-2">  We will send you OTP on your mobile number.</p>
            </div>

            <button
              className="btn btn-primary btn-lg btn-full mb-4"
              onClick={loginprocess}
              disabled={isLoading} // Disable the button when loading
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="30px" height="30px" />
              ) : (
                "Send OTP"
              )}
            </button>
            <p className="mb-0 text-center">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>


          </> : ""}
          {verifyotp == true ?
            <>



              <div className="from-group mb-3">
                <label>Verify OTP</label>
                <input type="number" name="otp" onChange={(e) => setUserotp(e.target.value)} ></input>
                {countermob === 0 ? (


                  <p className="tx-12 mt-2" onClick={resendOtpbr}>
                    Didn't receive OTP? Resend{" "}
                    <span className="tx-theme" style={{ cursor: "pointer" }}>
                      (Click Here)
                    </span>
                  </p>
                ) : (
                  <p className="tx-12 mt-2">
                    Resend OTP in {countermob} seconds
                  </p>
                )}
              </div>

              <button
                className="btn btn-primary btn-lg btn-full mb-4"
                onClick={VerifyOtp}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <img src="/img/loder01.gif" width="60px" height="11px" />
                ) : (
                  "Verify OTP"
                )}
              </button>
              <p className="mb-0 text-center">Don't have an account? <span className="tx-theme" onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>Register</span></p>


            </> : ""}


        </div>

      </MobileView>
    </>
  )
}
export default Home